import React, { useRef, useState } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import successImage from "../../Assets/images/popup/successBooking.svg";
import declinedImage from "../../Assets/images/popup/declinedBooking.svg";
import arrow from "../../Assets/images/popup/arrow.svg";

export default function BookingConfirmed({ name, closePopup }) {
  const [currentIndex, setCurrentIndex] = useState(0); // Suivi de l'utilisateur actif

  const btnRef = useRef(null);
  useOutsideClick(btnRef, () => {
    closePopup();
  });

  // Liste des réservations
  const usersBooking = [
    { id: "1", bookingName: "Paul Mirabel", status: "accepted" },
    { id: "2", bookingName: "Adrien Bosquet", status: "rejected" },
    { id: "3", bookingName: "Toavina Mario", status: "accepted" },

  ];

  const activeBooking = usersBooking[currentIndex]; // Récupère l'utilisateur actif
  const isLastBooking = currentIndex === usersBooking.length - 1;

  const handleNext = () => {
    if (!isLastBooking) setCurrentIndex((prevIndex) => prevIndex + 1);
  };
  const handleRedirect = () => {
    window.location.href = "/quotation";
  };
  return (
    <div className="popup" ref={btnRef}>
      <div className="container-popup-booking">
        {/* Ajout d'une transition dynamique entre success et rejected */}
        <div
          className={`booking-content ${
            activeBooking.status === "accepted" ? "success" : "rejected"
          }`}
        >
          <img
            className="img-success"
            src={
              activeBooking.status === "accepted" ? successImage : declinedImage
            }
            alt={activeBooking.status}
          />

          <div className="container-message">
            <p
              className={
                activeBooking.status === "accepted"
                  ? "message"
                  : "messageDeclined"
              }
            >
              {activeBooking.status === "accepted"
                ? "Your booking request has been successfully accepted!"
                : "Unfortunately your booking request has been declined!"}
            </p>
          </div>

          <p
            className={`userActif ${
              activeBooking.status === "rejected" ? "declined" : ""
            }`}
          >
            <span>“{activeBooking.bookingName}”</span> Booking
          </p>

          {/* Gestion des boutons */}
          <div className="container-button-carousel">
            {isLastBooking ? (
              <button className="button-b" onClick={handleRedirect}>Open booking grid</button>
            ) : (
              <button className="button-b next" onClick={handleNext}>
                Next <img className="img-arrow" src={arrow} alt="Next" />
              </button>
            )}

            {/* Indicateurs de progression */}
            <div className="carousel-button">
              {usersBooking.map((booking, index) => (
                <div
                  key={booking.id} className={`item-button ${index === currentIndex ? "active" : ""}`}
                  style={index === currentIndex ? { backgroundColor: "#A9A9A9", transform: "scale(1.1)" } : { backgroundColor: "#ccc" } }
                ></div>
              ))}
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
