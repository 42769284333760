import React from 'react'
import { pluralize } from '../../../constants'
import { formatDate } from '../../../utility/globalFunc'

function CancellationPolicyItem({rooms,dateFrom,dateTo,description}) {
  return (
    <div className='cancellation-item-container'>
        <div className='cancellation-header'>
            <div className='cancellation-title'>
                Cancellation policy for following {pluralize(rooms,"room","rooms")}:
            </div>
            <div className='cancellation-validity-container'>
                <span>Validity of policy</span>
                <div className='cancellation-date-container'>
                    <span className='date-item'>{formatDate(dateFrom)}</span>
                    <i className='arrow'></i>
                      <span className='date-item'>{formatDate(dateTo)}</span>
                </div>
            </div>
        </div>
        <div className='cancellation-rooms'><div className='room-item'>{rooms}</div></div>
        <div className='cancellation-description'>
            {description}
        </div>
    </div>
  )
}

export default CancellationPolicyItem
