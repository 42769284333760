import React,{useState,useRef} from 'react';
import useOutsideClick from '../../hooks/useOutsideClick.js';
import {accomClient, accomDates, accomPax, clearRoom, selectedRooms, setSelRooms,} from "../../store/accomodationReducer.js"
import {updateTransferDetails,updateTransferlistDetails} from "../../store/transferReducer.js"
import {addRefcode} from "../../store/transferReducer.js"
import { useSelector,useDispatch } from 'react-redux';
import { useEffect } from 'react';
import useClosestOverflowParent from '../../hooks/useClosestOverflowParent.js';
import { centerElement, transferReduxData,dateUi_format,accomodationReduxData } from '../../utility/globalFunc.js';
import { MORE_OPTIONS_MODE, ROOMPAXDATA } from '../../constants.js';
import useHotelList from '../Accomodation/hooks/useHotelList.js';
import { resetActionMode, setActionMode } from '../../store/globalReducer.js';
import useMoreOptionQuotation from '../../hooks/useMoreOptionQuotation.js';
import usePopupData, { ADD_SERVICE_BOOKING, DELETE_QUOTE,REMOVE_SERVICE_ITEMS,EXPORT_PDF_POPUP,SEND_MAIL } from '../../hooks/usePopupData.js';

export function Client_dropdown({data,label,phase,roomId,setOptionData,optionList}) {

  
    const [selectedClient, setSelectedClient] = useState(label);
    const [roomClient, setRoomClient] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);
    const {clientName} = useSelector((state) => state.accomodationreducer)
    const btnref = useRef(null);
    const drpdownref = useRef(null);
    const dispatch = useDispatch();
    const overflowParent=useClosestOverflowParent(drpdownref);


      const selectedItem = (e,item) => {
        e.preventDefault();
        if(phase === 1){
          let{name,value}=item
          setOptionData(prevAgeData => prevAgeData.map(room => 
            room.room_id === roomId ? { ...room, clientType: [name,value] } : room
          ));
          setSelectedClient([name,value]);
        }
        else{
          let {name,value}=item;
          setSelectedClient(name);
          let payload = {value:value,name:name,roomId:roomId}
          dispatch(accomClient(payload))
        }
        setOpenPopup(false);
      };
  
      useOutsideClick(btnref, () => {
        setOpenPopup(false);
      });

    
      useEffect(()=>{
        if (openPopup) {
          /* setTimeout(() => {
            drpdownref.current?.scrollIntoView({
              behavior:"smooth",
              block:"center",
            });
          }, 100); */
          centerElement(drpdownref.current,overflowParent.current);
        }
      },[openPopup])

    return (
        <div className='client_parent_dropdown' ref={btnref}>
        <p>Client type:</p>
          <div className={'client-select '+(label !== selectedClient[0] ? "selected":"")+ (openPopup?" pax-clicked":"")} ref={drpdownref} onClick={(e)=>{setOpenPopup(!openPopup)}}>{Array.isArray(selectedClient) ? selectedClient[0]:selectedClient}</div>
          {
            openPopup && phase === 1 ? <div className='client-options' >{data.map((item,index) => ( <div key={index} className={item.name === selectedClient[0] ? "opt-selected":"" } onClick={(e)=>{selectedItem(e,item)}}>{item.name}</div>))}</div>
            :openPopup && phase === 2 ? <div className='client-options' >{data.map((item,index) => ( <div key={index} className={item.name === selectedClient[0] ? "opt-selected":"" } onClick={(e)=>{selectedItem(e,item)}}>{item}</div>))}</div>
            :null
          }
        </div>
    );
}

export function Filter_dropdown({data,label,phase,roomId,setOptionData,optionList}) {

  
  const [selectedClient, setSelectedClient] = useState(label);
  const [roomClient, setRoomClient] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const {clientName} = useSelector((state) => state.accomodationreducer)
  const btnref = useRef(null);
  const drpdownref = useRef(null);
  const dispatch = useDispatch();
  const overflowParent=useClosestOverflowParent(drpdownref);


    const selectedItem = (e,item) => {
      e.preventDefault();
      if(phase === 1){
        let{name,value}=item
        setOptionData(prevAgeData => prevAgeData.map(room => 
          room.room_id === roomId ? { ...room, clientType: [name,value] } : room
        ));
        setSelectedClient([name,value]);
      }
      else{
        let {name,value}=item;
        setSelectedClient(name);
        let payload = {value:value,name:name,roomId:roomId}
        dispatch(accomClient(payload))
      }
      setOpenPopup(false);
    };

    useOutsideClick(btnref, () => {
      setOpenPopup(false);
    });

  
    useEffect(()=>{
      if (openPopup) {
        /* setTimeout(() => {
          drpdownref.current?.scrollIntoView({
            behavior:"smooth",
            block:"center",
          });
        }, 100); */
        centerElement(drpdownref.current,overflowParent.current);
      }
    },[openPopup])

  return (
      <div className='client_parent_dropdown' ref={btnref}>
      <p>Client type:</p>
        <div className={'client-select '+(label !== selectedClient[0] ? "selected":"")+ (openPopup?" pax-clicked":"")} ref={drpdownref} onClick={(e)=>{setOpenPopup(!openPopup)}}>{Array.isArray(selectedClient) ? selectedClient[0]:selectedClient}</div>
        {
          openPopup && phase === 1 ? <div className='client-options' style={{pointerEvents:"none"}}>{data.map((item,index) => ( <div key={index} className={item.name === selectedClient[0] ? "opt-selected":"" } onClick={(e)=>{selectedItem(e,item)}}>{item.name}</div>))}</div>
          :openPopup && phase === 2 ? <div className='client-options' style={{pointerEvents:"none"}}>{data.map((item,index) => ( <div key={index} className={item.name === selectedClient[0] ? "opt-selected":"" } onClick={(e)=>{selectedItem(e,item)}}>{item}</div>))}</div>
          :null
        }
      </div>
  );
}

export function DefaultDropdown({data,label,onChange=(item)=>{},defaultValue=null}){
  const [selected,setSelected]=useState(defaultValue);
  const [firstRender,setFirstRender]=useState(true);
  const btnref=useRef(null);
  const drpdownref=useRef(null);
  const [openPopup, setOpenPopup] = useState(false);
  
  useEffect(()=>{
    if (firstRender) {
      setFirstRender(false);
    }else{
      onChange(selected);
    }
  },[selected])

  useOutsideClick(btnref,()=>{
    setOpenPopup(false);
  });

  const getLabelValue=(item)=>{
    if (item.name) {
      return item.name;
    }else{
      return item;
    }
  }

  return (
    <div ref={btnref} className='select-container'>
      <div className={'select-data'+(selected?" selected":"")+(openPopup ? " open":"")} ref={drpdownref} onClick={(e)=>{setOpenPopup(!openPopup)}}>{selected?getLabelValue(selected):label}</div>
      {openPopup&&
        <div className='select-options'>
          {data.map((item,index) => { 
            let labelValue=getLabelValue(item);
            {/* console.log("labelValue:",labelValue) */}
            return (
              <div key={index} className={item === selected ? "opt-selected":"" } onClick={(e)=>{setSelected(item)}}>
                <span>{labelValue}</span>
              </div>)
          })}
        </div>
      }
    </div>
  );
}

export function QuotationActionDropdown({setPopupOpen,file_name,isView = false,data,isaddindService=false,CancelBooking}){
  const dropdownRef=useRef(null);
  const [toastId, setToastId] = useState(null);
  const [showError,setShowError]=useState(false);
  const [errorClass,setErrorClass]=useState(false);
  const {initAddItemsMode}=useMoreOptionQuotation();
  const {loading,fetchHotelTagList,buildFetchListPayload} = useHotelList();
  const { isOpen, openPopup, closePopup, getPopupContent } = usePopupData();

  const dispatch = useDispatch()
  let {reservationAccom=[],reservationTransfer=[],ToRef=[]}=data

  const allService = [...reservationAccom, ...reservationTransfer];

  // useOutsideClick(dropdownRef,()=>{
  //   setPopupOpen(false);
  // })

  const handleMouseEnter = () => {
    setErrorClass(true);
    setShowError(true);
  };

  const handleMouseLeave = () => {
    setErrorClass(false);
    setTimeout(() => {
      setShowError(false);
    }, 100);
  };

  const addNewItems=()=>{
   
    let ui_date = dateUi_format(reservationAccom[0]?.Rooms[0].Dates)
    dispatch(setActionMode(MORE_OPTIONS_MODE.ADD_ITEM))
    dispatch(addRefcode(ToRef))

    // adding data to redux for accom
    // we cant use quoteBookList because it was pointed to selRooms in accom
    let accomRedux = accomodationReduxData(reservationAccom)
    let transferRedux = transferReduxData(reservationTransfer)
    console.log("accom data: ",accomodationReduxData(reservationAccom));
    console.log("transfer data:", transferRedux);
    
    dispatch(accomDates(ui_date))
    dispatch(setSelRooms(accomRedux))
    dispatch(updateTransferDetails(transferRedux))
    dispatch(updateTransferlistDetails(transferRedux))

    let sendObject = buildFetchListPayload()
    let redirect="/accomodation-select"
    let module = 0
    let page=1    
    fetchHotelTagList(sendObject,redirect,module,page,true); 
  }

  const openRemoveItemsPopup=(e)=>{
    dispatch(setActionMode(MORE_OPTIONS_MODE.REMOVE_ITEM))
    openPopup(REMOVE_SERVICE_ITEMS,"",data)
  }

  const addingService = () =>{
    openPopup(ADD_SERVICE_BOOKING)
  }

  const CancelBookingChild = () =>{
    if(isaddindService){
      CancelBooking()
    }
  }


  return (
    <>
    {showError&&<div className={`toast-error fade-${errorClass?"in":"out"}-100`}>
      <span>To remove item, your quotation needs to have a minimum of 2 items</span>
    </div>}
    <div className='dropdown-container' ref={dropdownRef}>
      {!isView && <div onClick={()=>{isaddindService ?addingService() :addNewItems()}}>Add items</div>}
      {/* {!isView && reservationAccom.length >1 && <div className='disabled' onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>Remove items</div>} */}
      {!isView && 
        <div 
          className={allService.length && allService.length === 1 ? "disabled":allService.length} 
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleMouseEnter}
          onClick={openRemoveItemsPopup} 
        >
          Remove items
        </div>
      }

      <div onClick={(e)=>openPopup(SEND_MAIL)}>Send via mail</div>
      <div onClick={(e)=>openPopup(EXPORT_PDF_POPUP)}>Export in PDF</div>
      {isaddindService && <div onClick={CancelBookingChild}>Cancel booking</div>}
      {!isView && <div onClick={(e)=>openPopup(DELETE_QUOTE,"",ToRef)}>Delete quotation</div>}
    </div>
    {isOpen && getPopupContent()}
    </>
  );
}
