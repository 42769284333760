import { useEffect, useRef } from 'react';

const useClosestOverflowParent = (ref) => {
  const getClosestOverflowParent = (element) => {
    if (!element) return null;
    const style = getComputedStyle(element);
    if (style.overflow !== 'visible' || style.overflowY !== 'visible' || style.overflowX !== 'visible') {
      return element;
    }
    return getClosestOverflowParent(element.parentElement);
  };

  const overflowParentRef = useRef(null);

  useEffect(() => {
    if (ref.current) {
      overflowParentRef.current = getClosestOverflowParent(ref.current);
    }
  }, [ref]);

  return overflowParentRef;
};

export default useClosestOverflowParent;
