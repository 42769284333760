import React,{useState,useEffect} from 'react';
import Dropdowns from '../Transfer/Dropdowns';
import mapImg from "../../Assets/images/mapImg.png"
import NavbarBlock from '../Transfer/NavbarBlock.js';
import {DROPDOWN_DATA,accomHeading} from "../../constants"
import ExcursionInputs from './ExcursionInputs';
import QuoteExcursion from './QuoteExcursion';
import Accessibility from './Accessibility';
import TrackExcursion from './TrackExcursion';
import Notes from './Notes';
import Feedback from './Feedback';
import MapContainer from "../MiniComponents/MapContainer.js"
import Quotation from "../Transfer/Quotation.js";
import ImageCarosel from './ImageCarosel';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Footer from '../Transfer/Footer';


function SelectedExcursion(props) {
    const [openMap ,setOpenMap] = useState(false)
    const {quotation,selExcursion} = useSelector((state) => state.excursionreducer)
    const location = useLocation();
    let {
        name,
        type,
        heading ,
        desc,
        pois,
        route=[],
        position,
        notes,
        feedback,
        img, 
         }=location.state.result
 
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);     

    return (
        <>
        <NavbarBlock/>
        <div className='SelectedExcursion_parent'>
            
            <div className='SelectedExcursion_left'>
                <div className='SelectedExcursion_header'>
                    <div className='back_items'>Back | <span>Excursion List</span></div>
                    <Dropdowns dropData={DROPDOWN_DATA} imgcount={3} name="Excursion"/>
                    <div style={{float:"left"}}><ExcursionInputs alignbtn={false} hideBtmsearch={true} /></div>
                </div>
                <div className='SelectedExcursion_body'>
                    <div className='excursion_name'>{name}</div>
                    <div className={type ? 'excursion_type':""}>{type}</div>
                    <ImageCarosel data ={img}/> 
                    <div className='excursion_title'>{heading}</div>
                    <div className='excursion_detail'>{desc}</div> 
                    <div className='SelectedExcursion_split'>
                        <div>
                            <label className='labeltxt'>View Your excursion</label>
                            <div className='mapBlock'>
                                <img src={mapImg} alt="map img" />
                                <button onClick={(e)=>{setOpenMap(true)}}>View on map</button>
                            </div>
                                <div style={{margin:"72px 0 48px 0",float:"left"}}>
                                <TrackExcursion 
                                    label={pois.length ? "point_of_interests":route.length ? "Route Excursion" :""}  
                                    point={pois.length ? 2 :route.length ? 1 :"" } 
                                    data={pois.length ? pois: route.length ?route :[] } 
                                    fullWidth={true}
                                    labelSize={1}
                                />
                            </div>
                                <Notes data ={notes && notes.length ? notes:[]} label ="Notes"/> 
                        </div>
                        <div >
                             <Accessibility list={location.state.result}   label="Details" inc={true} islang={true} isacces={true} />
                            <div className='split_second'>
                                <Feedback data={ feedback && feedback.length ? feedback:[]}/> 
                            </div>
                        </div>
                    </div>    
                </div>
                <div style={{marginTop:"50px",float:"left"}}>
                      <QuoteExcursion allData = {location.state.result} details={location.state.details}/>  
                </div>
            </div>
            {quotation ?<div className='fixedQuotation'><Quotation cname="Excursion" details={selExcursion} /></div>:null}
            {openMap ? 
                <MapContainer 
                    setOpenMap={setOpenMap} 
                    alldata={location.state.result} 
                    details={location.state.details} 
                    mappoints={route.length ? route:position} 
                    name={route.length ? true:false}
                />
                :null
            }
           
        </div>
        <Footer/>
        </>
    );
}

export default SelectedExcursion;