import React, { useRef, useState,useEffect } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import useMoreOptionQuotation from '../../hooks/useMoreOptionQuotation'
import useOutsideClick from '../../hooks/useOutsideClick';
import SameInputs from "../Excursion/SameInputs";
import useAddItems from '../Quotations/hooks/useAddItems';
import TableLayoutNew from './TableLayoutNew';
import { MORE_OPTIONS_MODE } from '../../constants';
import {setActionMode} from "../../store/globalReducer"


export function CancelUpdatePopup({closePopup}) {
    const {refCode}=useSelector(state=>state.transfer_Global)
    const {cancelAddItemMode}=useMoreOptionQuotation();
    const [className,setClassName]=useState("");
    const popupRef=useRef();
    const handleCancel=(e)=>{
      e.preventDefault();
      animateClose(cancelAddItemMode);
    }

    const animateClose=(additionalAction)=>{
      setClassName("fade-out-300");
      setTimeout(() => {
        closePopup();
        setClassName("");
        additionalAction&&additionalAction()
      }, 200);
    }

    useOutsideClick(popupRef,()=>{
      animateClose();
    })

  return (
    <div className={`cancel-update-container ${className}`} ref={popupRef}>
        <h4 className='cancel-update-title'> You are about to delete?</h4>
        <p className='refCode-subtitle'><span className='refCode-label'>“{refCode}”</span>&nbsp;quotation</p>
        <button onClick={handleCancel}  className='cancel-update-button'>Cancel</button>
        <div onClick={()=>animateClose} className='continue-update-button'>Continue adding items</div>
    </div>
  )
}


export const QuoteDeletePopup =({name,closePopup})=>{
  const closePopref =useRef(null)
  const {deleteQuotation}=useAddItems()
  const dispatch = useDispatch();


  useOutsideClick(closePopref,()=>{
    dispatch(setActionMode(null))
    closePopup();
  })

  const callDeletequote=()=>{
    let payload ={"to_ref":name,"service_type":"ALL"}
    deleteQuotation(payload,MORE_OPTIONS_MODE.DELETE_QUOTATION)
  }


  return(
      <div className='cancel-update-container' ref={closePopref}>
          <h4 className='cancel-update-title'> You are about to delete?</h4>
          <p className='refCode-subtitle'><span className='refCode-label'>“{name}”</span>&nbsp;quotation</p>
          <div style={{display:"flex",gap:"20px"}}>
            <button className='continue-update-button' onClick={()=>closePopup()}>Cancel</button>
            <button className='cancel-update-button' onClick={()=>callDeletequote()}>Delete</button>

          </div>
          
      </div>
  )
}


export const QuoteDeleteService =({data,closePopup})=>{
  const closePopref =useRef(null)
  const {quoteBookList} =useSelector((state) => state.globalreducer)
  const dispatch = useDispatch();

  // const {deleteQuotation}=useAddItems()
  useOutsideClick(closePopref,()=>{
    dispatch(setActionMode(null))
    closePopup();
  })



  return(
      <div className='delete_item_popup' ref={closePopref}>
         <p>Remove item from your quotation</p>
         <TableLayoutNew lists={quoteBookList} total={10}/>
      </div>
  )
}

export const ExportPdfPopup =({data,closePopup})=>{
  const closePopref =useRef(null)
  const {quoteBookList} =useSelector((state) => state.globalreducer)
  const dispatch = useDispatch();

  // const {deleteQuotation}=useAddItems()
  useOutsideClick(closePopref,()=>{
    dispatch(setActionMode(null))
    closePopup();
  })



  return(
      <div className='exportpdf-parent' ref={closePopref}>
        <p>Export quotation</p>
        <p>Your quotation will be generate in PDF Format</p>
        <button>Export quotation</button>
      </div>
  )
}

export const QuotationSendMail =({closePopup})=>{
  const closePopref =useRef(null)

  useOutsideClick(closePopref,()=>closePopup()) // outside click
  
  const getPdf=()=>{

  }
  getPdf()
  const label ="Include items technical sheet"

  return(
      <div className='quotation-mail-popup' ref={closePopref}>
        <p>Send quotation via e-mail</p>
        <form>
            <input type="text" placeholder='Recipient*' required/>
            <input type="text" placeholder='Subject' required/>
            <textarea 
                name="emailContent" 
                placeholder="Write your email here..." 
                rows="10" 
                cols="30"
            >
            </textarea>

            <div>pdf attach</div>
            <div className='button-block'>
              {/* <label><input type="checkbox" name="subscription" /> {label}</label> */}
              <div><button type="submit" >Send</button></div>
            </div>
        </form>
      </div>
  )
}




