import React, { useEffect, useState } from 'react';
import {DEFAULT_DATE_FORMAT, filterTeens, getOrdinal, ISO_8601_DATE_FORMAT, pluralize} from "../../constants";
import {calculateNights, formatStrDate, getDateFromString, getOrdinals, isServiceAccomodation, isServiceTransfer, properCase} from "../../utility/globalFunc.js"
import { useSelector } from 'react-redux';
import DOCKET_IMG from "../../Assets/images/global-icon/booking-docket.svg";
import ZIGZAG_BOTTOM from "../../Assets/images/global-icon/zigzag.svg";
import { ImageWithFallBack } from '../MiniComponents/Atom.js';
import DefaultRoomImg from "../../Assets/images/default-img/room.jpg";
import { format, isValid } from 'date-fns';
import LazyImage from '../MiniComponents/LazyImage.js';
import arrival_plane from "../../Assets/images/savetransfer_plane.png"
import departure_plane from "../../Assets/images/departure-plane.png"
import arrow from "../../Assets/images/global-icon/next-arrow-black.svg"
import hotel from "../../Assets/images/green-bed.png"

function Booking_summary({data,dateRange=['','']}) {
    
    const countItems=()=>{
        return data.length;
    }

    let ordinalsTransfer = 0;
    return (
    <div className='booking-docket-container'>
        <div className='booking_summary_parent'>
            <h5>Your Booking</h5>
            {dateRange&&<div className='date-range-container'>
                Booking file date range: <span>{dateRange.dateStart}</span><span>{dateRange.dateEnd}</span>
            </div>}
            <div className='summary_loop'>
                {

                    // dont remove because we nned this sttucture
                    // data.map((data)=>{
                    //     return(
                    //         <div key={data.IdTransfer} className='summary_loopblk'>
                    //             <label>Transfer <span className="hotelImg"></span></label>
                    //             <div className='summary_img_cnt'>
                    //                 <div className='summaryImg'><img src={data.pic_url} alt="img" width={161} height={134}/></div>
                    //                 <div className='summaryData'>
                    //                     <div className='summary_locations'>
                    //                         <div> {data.LocationFromName } &rarr;{<span className={data.LocationFromId === 752 ? "airImg":"hotelImg"}></span>} </div>
                    //                         <div>{data.LocationToName} {<span className={data.LocationToId === 752 ? "airImg":"hotelImg"}></span>}</div>
                    //                     </div>
                    //                     {/* <div className='summaryPax-count'>{data.pax.name} {data.pax.count}</div>  */}
                    //                     <div className='summaryPax_btn'>
                    //                         <div className='summaryPax-count'>Adult {Adult}</div> 
                    //                         <div className='summaryPax-count'>Child {Child}</div>
                    //                         <div className='summaryPax-count'>Teen {Teen}</div>
                    //                         <div className='summaryPax-count'>Infant {Infant}</div>
                    //                     </div>
                    //                     <div className='sumVehicle'>{data.vehicle_type}</div>
                    //                     <div className='summDate'>{data.TransferDate}</div>
                    //                 </div>
                    //             </div>
                    //         </div>
                    //     )
                    // })
                }


                {
                data.map((data,ind)=>{
                    if(data.Rooms){
                        return data.Rooms.map((item,indItem)=>{
                        return(
                            <div key={data.HotelId+indItem+ind} className={'summary_loopblk '+data.HotelId+indItem+ind}>
                                <label>Accommodation <p className="hotelname">{data.HotelName}</p></label>
                                <div className='summary_img_cnt'>
                                    <div className='summaryImg-container'>
                                        <LazyImage className={"summaryImg"} src={item.RoomImage} fallBackImg={DefaultRoomImg} alt="img" hasFallback={true}/>
                                    </div>
                                    <div className='summaryData'>
                                        <div className='summaryData-text'><span className='summaryData-text-chambre'>{item.RoomName}</span></div>
                                        <div className='summary_locations dates'>
                                            <div className='summary_locations dates first-date'>  {formatStrDate(item.Dates[0],ISO_8601_DATE_FORMAT,DEFAULT_DATE_FORMAT)}</div>&rarr;
                                            <div className='summary_locations dates first-date'> {formatStrDate(item.Dates[1],ISO_8601_DATE_FORMAT,DEFAULT_DATE_FORMAT)}</div>
                                        </div>
                                        <div className='summaryData-text nights-docket'>{calculateNights(item.Dates[0],item.Dates[1])} nights</div>
                                        
                                        <div>
                                        <div className='summaryData-text person-icon'>{item.AdultCount} {pluralize(item.AdultCount,"Adult","Adults")}</div>
                                            {item.TeenCount > 0 && <div className='summaryData-text person-icon'><div>{item.TeenCount+" "+ pluralize(item.TeenCount,"Teen","Teens")}</div></div>}
                                            {item.ChildCount > 0 && <div className='summaryData-text person-icon'><div>{item.ChildCount+" "+ pluralize(item.ChildCount,"Child","Children")}</div></div>}
                                            {item.InfantCount > 0 && <div className='summaryData-text person-icon'><div>{item.InfantCount+" "+ pluralize(item.InfantCount,"Infant","Infants")}</div></div>}
                                        </div>
                                        
                                        <div className='summaryData-text meal-plan-icon'><b>Meal Plan</b> {item.MealPlan}</div>
                                    </div>
                                </div>
                            </div>
                        )
                        })
                    }
                    if (data.hasOwnProperty('IdTransfer') || data.hasOwnProperty('TransferCategoryName')){
                        { ordinalsTransfer ++}
                        return(
                        <div key={data.IdTransfer} className='summary_loopblk'>
                                <label> {ordinalsTransfer}{getOrdinal(ordinalsTransfer)} Transfer <span className="hotelImg"></span></label>
                            
                            <div className="summary_locations">
                                <div>
                                    <span className='locationFrom'>{properCase(data.LocationFromName)}</span>
                                    <img className='icon_summary' src={data.LocationFromId === 752 ? arrival_plane : hotel} alt="img"/>
                                    <img className='icon_summary arrow' src={arrow} alt="img" />
                                </div>
                               
                                <div>
                                    <span className='locationFrom'>  {properCase(data.LocationToName)}</span>
                                        <img className='icon_summary' src={data.LocationToId === 752 ? departure_plane : hotel} alt="img"/>
                                </div>
                            </div>

                            <div className='summary_img_cnt'>
                                <div className='summaryImg'><img src={data.Pic_url} alt="img" width={161} height={134}/></div>
                                <div className='summaryData'>
                                     <div className='summary_locations dates'>
                                        <div className='summary_locations dates first-date'>  {data.TransferDate}</div>
                                    </div>
                                    <div className='sumVehicle'>{data.Vehicle_type}</div>
                                    <div className='summaryData-text person-icon'>{data.AdultCount} {pluralize(data.AdultCount,"Adult","Adults")}</div>
                                    <div className='summaryData'>
                                        {data.TeenCount > 0 && <div className='summaryData-text person-icon'><div>{data.TeenCount+" "+ pluralize(data.TeenCount,"Teen","Teens")}</div></div>}
                                        {data.ChildCount > 0 && <div className='summaryData-text person-icon'><div>{data.ChildCount+" "+ pluralize(data.ChildCount,"Child","Children")}</div></div>}
                                        {data.InfantCount > 0 && <div className='summaryData-text person-icon'><div>{data.InfantCount+" "+ pluralize(data.InfantCount,"Infant","Infants")}</div></div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    }
                    return null;
                        
                    })
                }
            </div>
            <i className='icon-dashed-line-grey'></i>
            <div className='nb-item-container'>
                {countItems()+" "+pluralize(countItems(),'item','items')} in total
            </div>
        </div>
        <img alt='docket' src={ZIGZAG_BOTTOM} className='docket-zigzag'/>
    </div>
    );
}

export default Booking_summary;