import React, { forwardRef,Fragment, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {DateAtom} from "../Accomodation/minicomponents/Atom.js";
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import { updateRegion,clearTransfer  } from "../../store/transferReducer.js";
import useFinalBooking from '../../hooks/useFinalBooking.js';
import useBookingService from "../../hooks/useBookingService.js"
import {splitDescription,capitalizeFirstLetter, pluralize, BOOKING_PAGE,DROPDOWN_DATA, NAVBAR_HEIGHT, checkAllRoomValid,MORE_OPTIONS_MODE, checkAllTransferValid} from "../../constants"
import {calculateNights, centerElement, formatDate,dateToConventionalFormat, getElementNbOfLines, getOrdinals, scrollToDependsBoundary, scrollToElement,getBeforeUnderscore, formatPrice, separateurMillier} from "../../utility/globalFunc.js"
import useOutsideClick from '../../hooks/useOutsideClick.js';
import useClosestOverflowParent from '../../hooks/useClosestOverflowParent.js';
import usePopupData from '../../hooks/usePopupData.js';
import {clearexcursion} from "../../store/excursionReducer.js"
import {clearPax,clearAll} from "../../store/globalReducer.js"
import {clearAccom} from "../../store/accomodationReducer.js"
import cotationclose from "../../Assets/images/close_cotation1.png"
import DefaultMainHotelImage from "../../Assets/images/default-img/hotel-main.jpg"
import arrowPagination from "../../Assets/images/arrow-pagination.png";
import arrowNoPagination from "../../Assets/images/arrow-no-pagination.png";
import AmenitiesStatic from '../../Assets/images/accomodation/staticImages/amenitiesStatic.png';
import Amenities from './Amenities.js';
import LazyImage from './LazyImage.js';
import { getOrdinal } from '../../constants';
import useAddItems from '../Quotations/hooks/useAddItems.js';
import CircularProgress from '@mui/material/CircularProgress';
import successImageNotif from "../../Assets/images/popup/successBookingNotif.svg";
import declinedImageNotif from "../../Assets/images/popup/declinedBookingNotif.svg";
import trash from "../../Assets/images/delete.svg";
import deleteTransfer from "../../Assets/images/popup/deleteTransfer.svg";
import arrow from "../../Assets/images/arrow.svg";
export const ImageWithFallBack = forwardRef(({ src, alt, width, height, onLoad = () => { }, onClick = () => { }, style = {}, className = "", fallBackImg = "", onError = undefined, onMouseEnter}, ref) => {
    const handleError = (e) => {
        onError && onError(e);
        e.target.onerror = null;
        if (e.target.src !== fallBackImg) {
            e.target.src = fallBackImg;
            e.target.onerror = null;
        }else{
            e.target.onerror = null;
        }
    }
    if (width) {
        style.width = width;
    }
    if (height) {
        style.height = height;
    }
    useEffect(() => {
        if (!src) {
            onError && onError();
        }
    }, [src])
    return (
        <img src={src || fallBackImg} ref={ref} alt={alt} style={style} className={className} onLoad={onLoad} onClick={onClick} onError={handleError} onMouseEnter={onMouseEnter}/>
    )
})

export function Description ({label,details,listType}){
    const [nbLines,setNbLines]=useState(false);
    const descriRef=useRef(null);
    useEffect(()=>{
        let element=descriRef.current
        if (element) {
            let style=getComputedStyle(element)
            const maxHeight = parseFloat(style.maxHeight);
            const isMultiline = element.scrollHeight > maxHeight;
            setNbLines(isMultiline);
        }
    },[descriRef]);
    // styles are visually good confirmed ny malikah
    return(
        <div style={{color:"#505050"}}>
            <label className='description-label'><b className='description-title'>{label ? label:""}</b></label>
            <div style={{fontWeight:"400"}} ref={descriRef} className={`hotel-card-description ${nbLines?"limited-nbr-lines":""}`}>{details && listType ? splitDescription(details): !listType ?splitDescription(details): ""}</div>
        </div>
    )
}
export function NearBy_Region({ nearRegion }) {

    // styles are visually good confirmed by Malikah
    return (
        <div className='parent_container_near_region'>
            {/* //info temperature statique */}
            {/* <div className='container_near_region'> */}
                {/* <span className='infoTemp'>Temperature moyenne en juillet : 24°- 27°</span><br /> */}
            {/* </div> */}
            {nearRegion.map((region, key) => (
                <div key={key}  className='container_near_region'>
                    <span className='textRegion'>Near {region}</span><br />
                </div>
            ))}
        </div>
    );
}


export const Button =({styles,label})=>{
    return(
        <button style={styles}>{label}</button>
    )
}


export const PaxAge = ({ data, onAgeChange, isDisabled, setIsDisabled=undefined})=>{
    const ages = Array.from({ length: 18 }, (_, index) => index + 1);
    const [showDropdown,setShowDropdown]=useState(false);
    const [age,setAge]=useState(data[Object.keys(data)[0]]?data[Object.keys(data)[0]]:null);
    const elementRef=useRef(null);
    const overflowParent=useClosestOverflowParent(elementRef);
    
    const handleSelectChange = (value) => {
        if (value && setIsDisabled){
            setIsDisabled(false)
        }
        setAge(value);
        onAgeChange&&onAgeChange(value);
        setShowDropdown(false);
    };

    const onClickShow=()=>{
        setShowDropdown(!showDropdown)
    }

    useOutsideClick(elementRef,()=>{
        setShowDropdown(false);
    })

    useEffect(()=>{
        setTimeout(() => {
           centerElement(elementRef.current,overflowParent.current);
        }, 10);
    },[]);

    return(
        // <div style={{marginBottom:"10px"}}>
            <div className='sub-age-container'>
                <div className='category-title'>Age, {capitalizeFirstLetter(Object.keys(data)[0])}  {Object.values(data)[1]}:</div>
                <div className='select-age-wrapper' ref={elementRef}>
                <div className={"select-age "+(showDropdown?"clicked":"")+(isDisabled&&!age?" disabled":"")} value={data[Object.keys(data)[0]]} onClick={onClickShow}>
                    {age||'--'}
                    
                </div>
                {
                    showDropdown &&
                    <div className='age-choice-container'>
                        {
                            ages.map((item) =>(<div key={"age_"+item} className={age===item?"selected":""} onClick={()=>handleSelectChange(item)}>{item}</div>))
                        }
                    </div>
                }   
                </div>
            </div> 
        // </div>
    )
}


export const Back =({label,name, marginBottom=""})=> {
    const navigate = useNavigate();
    const { isOpen, openPopup, getPopupContent } = usePopupData();
    const {sortedList} = useSelector((state) => state.globalreducer)
    const {roomPax,pendingRooms,selRooms} = useSelector((state) => state.accomodationreducer)
    const pathname = window.location.pathname;
    const DEFAULT_PATH= "/accomodation"
     
    const style={marginBottom}
    const firstSubpages = DROPDOWN_DATA.map(item => item.subpage[0]);
    const isRestricted =  firstSubpages.includes(pathname)
    const isSortedListLength = Object.values(sortedList.services).some(array => array.length > 0);

    let {requiredNbRooms} = pendingRooms


    
    const goBack=(e)=>{
        e.preventDefault();
        
        if (pathname === '/selected-accomodation'){
            if(requiredNbRooms > 0){
                let isvalidRoom = checkAllRoomValid(pendingRooms)
                if(!isvalidRoom){
                    openPopup("dialogue-box","","/accomodation-select") 
                    return;
                }
            }
            navigate("/accomodation-select");
        }
        else if(pathname === '/accomodation-select' && selRooms.length ){
        //     console.log("pathname /accomodation-select test:",pathname);
        //     alert()
        //    if(requiredNbRooms !== selRooms.length) {
            // openPopup("dialogue-box","",DEFAULT_PATH) 
            navigate('/accomodation')
        //    }
        //    else{

        //    }
        }
        else{
            (isRestricted && isSortedListLength) ? openPopup("dialogue-box","",DEFAULT_PATH):navigate(`/${name}`);
        }
    } 


    return (
        <>
            <div className='back_items' style={style}>
                <div className='back-txt' onClick={(e)=>{goBack(e)}}>Back</div> 
                <div className='back-label'>{ label}</div>
            </div>
            {isOpen ? getPopupContent():<></>}
        </>
    );
}

// export const Supplement =({data,name,supmod,notes,lugtype,detailId,regionId,infant=0})=>{
    //const{ infant:Infant} =useSelector((state) => state.transfer_Global.transferPax[0])    
export const Supplement =({data,name,supmod,notes,lugtype,detailId,regionId,infant=0})=>{
    const { tempId: currentTransferId } = useSelector((state) => state.transfer_Global);
    const {ChildCount: Child,InfantCount: Infants } = useSelector((state) => state.transfer_Global.transferUpdates[currentTransferId]);
    let Infant = Child+Infants;
    // const{ infant:Infant} =useSelector((state) => state.transfer_Global.transferPax[0])    

    let {Currency:cur,BabySeatPrice,MaxAllowedBabySeat:max_baby_seats,LuggageCar,
        LuggageTruck,baby_seat_count:babyseat,lug_car_count:carlug,
        lug_truck_count:trucklug,final_price }=data


    let split = name.split(" ")
    const dispatch = useDispatch()
    
    const handleCounter=(e,name)=>{
        e.preventDefault();
        // console.log("babyseat",babyseat,data);
        
        if(lugtype === ""){
            if (Infant>0){
                if (name === "inc" &&  max_baby_seats > babyseat) {
                    let count = babyseat + 1
                    let price = BabySeatPrice * count
                    let finalprice = final_price + BabySeatPrice
                    dispatch(updateRegion({ detailId, regionId, name: "baby_seat_count", count, price, pname: "new_babyseat_price", finalprice }))
                }
                else if (name === "dec" && babyseat > 0) {
                    let count = Math.max(0, babyseat - 1)
                    let price = BabySeatPrice * count
                    let finalprice = final_price - BabySeatPrice
                    dispatch(updateRegion({ detailId, regionId, name: "baby_seat_count", count, price, finalprice }))
                }
            }
            else{
                alert("There’s no Infant or Child in your pax selection" )
            }
        }
        else if (lugtype === "Cars"){
            // && carlug < luggage_car.capacity
            if (name === "inc" && LuggageCar?.capacity > carlug ){
                let count = carlug +1
                let price = LuggageCar?.price * count
                let finalprice = final_price + LuggageCar?.price
                dispatch(updateRegion({detailId,regionId,name:"lug_car_count",count,price,finalprice}))

            }
            else if (name === "dec" && carlug > 0){
                let count = Math.max(0, carlug - 1)
                let price = LuggageCar?.price * count
                let finalprice = final_price - LuggageCar?.price
                dispatch(updateRegion({detailId,regionId,name:"lug_car_count",count,price,pname:"LuggageCar",finalprice}))

            }
        }
        //truck
        else{
            // && trucklug < luggage_car.capacity
            if (name === "inc" && LuggageTruck?.capacity > trucklug){
                 let count = trucklug +1
                 let price = LuggageTruck?.price * count
                 let finalprice = final_price + LuggageTruck?.price
                 dispatch(updateRegion({detailId,regionId,name:"lug_truck_count",count,price,pname:"LuggageTruck",finalprice}))
 
            }
            else if (name === "dec" && trucklug > 0){
                let count = Math.max(0, trucklug - 1)
                let price = LuggageTruck?.price * count
                let finalprice = final_price - LuggageTruck?.price
                dispatch(updateRegion({detailId,regionId,name:"lug_truck_count",count,price,pname:"LuggageTruck",finalprice}))
            }
        }
        
    }


    return(
        <>
            {/* <div className={'supplement-row '+(notes === true ? "":Infant === 0 && notes === false ? "order":"")}> */}
            <div className={'supplement-row '}>
                <div className={'sup-text '+(supmod ===2 ? "sup-text-lugg":"")}>
                    <div>{split[0]}<br/>{split[1]}</div>
                    <div className='sup-txtryt'>
                        {supmod === 1 ? "Add your baby seat":"Additional luggage capacity" }<br/>
                        <span>{lugtype === "" ? `This car can only take ${max_baby_seats} baby seats`: lugtype === "Cars"?LuggageCar?.capacity:LuggageTruck?.capacity }</span>
                    </div>
                </div>
                {notes ? <div className='sup-notes-capacity'>Please note this item is for extra luggage in addition of your luggage already included </div>:<></>}
                <div className='sup-button'>
                    <div><span>{cur ==="MUR" ?<span>&#8360;</span>:cur ==="EUR" ? <span>&euro;</span>:<span>&#x0024;</span>} {lugtype === "" ? BabySeatPrice : lugtype === "Cars" ?LuggageCar?.price:LuggageTruck?.price }.00 </span></div>
                    <div className='supplement-count'>
                        <span onClick={(e)=>{handleCounter(e,"dec")}}></span>
                        <div className='paxValueBox'>{lugtype === "" ? babyseat :lugtype === "Cars" ? carlug+` Car${carlug >1 ? "s":""}` : trucklug+` Truck${trucklug >1 ? "s":""}`}</div> 
                        <span onClick={(e)=>{handleCounter(e,"inc")}}></span>
                    </div>
                </div>
                
            </div>
        </>
    )
}

export const CarDetails =({carInfo})=>{
    //let {min_pax,max_pax,air_condition,luggage_capacity,max_baby_seats,cat_name}=carInfo
    let {Category,MaxPax,MinPax,MaxLuggage,MaxAllowedBabySeat}=carInfo
    
    return(
        <>
            <div className="carCategory">{Category}</div>
            <div className="car_details_block">
                <div className="car_details"><span>Maximum passenger capacity</span>{MinPax} - {MaxPax} pax </div>
                <div className="car_details"><span>Air conditioned vehicle</span>{""} </div>
                <div className="car_details"><span>Maximum luggage capacity</span>{MaxLuggage} </div>
                <div className="car_details"><span>Baby/booster/child seats</span><p style={{color:"#6DA767"}}>{MaxAllowedBabySeat}</p> </div>
            </div>
        </>
        
    )
}

export const HeadingNav =forwardRef(({data,updatefn,selId},ref)=>{
    const parentRef=useRef(null);
    const childsRef=useRef([]);
    const [barWidth,setBarWidth]=useState(200);
    const [barMarginLeft,setBarMarginLeft]=useState(0);

    useImperativeHandle(ref,()=>({
        scrollIntoView:()=>{
            scrollToElement(parentRef.current,"start","smooth",-NAVBAR_HEIGHT-1);
        },
        clickOption:(index)=>{
            let optionRef=childsRef.current[index];
            if (optionRef) {
                optionRef.click()
            }
        }
    }))

    useEffect(() => {
        if (parentRef.current) {
          const childElement = parentRef.current.children[selId];
          if (childElement) {
            setBarWidth(childElement.offsetWidth)
            let marginLeft=selId===1?0:childElement.offsetLeft;
            setBarMarginLeft(marginLeft);
          }
        }
      }, [selId]);
    return(
        <div className='accom-Heading' ref={parentRef}>
            <div className='bar-selected' style={{ width:`${barWidth}px`,marginLeft:`${barMarginLeft}px` }}></div>
           {data.map((item, index) => (
            <div ref={ref=>childsRef.current[index]=ref} key={index} className={selId === index+1 ? 'labels selected':'labels'} onClick={(e)=>{updatefn(index+1)}}>
                <span>{item}</span>
            </div>
            ))}
        </div>
    )
})

export const AmenitiesPattern =({data=[],pattern="lr"})=>{
    
    return(
        <Fragment key={"amenities-pattern"}>
            {
                data && data.map((item,ind)=>{
                    const patternId=pattern[ind%pattern.length];
                    console.log("item aa",ind, item);

                    return(
                        <Fragment key={"amenities-item"+ind}>
                            {
                                typeof item === 'object' ?
                                    <div key={ind} className={ `accordion-section ${patternId}`}>
                                    {
                                        typeof item === 'object' ?
                                        < >
                                            <div className='accordion-image-container'>
                                                <LazyImage 
                                                    className='accordion-image' 
                                                    src={item === null || item?.img === null ?AmenitiesStatic:item.img} 
                                                    alt="amenities img" 
                                                    effect='blur'
                                                    />
                                            </div>
                                            <div>
                                                <b>{item?.title}</b><span>{item?.desc}</span>
                                            </div>
                                        </>
                                        :<>{item}</>
                                        
                                    }
                                    </div>
                                :<div className='other-element'>asdf</div>    
                            }
                       
                        </Fragment>
                    )
                })
            }
        </Fragment>
        
    )
}
export const AccomTable =({list})=>{

    let nbNights=calculateNights(list.date[0],list.date[1]);
    return(
        <>
            <div className='first-item-category'>
                {/* <div className='accomIcon-table'>Accomodation</div> */}
                <CategoryItem icon={"../../Assets/images/global-icon/accomodation-icon.svg"} title={"Accomodation"}>
                    <div className='category-hotel-name'>{list.hotelName}</div>
                </CategoryItem>
            </div>
            <div>
                <div className='nb-nights'>{nbNights} {pluralize(nbNights,"night","nights")}</div>
                <div className='hotel-name'>{list.name}</div>
                <div className='client-type'>Client type STD</div>
                <div>
                    <b>Meal Plan :</b>
                    <span className='meal-plan'>{list.plan}</span>
                </div>
            </div>
            <div className='date-container'>
                <span className='date'>{list.date[0] } <span className='date-arrow'>&rarr;</span> </span>
                <span className='date'>{list.date[1]}</span> 
            </div>
            <div><PaxDetailsTags paxDetails={list.roomPax[0]}/></div>
            <div className='total-line-price'>EUR {list.price.toLocaleString()}</div>   
        </>
    )
}

export const AccomTableV1 =({list})=>{
    return(
        <>
            <div className='first-item-category'>
                {/* <div className='accomIcon-table'>Accomodation</div> */}
                <CategoryItem icon={"../../Assets/images/global-icon/accomodation-icon.svg"} title={"Accomodation"}>
                {list.reservationAccom.map((reservationAccomData,indexOne)=>{
                    return(
                        <div className='category-hotel-name' key={'reservationAccomData'+indexOne}>{reservationAccomData.HotelName}</div>
                    )
                })}
                </CategoryItem>
            </div>
            <div>
                {list.reservationAccom.map((reservationAccomDataDetails,indexOne)=>{
                    let nbNights=calculateNights(reservationAccomDataDetails.Rooms[0].Dates[0],reservationAccomDataDetails.Rooms[0].Dates[1]);
                    return(
                        <Fragment key={indexOne}>
                            <div className='nb-nights'>{nbNights} {pluralize(nbNights,"night","nights")}</div>
                            {reservationAccomDataDetails.Rooms.map((reservationAccomDataRoom,indexTwo)=>{
                                return(
                                   < Fragment key={indexTwo}>
                                    <div className='hotel-name'>{reservationAccomDataRoom.RoomName}</div>
                                    <div className='client-type'>Client type STD</div>
                                    <div>
                                        <b>Meal Plan :</b>
                                        <span className='meal-plan'>{reservationAccomDataRoom.MealPlan}</span>
                                    </div>
                                   </Fragment> 
                                )
                            })}
                        </Fragment>
                    )
                })}
            </div>
            {
                list.reservationAccom.map((reservationAccomDataDetails,indexOne)=>{
                    return(
                        <div className='date-container'>
                            <span className='date'>{reservationAccomDataDetails.Rooms[0].Dates[0] } <span className='date-arrow'>&rarr;</span> </span>
                            <span className='date'>{reservationAccomDataDetails.Rooms[0].Dates[1]}</span> 
                        </div>
                    )

                })
            }
            {
                list.reservationAccom.map((reservationAccomDataDetails,indexOne)=>{
                    return (
                        reservationAccomDataDetails.Rooms.map((reservationAccomDataRoom,indexTwo)=>{
                            return(
                                <>
                                <div><PaxDetailsTagsV1 paxDetails={ reservationAccomDataRoom}/></div>
                                <div className='total-line-price'>{reservationAccomDataDetails.Currency} { reservationAccomDataDetails.Total.toLocaleString()}</div>   
                            </>
                            )
                        })
                    )

                })
            }
            
        </>
    )
}

export const AccomTableV2 = ({ list,isDelete }) => {
    let {reservationAccom,reservationTransfer}=list
    let allService = [...reservationAccom, ...reservationTransfer];
    let allServiceLength = allService.length

    const {deleteQuotation}=useAddItems()

    const removeItems=(data)=>{
        let payload ={"to_ref":list.ToRef,"service_type":"ACCOM", "hotel_id":data.HotelId}
        deleteQuotation(payload,MORE_OPTIONS_MODE.REMOVE_ITEM)
    }


    return (
        <>
            {list.reservationAccom.map((reservationAccomData, indexOne) => {
                let nbNights = calculateNights(
                    reservationAccomData.Rooms[0].Dates[0],
                    reservationAccomData.Rooms[0].Dates[1]
                );

                let paxTotal = reservationAccomData.Rooms.reduce(
                    (acc, room) => {
                        return {
                            AdultCount: acc.AdultCount + room.AdultCount,
                            ChildCount: acc.ChildCount + room.ChildCount,
                            InfantCount: acc.InfantCount + room.InfantCount,
                            TeenCount: acc.TeenCount + room.TeenCount,
                        };
                    },
                    {
                        AdultCount: 0,
                        ChildCount: 0,
                        InfantCount: 0,
                        TeenCount: 0,
                    }
                );

                return (
                    <Fragment key={"item"+indexOne}>
                        <div className='first-item-category'>
                            <CategoryItem
                                icon={"../../Assets/images/global-icon/accomodation-icon.svg"}
                                title={"Accommodation"}
                            >
                                <div
                                    className='category-hotel-name'
                                    key={'reservationAccomData' + indexOne}
                                >
                                    {reservationAccomData.HotelName}
                                </div>
                            </CategoryItem>
                        </div>

                        <div>
                        <Fragment key={indexOne}>
                            <div className='nb-nights'>
                                {nbNights} {pluralize(nbNights, "night", "nights")}
                            </div>
                            {reservationAccomData.Rooms.map((room, indexTwo) => (
                                <div className='hotel-name' key={indexTwo}>
                                    <span className='hotel-name-room-text'>Room</span>{`${indexTwo+1} : ${room.RoomName}`}
                                </div>
                            ))}
                            <div className='client-type'>Client type STD</div>
                            <div>
                                <b>Meal Plan :</b>
                                {reservationAccomData.Rooms.map((room, indexTwo) => (
                                    <span className='meal-plan' key={indexTwo}>
                                        <span className='hotel-name-room-text'>Room</span>{`${indexTwo+1} : ${room.MealPlan}`}
                                    </span>
                                ))}
                            </div>
                        </Fragment>
                        </div>

                        <div className='date-container'>
                            <span className='date'>
                                {dateToConventionalFormat(reservationAccomData.Rooms[0].Dates[0])}{" "}
                                <span className='date-arrow'>&rarr;</span>{" "}
                            </span>
                            <span className='date'>
                                {dateToConventionalFormat(reservationAccomData.Rooms[0].Dates[1])}
                            </span>
                        </div>
                        <div>
                            <PaxDetailsTagsV1 paxDetails={paxTotal} />
                        </div>
                        <div className='total-line-price' style={isDelete ? { display: "flex", justifyContent: "flex-end" } : {}}>
                            {reservationAccomData.Currency}{" "}
                            {formatPrice(reservationAccomData.Total,',')}
                            {/* {isDelete &&  <div><button  disabled ={allServiceLength === 1} onClick={()=>removeItems(reservationAccomData)}>delete</button></div>} */}
                            {isDelete &&
                                <>
                                    <div className='separator'></div>
                                    <div className='image-trash'>
                                    <img disabled={allServiceLength === 1} onClick={() => removeItems(reservationAccomData)} style={{ width: "25px", height: "25px" }} src={trash} />
                                        {/* <button disabled={allServiceLength === 1} onClick={() => removeItems(reservationTransferData)}>test</button> */}
                                    </div>
                                </>
                            }
                        </div>
                        
                    </Fragment>
                );
            })}
        </>
    );
};

export const TransferTable = ({ list,isDelete }) => {
    let {reservationAccom,reservationTransfer}=list
    let allService = [...reservationAccom, ...reservationTransfer];
    let allServiceLength = allService.length
    
    const {deleteQuotation}=useAddItems()

    const [showRemovePopup, setshowRemovePopup] = useState(false);
    const removeItems=(data)=>{
        console.log(data);
        setshowRemovePopup(true);
        let payload ={"to_ref":list.ToRef,"service_type":"TRANSFER", "service_id":data.ServiceId}
        deleteQuotation(payload,MORE_OPTIONS_MODE.REMOVE_ITEM)
    }
    return (
        <>
            {list.reservationTransfer.map((reservationTransferData, indexOne) => {

                let paxTotal = 
                    {
                        AdultCount: reservationTransferData?.AdultCount ? reservationTransferData?.AdultCount : 0,
                        ChildCount: reservationTransferData?.ChildCount ? reservationTransferData?.ChildCount : 0,
                        InfantCount: reservationTransferData?.InfantCount ? reservationTransferData?.InfantCount : 0,
                        TeenCount: reservationTransferData?.TeenCount ? reservationTransferData?.TeenCount : 0,
                    };

                return (
                    <Fragment key={"item"+indexOne}>
                        <PopupDeleteItem showRemovePopup={showRemovePopup} setshowRemovePopup={setshowRemovePopup} data={reservationTransferData}></PopupDeleteItem>
                        <div className='first-item-category'>
                            <CategoryItem
                                icon={"../../Assets/images/chambre2.png"}
                                title={"Transfer"}
                            >
                                <div
                                    className='category-hotel-name'
                                    key={'reservationAccomData' + indexOne}
                                >
                                   <div className='transfer-location'> {reservationTransferData.LocationFromName}{" "}<span className='date-arrow'>&rarr;</span>{" "}</div>
                                    
                                    <div className='transfer-location' style={{marginTop:'6px'}}>{reservationTransferData.LocationToDetails ? reservationTransferData.LocationToDetails : reservationTransferData?.LocationToName  }</div>
                                </div>
                            </CategoryItem>
                        </div>

                        <div className='second-item-category-transfer'>
                            {reservationTransferData?.Vehicle_type ? reservationTransferData?.Vehicle_type : reservationTransferData?.VehicleTypeName}
                        </div>

                        <div className='date-container'>
                            <span className='date'>
                                {dateToConventionalFormat(reservationTransferData?.TransferDate)}{" "}
                            </span>
                        </div>
                        <div>
                            <PaxDetailsTagsV1 paxDetails={paxTotal} />
                        </div>
                        <div className='total-line-price' style={isDelete ? { display: "flex", justifyContent: "flex-end" } : {}}>
                            {reservationTransferData.Currency}{" "}
                            {formatPrice(reservationTransferData?.TotalPrice,',')}
                            {isDelete && 
                                <>
                                    <div className='separator'></div>
                                    <div className='image-trash'>
                                        <img disabled={allServiceLength === 1} onClick={() => removeItems(reservationTransferData)} style={{width:"25px", height:"25px"}} src={trash}/>
                                        {/* <button disabled={allServiceLength === 1} onClick={() => removeItems(reservationTransferData)}>test</button> */}
                                    </div>
                                </>
                                }
                        </div>
                        {/* <div> */}
                        {/* </div> */}
                        
                    </Fragment>
                );
            })}
        </>
    );
};

export const NightStay =({from, to, flexDirection="row", otherClassName=""})=>{
    return(
        <div className={`nights-parent ${flexDirection==="column"?"flex-column":""} ${otherClassName}`} ><span>{calculateNights(from,to) }</span> <span>nights</span></div>
    )
}

{/* <div className={'patternBlock '+(patId === 1 || patId === 4 || patId === 5 ?"pat1":patId === 2 ?"pat2":patId === 3 ?"pat3":"")} key={ind}> */}

export const FilterTag = ({onClick,text}) =>{
    // console.log("allData",text);

  return (
    <div className='filter_lists' onClick={onClick}>{capitalizeFirstLetter(text)}</div>
  )
}

export const HotelImgPreview = ({nbrLeftImages,imgSrc,imgClassName,imgStyle={},onClick=()=>{}},onLoad) => {

  return (
    <div className='img-hotel-preview' onClick={onClick}>
        {nbrLeftImages>1&&<div className='images-left'>+{nbrLeftImages}</div>}
        <LazyImage src={imgSrc} style={imgStyle} key={"img2"} alt="excursion-image" className={imgClassName} fallBackImg={DefaultMainHotelImage} hasFallback={true}/>
    </div>
  )
}


export const CancellationPolicyPopup =({closepopup,data})=> {
    const [isChecked,setIsChecked]=useState(false);
    const [confirmedStep,setConfirmedStep]=useState(0);
    const { finalBooking,loading,status} = useFinalBooking()
    const {userDateClients,finalBookingData} = useSelector((state) => state.globalreducer)
    const { bookingService,loading:loading2,isSuccess,bookingData} = useBookingService();
    //const {closePopup } = usePopupData();
    const closeRef = useRef()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    
    let {refCode,selRooms}=data
    let LABEL ="I have read and agreed to the terms and conditions"

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {document.body.style.overflow = 'auto'};
    }, [])

    useEffect(() => {    
        if(bookingData !== null){
            alert(bookingData.data.message)
            setTimeout(() => {
                navigate("/accomodation")
              }, 2000); // 2000 milliseconds = 2 seconds
        }
      },[bookingData]);


    const bookingFinal = (e) => {
        e.preventDefault();

        if (loading) return 
        finalBooking(finalBookingData)
    }


    const confirmationSteps=[
        {
            text:"Your booking has been successfully confirmed !",
            className:"confirmed",
            buttonLabel:"Next",
            onNext:()=>{
                setConfirmedStep((prev)=>{return prev+1})
            }
        },
        {
            text:"You will receive a copy of your booking in your mail inbox",
            className:"confirmed-mail",
            buttonLabel:"Open booking grid",
            onNext:()=>{
                navigate("/quotation",{state:{page:BOOKING_PAGE,showLast:true}})
                dispatch(clearAll())
                dispatch(clearTransfer())
                dispatch(clearexcursion())
                dispatch(clearAccom())
            }
        },
    ]

    useOutsideClick(closeRef, () => {
        closepopup()
    });


    return(
        <>
            {
                status === null ? 
                <div className="booking-done-popup" ref={closeRef}> 
                <div className='popup-content-container'>
                    <div className="popup-heading">Confirmation of Terms & Cancellation policy</div>
                    <div className={"popup-content "+(selRooms.length >1 ? "scrollpop":"")}>
                        {
                    
                            selRooms.map((data)=>{
                                return(
                                    <div className="popup-sub-heading" key={data.idAccom}>
                                        {selRooms.length > 1 && <div className='roomnum'>Room {data.idAccom}</div>}
                                        
                                        <div className="accom-label">Accommodation </div>
                                        <div  className="hotel-name"><b>{data.hotelName}</b></div>
                                        <div>
                                            <span className='date'>{data.date[0]}</span>  &rarr; <span className='date'>{data.date[1]}</span>
                                            <span className='nights'>{calculateNights(data.date[0], data.date[1])} nights</span>
                                        </div>
                                        
                                      
                                        {/* need an id */}
                                        <div className='container-policy-room'>
                                            {data.cancellation_policy.map((policy,id)=>(
                                                <div key={policy.CancellationId} style={{color:"#434343",marginTop:"10px"}}>
                                                    <div className='policy-room'>{policy.Rooms}</div>
                                                <div className='policy-period'>Policy period applies: <span>{policy.ValidFrom}</span>   &rarr; <span>{policy.ValidTo}</span> </div>
                                                <div className='policy-price' style={{marginLeft:"10px",marginTop:"10px"}}><b>{policy.Value}% of total price</b></div>
                                                    <div style={{
                                                        marginLeft: "10px", color: "#797979"}}> {policy.Description} </div>
                                                </div>
                                            ))} 
                                        </div>
                                    </div>
                                )
                            })

                        }
                    </div>
                </div>    
                
                <div className="popup-btn-block">
                    <div className='sameInputs_parent'>
                        <label><input type="checkbox" onClick={()=>{setIsChecked(!isChecked)}}/> {LABEL}</label>
                    </div>
                    <button className={"confirm-book-btn "+(isChecked ? "":"disable")} onClick={(e)=>{bookingFinal(e)}} >
                        
                        {loading ? (
                        <CircularProgress
                        color="secondary"
                        style={{ width: "20px", height: "20px", color: "#fff", marginLeft: "8px" }}
                        />
                    ) : "Confirm booking"}
                    </button>
                </div>   
                </div>:
                <div className={`booking-done-popup popup-confirmed-booking ${confirmationSteps[confirmedStep].className}`}>
                     {/* <div onClick={(e)=>{ closePopup(e)}} style={{textAlign: "right",padding: "10px 10px 0",cursor:"pointer"}}><img src={cotationclose} alt="close img"/></div> */}
                    <p>{confirmationSteps[confirmedStep].text}</p> 
                    <p><span>"{getBeforeUnderscore(refCode)}"</span> Booking</p>
                    <button onClick={confirmationSteps[confirmedStep].onNext} className='next-button'>{confirmationSteps[confirmedStep].buttonLabel}</button>
                </div>   
            }
           
                     
          
        </>
    )
}

export const PaxDetailsTags=({paxDetails,customContainer=false})=>{
    const children=(
        <>    
            <div className={paxDetails.adult > 0 ? "paxselected" : "paxunselected"}>
                {`Adult${paxDetails.adult > 1 ? "s" : ""} ${paxDetails.adult}`}
            </div>
            <div className={paxDetails.teen > 0 ? "paxselected" : "paxunselected"}>
                {`Teen${paxDetails.teen > 1 ? "s" : ""} ${paxDetails.teen}`}
            </div>
            <div className={paxDetails.child > 0 ? "paxselected" : "paxunselected"}>
                {`Child${paxDetails.child > 1 ? "ren" : ""} ${paxDetails.child}`}
            </div>
            <div className={paxDetails.infant > 0 ? "paxselected" : "paxunselected"}>
                {`Infant${paxDetails.infant > 1 ? "s" : ""} ${paxDetails.infant}`}
            </div>

        </>);
  return (
    <>
    {
        customContainer?children:
        <div className='pax-details-container'>
            {children}
        </div>
    }
    </>
  )
}
export const PaxDetailsTagsV1=({paxDetails,customContainer=false})=>{
    const children=(
        <>    
            <div className={paxDetails.AdultCount > 0 ? "paxselected" : "paxunselected"}>
                {`Adult${paxDetails.AdultCount > 1 ? "s" : ""} ${paxDetails.AdultCount}`}
            </div>
            <div className={paxDetails.TeenCount > 0 ? "paxselected" : "paxunselected"}>
                {`Teen${paxDetails.TeenCount > 1 ? "s" : ""} ${paxDetails.TeenCount}`}
            </div>
            <div className={paxDetails.ChildCount > 0 ? "paxselected" : "paxunselected"}>
                {`Child${paxDetails.ChildCount > 1 ? "ren" : ""} ${paxDetails.ChildCount}`}
            </div>
            <div className={paxDetails.InfantCount > 0 ? "paxselected" : "paxunselected"}>
                {`Infant${paxDetails.InfantCount > 1 ? "s" : ""} ${paxDetails.InfantCount}`}
            </div>

        </>);
  return (
    <>
    {
        customContainer?children:
        <div className='pax-details-container'>
            {children}
        </div>
    }
    </>
  )
}

export const CategoryItem=({icon,title,children})=>{
    return (
        <div className='category-container'>
            <div><img className='img-icon' src={icon} alt={title+" icon"}/></div>
            <div className='category-details'>
                <div className='category-title'>{title}</div>
                {children}
            </div>
        </div>
    );
}

export const B2BPagination=({currentPage=1,total,nbPerPage,onPageChange,nbPages})=>{
    const [items,setItems]=useState([]);

    const getTotalMaxPages=()=>{
        return Math.ceil(total/nbPerPage);
    }

    useEffect(()=>{
        let tempo = [];
        let beginIndex=1;
        let maxPages=nbPages||getTotalMaxPages();

        if(maxPages < 8){
            for(let i=beginIndex;i<=maxPages;i++){
                tempo.push({libelle:i.toString(),page:i});
            }
        }
        else{
            tempo.push({libelle:'1',page:1});
            if (currentPage>3) {
                tempo.push({libelle:'...',page:beginIndex});
            }
            for (let page= Math.max(2,currentPage-2); page< Math.min(currentPage+3,maxPages); page++) {
                tempo.push({libelle:page.toString(),page:page})
            }
            if (currentPage<maxPages-3) {
                tempo.push({libelle:'...',page:beginIndex});
            }
            if (maxPages>1) {
                tempo.push({libelle:maxPages.toString(),page:maxPages})
            }
            for (let i = beginIndex; i < tempo.length; i++) {
                if (tempo[i].libelle==='...') {
                    tempo[i].page=Math.floor((tempo[i-1].page+tempo[i+1].page)/2)              
                }
                
            }
        }
        setItems(tempo);
    },[currentPage,total,nbPages]);

    const renderButton=(item)=>{
        let buttons=[];
        items.map((item) => {
          if (item.libelle === "...") {
            buttons.push(
              <span
                key={`paginate-three-points-${item.page}`}
                onClick={() => onPageChange(item.page)}
              >
                {item.libelle}
              </span>
            );
          } else {
            buttons.push(
              <button
                key={`paginate-number-${item.page}`}
                onClick={() => onPageChange(item.page)}
                className={currentPage === item.page ? "active" : ""}
              >
                {item.libelle}
              </button>
            );
          }
        });
        return buttons;
    }
    return (
        <>
            <img onClick={()=>currentPage>1?onPageChange(currentPage-1):null}  src={currentPage > 1 ? arrowPagination : arrowNoPagination} className='arrow-prev'/>
            {
                renderButton()
            }
            <img key="arrow-right-pagination" onClick={() => currentPage < getTotalMaxPages() ?onPageChange(currentPage + 1):null} src={currentPage < getTotalMaxPages() ? arrowPagination : arrowNoPagination} className='arrow-next'/>
        </>
    );
}

export const GlobalAmenities=({amenities,isPopup=false,closePopup,isMultiple=false,onSeeMoreClick})=>{
    const containerRef=useRef(null);
    
    isPopup&&useOutsideClick(containerRef,()=>{
        closePopup&&closePopup();
    })

    return (
        <div className='amenities_block' ref={containerRef}>
            <p>Global amenities</p>
            <Amenities multiple={isMultiple} data={amenities} multi={true} onSeeMoreClick={onSeeMoreClick}/>
        </div>
    )
}

export const CancellationInfo =({serviceName,date,location,})=>{
    return(
        <div className='cancellationInfo-parent'>
             <div className={`cancellationInfo-service ${serviceName +"icon"}`}>{serviceName}</div>
             <div className='cancellationInfo-location'>{location}</div> 
             <div style={{display:"flex",gap:"17px"}}>
                <DateAtom date={date}/> 
                {date.length > 1 && <span><NightStay from={date[0]} to={date[1]?date[1]:date[0]} flexDirection="row" otherClassName="removeNightscss"/></span>}
            </div>
        </div>
    )
}

export const QuoteDetailsPolicy =({data})=>{    
    return(
        <div className='quote-det-policy-parent'>
             <div className='quote-det-period'>Policy period applies <b> {formatDate(data.ValidFrom)} - {formatDate(data.ValidTo)}</b></div>
             <div className='quote-det-price'><b>{data.Value} % of total price</b></div> 
            <div>{data.Description}</div> 
        </div>
    )
}
//
export const AtomOrdinalButton = ({typeService}) => {

    const { numberOfRoom: numberOfRoomStatus } = useSelector((state) => state.accomodationreducer);


    // let tempStatusBtn = numberOfRoom; 

    const [statusButton, setstatusButton] = useState(numberOfRoomStatus);
    
    function getClassButton(index){


        // let style = 'alreadysel' refa avy mselect
        // let style = 'selectedOne' //tsaiko
        // let style = 'needtosel' //initial
        // let style = 'disable4' //tsaiko
        // let style = 'disable3' //tsaiko

        // setstatusButton();
        
        return 'selectedOne';
    }
    useEffect(() => {
        setstatusButton(numberOfRoomStatus);
    }, [numberOfRoomStatus])
    
    return (
        <>
            {statusButton.length > 1 && statusButton.map((orderStatus,index) => (
                <div key={orderStatus.number} className={`room-btn ${getClassButton(index)}`}>
                    {index+1}{ getOrdinal(orderStatus.number)} {typeService}
                    {/* //ceci return 1st Room... */}
                </div>
            ))}
        </>
    );
}

export const EmptyRoomTimeline=({roomNo})=>{
    return (
        <div>
            <div className='excursion-map roomno disable'>
                Room {roomNo}
            </div>
            <p className='infoRoom disable'>
                Please select your {getOrdinals(roomNo)} room
            </p>
        </div>
    );
}

export const FilledRoomTimeline=({isMonoRoom,room,roomNo})=>{
    return (
        <>
            {isMonoRoom && <div className='excursion-map roomno'>Room {roomNo}</div>}
            {/* pax details */}
            <div className='excursion-map'><b>Client type </b> | Standard</div>
            {room.AdultCount > 0 && (
                <div className='excursion-map paxIcon'>
                    {room.AdultCount} Adult{room.AdultCount > 1 && 's'}
                </div>
            )}
            {room.TeenCount > 0 && (
                <div className='excursion-map paxIcon'>
                    {room.TeenCount} Teen{room.TeenCount > 1 && 's'}
                </div>
            )}
            {room.ChildCount > 0 && (
                <div className='excursion-map paxIcon'>
                    {room.ChildCount} Child{room.ChildCount > 1 && 'ren'}
                </div>
            )}
            <div className='excursion-map suite'>{room.RoomName}</div>
            <div className='excursion-map meal_plan'><b>Meal Plan</b> | {room.MealPlan}</div>
        </>
    );
}

export const useBackButtonHandler = (condition, onBackButtonPressed) => {
    useEffect(() => {
      // Push initial state to prevent immediate back
      window.history.pushState(null, "", window.location.pathname);
  
      const handlePopState = (event) => {
        // Prevent the default back behavior
        event.preventDefault();
        
        if (!condition) {
          // If condition is false, prevent navigation
          window.history.pushState(null, "", window.location.pathname);
          if (onBackButtonPressed) {
            onBackButtonPressed();
          }
        } else {
          // If condition is true, allow navigation by going back
          window.history.back();
        }
      };
  
      window.addEventListener("popstate", handlePopState);
  
      // Cleanup function
      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    }, [condition, onBackButtonPressed]); // Dependencies array
  };

export const BackButtonHandler = ({ service,serviceName = "Accomodation" }) => {
    const {  isOpen,openPopup, getPopupContent } = usePopupData();

    useEffect(() => {
      let isValidServices = false 
      if(serviceName == "Accomodation"){
        isValidServices = checkAllRoomValid(service);
        
      }else if (serviceName == "Transfer") {
        isValidServices = checkAllTransferValid(service)
      }
      const handlePopState = (event) => {
        event.preventDefault();

        if (isValidServices) {
            window.history.back();
        } else {
            if(serviceName == "Accomodation"){
                openPopup("dialogue-box", "", "/accomodation-select");
            }else if(serviceName == "Transfer"){
                openPopup("dialogue-box", "", "/transfer");
            }
            window.history.pushState(null, "", window.location.pathname);
        }
    };
  
      // Initial push state
      window.history.pushState(null, "", window.location.pathname);
      
      // Add event listener
      window.addEventListener("popstate", handlePopState);
  
      // Cleanup function
      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    }, [service?.roomsFilled]);
  
    return (
        <div>
           {isOpen ? getPopupContent():<></>}
        </div>
    ); 
  };
export const DocketPatternPrice=({currency,price,display})=>{
    return (
        <div style={{ display: display ? 'none' : 'block' }} id='price' className='docket-price'>
            <p>{currency} {separateurMillier(price, ',')}</p>
        </div>
    )
}
export const NoTransferResult=()=>{
    return (
        <div>
            <h1>No available vehicles ...</h1>
        </div>
    )
}
export const NotifSuccessBooking = () => {
    const usersBooking = { id: "1", bookingName: "Adrien Bosquet", status: "rejected" };
        


    return (
        <div className="notif-wrapper">
           
                <div
                    key={usersBooking.id}
                    className={`container-notif ${usersBooking.status === "accepted" ? "success" : "rejected"}`}
                    // style={{
                    //     animationDelay: `${index * 3}s`, // Délai dynamique en fonction de l'index
                    // }}
                >
                    <div className="notif-icon">
                        <img
                            src={usersBooking.status === "accepted" ? successImageNotif : declinedImageNotif}
                            alt={usersBooking.status}
                        style={{ width: "150px", height: usersBooking.status ==="accepted"? "100px":"112px", transform: "scale(0.8)" }}
                        />
                    </div>

                    {/* Partie droite avec le texte */}
                    <div className="notif-content">
                        <p className="statusMessage">
                            {usersBooking.status === "accepted"
                                ? `Your booking request has been successfully accepted!`
                            : `Unfortunately your booking request has been declined !`}
                        </p>

                    <p className='user-booking'><span style={{ color: usersBooking.status === "accepted" ? "#0F7173" : "#F66666"  }}>{`"${usersBooking.bookingName}"`}</span> Booking</p>
                    </div>
                </div>
        
        </div>
    );
};

export const PopupDeleteItem = ({ showRemovePopup, setshowRemovePopup, data }) => {

    const btnRef = useRef(null);
    useOutsideClick(btnRef, () => {
        setshowRemovePopup(false);
    });
    function deleteItem(){
        console.log("daatatatata",data);
    }
    return (
        <>
            {showRemovePopup &&
                <div className='deletepopup_containerBooking'>
                    <div ref={btnRef}>
                        <div className='popup-delete-item'>
                            <p className='title-popup'>You are about to delete this item</p>
                            <p className='title-popup'><span style={{ color:"#C16161"}}>Transfer</span> of your “Adrien Bosquet” booking</p>
                            
                            <div className='item-deleted'>
                                <p><img src={deleteTransfer} className ="img-delete-service" /> <span className="name-service-delete">Transfer</span></p>
                                <div style={{paddingLeft:"31px"}}>
                                    <p className='title-popup ex'>Sir Seewoosagur Ramgoolam<img style={{marginLeft:"6px", width:"17px", height:"17px"}} src={arrow}/></p>
                                    <p className='title-popup ex'>International Airport</p>
                                    <p className='title-popup ex' style={{marginTop:"9px"}}>LUX* Grand-Baie</p>
                                </div>
                            </div>
                            <div className='button-action'>
                                <button className='cancel' onClick={()=>setshowRemovePopup(false)}> Cancel</button>
                                <button className='delete' onClick={() => deleteItem()}> Delete</button>
                            </div>

                        </div>
                    </div>
                </div>
            }
        </>
    )
}



