import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsOffer } from '../../../store/accomodationReducer';
import useFilterItems from '../../../hooks/useFilterItems';
import { scrollToTop } from '../../../utility/globalFunc';

function OfferFilter({ hasContainer = false,scrollRef }) {
    const { isOffer,criteria } = useSelector((state) => state.accomodationreducer);
    const dispatch = useDispatch();
    const {callMyApi}=useFilterItems();

    const handleCheckboxChange = (e) => {
        scrollToTop();
        let offer=e.target.checked;
        
        dispatch(setIsOffer(offer))
        callMyApi(criteria,[],offer);
    }
    return (
        <div key={"offer-filter"} className='types-of-filter'>
            <div className='filter_type_name'>Offers</div>
            <div className="filterbox_loop">
                <label>
                    <input type="checkbox" value="1" checked={isOffer} onChange={handleCheckboxChange} />Show only hotels with offers
                </label>
            </div>
        </div>
    )
}

export default OfferFilter
