import React,{useEffect,useState} from "react";
import MyRoutes from "./MyRoutes";
import { useNavigate,useLocation } from "react-router-dom";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Assets/styles/scss/newstyle.scss';


function App() {

  const [accessToken, setAccessToken] = useState(localStorage.getItem("access-token"));
  const [mtcoTken,setMtcoToken] =useState(localStorage.getItem("mtco-token"))
  const navigate =useNavigate();
  const location = useLocation()
  let isLogged = localStorage.getItem("isLoggedIn")
  let DETAILS_TAB = ["/view-details","/selected-view-details","/internal-error"]
  
// useEffect(() => { 
//   const handleVisibilityChange = () => {  
//     if (document.visibilityState === "visible") {
//       const newAccessToken = localStorage.getItem("access-token");     
//       if (newAccessToken !== accessToken) {
//         setAccessToken(newAccessToken); // Update the accessToken state
//         console.log("window reloaded");
        
//         window.location.reload(); // Reload page if accessToken has changed
//       }
  
//     }
//   };

//   //document.addEventListener("visibilitychange", handleVisibilityChange);
//   !isLogged ? navigate("/login") : isLogged && DETAILS_TAB.includes(location.pathname) ? navigate(location.pathname):navigate("/")
  
//   return () => {
//     document.removeEventListener("visibilitychange", handleVisibilityChange);
//   };
// }, [accessToken,mtcoTken]);  
  



  return (
      <div className="App">
        <div className="mainParent">
          <div className="loading_components"><MyRoutes /></div>
        </div>
      </div>
  );
}

export default App;