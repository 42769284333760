import React from 'react';
import uk from "../../Assets/images/united-kingdom-flag.png";
import fr from "../../Assets/images/france_flag.png";
function Accessibility({list,label,inc,islang,isacces,vehicletype}) {
    let {duration,lang,meal,accessibility,pickup} =list
   
    return (
        <div className='accesibility_parent'>
            {label && label.length > 0 ?<div style={{marginBottom:"32px"}} className='labeltxt'> {label}</div>:null}
            <div className='access_loop'>
                {lang && islang ? <div className='access_loop_box'>
                    <div className='box_backimg box_backimg1'>Languages</div>
                    <div>
                        {lang.length >0 ?
                            (lang.map((data,id)=>
                            <div className='flagloop' key={id} >{
                                data === "French" ? <img src={fr} alt="france flag"/> :<img src={uk} alt="uk flag"/>}
                            </div>)):"No data"
                        }
                    </div>
                </div>:null}
                <div className='access_loop_box'>
                    <div className='box_backimg box_backimg2'>Duration</div>
                    <div>{duration ? duration :"No data"}</div>
                </div>
                <div className='access_loop_box'>
                    <div className='box_backimg box_backimg3'>Meals</div>
                    <div>{meal ?  meal.charAt(0).toUpperCase() + meal.slice(1):"No data"}</div>
                </div>
                {
                    isacces ?
                    <div className='access_loop_box'>
                        <div className='box_backimg box_backimg4'>Accessibility</div>
                        <div>{accessibility ? accessibility.charAt(0).toUpperCase() + accessibility.slice(1).toLowerCase() :"No data"}</div>
                    </div>:<></>
                }
               { 
               vehicletype ?
                    <div className='access_loop_box'>
                        <div className='box_backimg box_backimg5'>vehicle</div>
                        <div>{vehicletype.charAt(0).toUpperCase() + vehicletype.slice(1)}</div>
                    </div>:<></>
                }
                 {pickup && inc ?<div className='access_loop_box'>
                    <div className='box_backimg box_backimg4'>Support</div>
                    <div>{pickup ? pickup :"No data"}</div>
                </div>:null}  
                 
            </div>
        </div>
    );
}

export default Accessibility;