import React, { useState,useEffect } from 'react';
import axios from "axios"
import {axiosRequestParams} from "../../../services/apiClient.js"
import {QUOTELIST_ENDPOINT,BOOKING_ENDPOINT} from "../../../services/apiEndpoints.js"
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUrlParams } from '../../../utility/globalFunc.js';


// Custom hook to fetch data from an API
const useQuoteList = () => {
    const [quoteList, setQuoteList] = useState(null);
    const [bookingList, setBookingList] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);  
    const [nbPages,setNbPages]=useState(1);
    let token = localStorage.getItem("access-token");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const fetchData = async (filter={},setStateCallback) => {
        setLoading(true)
       
        try {
            const response = await axios.request({
                headers: {Authorization: `Bearer ${token}`},
                method: 'GET',
                url: process.env.REACT_APP_API_URL+`${QUOTELIST_ENDPOINT}?${getUrlParams(filter)}`,
            });
            setQuoteList(response.data.result)
            setNbPages(response.data.nb_page||1)
            if (setStateCallback) {
                setStateCallback(response.data.result);
              }
        
        } catch (error) {
            if(error.response && error.response.status === 401){
                navigate("/")
            }else if (error.response) {
                setError(error.response.data.message);
            } else {
                navigate("/internal-error")
            }
        } finally {
            setLoading(false)
        }
    };

    const fetchBookingData = async (Bookfilter={}) => {
        setLoading(true)
        try {
            let payload = getUrlParams(Bookfilter)
            const params = new URLSearchParams(payload);
            const sendobj = Object.fromEntries(params.entries());
           // console.log("sendobj:",sendobj);
            
            const response = await axiosRequestParams("GET",BOOKING_ENDPOINT,sendobj)

          //  console.log(response);
            
            setBookingList(response.data.result)
            setNbPages(response.data.nb_page||1)
            // if (setStateCallback) {
            //     setStateCallback(response.data.result);
            //   }
        
        } catch (error) {
            console.log("error",error);
            
            if(error.response && error.response.status === 401){
                navigate("/")
            }else if (error.response) {
                setError(error.response.data.message);
            } else {
                navigate("/internal-error")
            }
        } finally {
            setLoading(false)
        }
    };

    // const filterToRefDatas =async (sendobj)=>{
    //     console.log("sendobj:",sendobj);
        
    //     try {
    //         const response = await axiosRequestParams("GET",QUOTELIST_ENDPOINT,sendobj)
    //         console.log("response:",response);
            
        
    //     } catch (error) {
    //         if(error.response && error.response.status === 401){
    //             navigate("/")
    //         }else if (error.response) {
    //             setError(error.response.data.message);
    //         } else {
    //             navigate("/internal-error")
    //         }
    //     } finally {
    //         setLoading(false)
    //     }
    // }
   
  return { quoteList,loading,fetchData,fetchBookingData,bookingList,nbPages };
};


export default useQuoteList;