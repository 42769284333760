import react,{useState,useRef} from "react"
import AgencyList from "../MiniComponents/AgencyList";
import useOutsideClick from '../../hooks/useOutsideClick.js';
import useAuthActions from '../../hooks/useAuthActions.js';
import { useSelector } from "react-redux";


const AgencyListPopup = ({closepopup}) => {
    const [selectedItem, setSelectedItem] = useState('');
    const {agencyList,subAgencyAdded}= useSelector((state) => state.authpath)
    const { switchAgency} = useAuthActions();
    const agencyPopup = useRef(null);

    let {agency_name:subAgencyName} = subAgencyAdded
    const handleApiCall = (payload) => {
      switchAgency(payload,closepopup)
    };

    useOutsideClick(agencyPopup, () => {
      closepopup();
  });


  
    return (
      <div style={{ width: '383px'}} ref={agencyPopup}>
        <AgencyList
          items={agencyList}
          onItemSelect={setSelectedItem}
          selectedItem={selectedItem}
          onClearSelection={() => setSelectedItem('')}
          onSubmitCall={handleApiCall}
        />
      </div>
    );
  };

 export default AgencyListPopup 