import React,{useEffect} from 'react';
import {createId} from "../../constants.js"

function Notes({data,label}) {
    
    useEffect(() => {
      let update =   createId(data)
     
    }, [data]);
    const loopNotes = createId(data).map(note =>
        <ul key={note.id}>
            <li>{note.description}</li>
        </ul>
    ) 

    return (
        <div className='notes_parent'>
            <div className='lbltxt'>{label}</div>
            {loopNotes}
        </div>
    );
}

export default Notes;