import React,{useRef,useState,useEffect, useMemo} from 'react';
import Calendar, { DateObject } from "react-multi-date-picker";
import { ShowCalendar,CalendarFooter,CalanderHeader, CalendarButton } from './ExcursionArray';
import useSelectDates from '../../hooks/useSelectDates.js';
import { useSelector,useDispatch } from 'react-redux';
import {clearDates,clearSelDate} from "../../store/excursionReducer.js"
import {clearUserDate} from "../../store/globalReducer.js"
import {clearAccomDates} from "../../store/accomodationReducer.js"
import { isLastDayOfMonth, parse } from 'date-fns';
import { scrollToDependsBoundary } from '../../utility/globalFunc.js';
import { WEEK_DAYS } from '../../constants.js';



function Multicalander({label,label2,month,page,rangeDate}) {
  const datePickerRef = useRef(null)
  const {selDate=[]} = useSelector((state) => state.excursionreducer)
  const { selectDates } = useSelectDates(month, page);
  const [value, setValue]=useState(new Date());
  const rightButtonRef=useRef(null);
  const dispatch = useDispatch()
  const [hasScroll,setHasScroll]=useState(false);

  // Call selectDates function to select dates
  const handleDateSelect = (date) => {selectDates(date)};


  const handlePositionChange=(data)=>{
    if (!hasScroll) { 
      const rect=data.popper;
      rect.top=data.element.top-2;
      rect.bottom=rect.top+rect.width;
      scrollToDependsBoundary(rect)
      setHasScroll(true);
    }
  }
  


  // Call removeSelectedDate function to remove a selected date
  const handleRemoveDate = (type) => {
    type === "clear" && month === 2 && page === 1 ? dispatch(clearAccomDates()) :
    type === "clear" && month === 2 && page === 3 ? dispatch(clearDates()) :
    type === "clear" && month === 2 && page === 5 ? dispatch(clearUserDate()) :
    type === "validate" ? datePickerRef.current.closeCalendar():
    dispatch(clearSelDate());
  };

  const getDayClassName=(date,selectedDate,prop={})=>{
    let d=date.toDate();
    if(selectedDate[0]&&selectedDate[1]){
      let d1=selectedDate[0].toDate();
      let d2=selectedDate[1].toDate();
      let isFirstOfMonth=1===date.day;
      let isLastOfMonth=isLastDayOfMonth(date.toDate());
      if(d1<d&&d<d2){
        let isSunday = 0===date.weekDay.index;
        let isMonday = 1===date.weekDay.index;
        prop.className="in-range-day".concat(isSunday||isLastOfMonth?" range-day-sunday":"").concat(isMonday||isFirstOfMonth?" range-day-monday":"");
      }else{
        prop.className=(prop.className?prop.className:"").concat(isLastOfMonth?" range-day-sunday":"").concat(isFirstOfMonth?" range-day-monday":"")
      }
    }
  }

  const handleDateValue=()=>{
    
    if (rangeDate?.length===0) {
      setValue(new Date());
    }else if(rangeDate.length>=1){
      setValue(parse(rangeDate[0],"dd/MM/yyyy",new Date()));
    }
  };
  
  const setRightRef=(ref)=>{
    rightButtonRef.current=ref.current;
  }

    return (
      <Calendar 
        render={(data, openCalendar) => {

            return (
                // calendar inputs initial display
                <div onClick={(e)=>{openCalendar();}}> 
                    <ShowCalendar label={label} label2={label2}   />
                </div>
            )
        }}
        format="DD/MM/YYYY"  
        ref={datePickerRef}
        value={month === 2 ? rangeDate : selDate}
        onMonthChange={(date)=>{setValue(new Date(date))}}
        onChange={handleDateSelect}
        weekDays={WEEK_DAYS}
        range={month === 2 ? true : false}
        minDate={month === 2 && page !== 5 ? new Date() : month === 1 ? rangeDate[0] : false }
        numberOfMonths={month}
        maxDate={month === 2 ? null : rangeDate[1]}
        disableMonthPicker={true}
        disableYearPicker={true}
        weekStartDayIndex={1}
        renderButton={<CalendarButton value={value} setRightRef={setRightRef}/>}
        headerOrder={["LEFT_BUTTON", "RIGHT_BUTTON"]}
        onClose={()=>{
            handleDateValue();
            setHasScroll(false);
          }
        }
        onOpen={handleDateValue}
        onPositionChange={data => handlePositionChange(data)}
        onFocusedDateChange={(focusedDate,clickedDate)=>{
            // const dateFocused=new Date(focusedDate);
            // const dateClicked=new Date(clickedDate);
            // if(dateFocused&&(rangeDate.length!=1)&&dateFocused.getMonth()!==value.getMonth()){
            //   rightButtonRef.current.click();
            // }else if(dateClicked&&(rangeDate.length!=1)&&dateClicked.getMonth()!==value.getMonth()){
            //   rightButtonRef.current.click();
              
            // }
        }}
        // showOtherDays={true}
        mapDays={({date,selectedDate})=>{
          let prop={};
          if(selectedDate){
            getDayClassName(date,selectedDate,prop);
          }
          const day = date.day;
          const formattedDay = String(day).padStart(2, '0');
          prop.children=formattedDay;
          return prop;
        }}
        // showOtherDays
        plugins={[
            <CalendarFooter position="bottom" handleDates={handleRemoveDate} />,
            <CalanderHeader position="top" label="Arrival" label2="Departure" dummytxt="dd/mm/yyyy" dates={month === 2 ? rangeDate : selDate} removeLabel2={month === 1 ? true : false} />
           
          ]}
      /> 
    
    );
}

export default Multicalander;