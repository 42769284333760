import React,{forwardRef, useImperativeHandle, useRef, useState} from 'react';
import {accomHeading,accomRoom,accomRegion} from "../../constants.js"
import {HeadingNav} from "../MiniComponents/Atom.js"
import HotelRooms from './HotelRooms.js';
import HotelRegion from './minicomponents/HotelRegion.js';
import HotelAmenitiesDetails from './minicomponents/HotelAmenitiesDetails.js';


const HotelsOptions=forwardRef(({hotelRoomProps,hotelRegion,hotelName,roomData,date,hotelId,hotelDescriptionImg,hotelDesc,otherImage},ref)=>{
    const [headingNo,setHeadingNo] =useState(1);
    const [regionData,setRegionData]=useState(null);
    const [amenitiesData,setAmenitiesData]=useState(null);
    const navRef=useRef(null);
    const mainRef=useRef(null);

    useImperativeHandle(ref,()=>({
        navRef:navRef
    }))

    return (
        <div ref={mainRef}>
            <HeadingNav data={accomHeading} updatefn={setHeadingNo} selId={headingNo} ref={navRef}/>
            <div className='options_Data'>
               {
               headingNo === 1 ? 
               <HotelRooms data={roomData} hotelName={hotelName} accomDate={date} hotelId={hotelId} otherImage={otherImage} hotelRoomProps={hotelRoomProps}/>:
               headingNo === 2 ?
               <HotelAmenitiesDetails setAmenitiesData={setAmenitiesData} hotelId={hotelId} hotelDescriptionImg={hotelDescriptionImg} hotelDesc={hotelDesc}  data={amenitiesData}/>
               :
               <HotelRegion hotelRegion={hotelRegion} hotelId={hotelId} regionData={regionData} setRegionData={setRegionData}/>
               
               }

            </div>
        </div>
    );
})

export default HotelsOptions;