import React,{useState,useRef} from 'react';
import useOutsideClick from '../../hooks/useOutsideClick.js';

function MoreOptions({options=[]}) {
    const [showDropdown,setShowDropdown] = useState(false)
    const btnref = useRef(null);

    const openDropdown =(e)=>{
        e.preventDefault();
        setShowDropdown(!showDropdown)
    }
    useOutsideClick(btnref, () => {
        setShowDropdown(false);
    });

    const onClick=(onClickCallback)=>{
        setShowDropdown(false);
        onClickCallback();
    }
    
    return (
        <div  ref={btnref}>
            <div className={`headerQuotation-button ${showDropdown?"clicked":""}`}  onClick={(e)=>openDropdown(e)}>
                More options
            </div>
            {showDropdown && (
                <div className="docket-options">
                    {options.map((option, id) => (
                    <div className="option-item" onClick={()=>onClick(option.onClick)}  key={id}>
                        <span>{option.label}</span>
                    </div>
                    ))}
                </div>
            )}        
        </div>
    );
}

export default MoreOptions;