import React from 'react';

function TrackExcursion({data,label,point,fullWidth,labelSize}) {
    return (
        <div className='track_parent'>
            <div className={'track_parent_txt '+(labelSize === 2?"fntsize":"")}>{label !== ""? label+":" :""}</div>
            <div  className={'track_loop_parent '+(fullWidth ? "loop_updated":"")}>
                {data ?
                    
                    (data.map((item,id)=>{
                        return(
                            <div className='track_loop_div' key={item.id}>
                                <div className={point === 1  ?'track_no':"highlights"}>{point === 1  ? id+1:""}</div>
                                <div>
                                    <div className='track_name'>{point ===1 ? item.name:item.description}</div>
                                    <div className={point ===1 ?'track_location':"hide"}>{item.location_name}</div>
                                </div>
                            </div>   
                        )
                    })):
                    null
                    
                }
            </div>
        </div>
    );
}

export default TrackExcursion;