import { useState } from 'react';
import { axiosRequestBody} from '../../../services/apiClient.js';
import { CREATE_QUOTE_ENDPOINT } from '../../../services/apiEndpoints.js';
import {addRefcode} from "../../../store/transferReducer.js"
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearRoom, resetPendingRooms } from '../../../store/accomodationReducer.js';

// Custom hook to fetch data from an API
const useCreateQuote = () => {


    const [status,setStatus] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);  
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const {transferList,refCode,regionDetails,paxData} =useSelector((state) => state.transfer_Global)
    // const {selExcursion} =useSelector((state) => state.excursionreducer)
    // const {selRooms,roomPax} =useSelector((state) => state.accomodationreducer)

    const createquote = async (payload,setErrorMessage=undefined) => {
        setLoading(true)
        try {
            const response = await axiosRequestBody("POST",CREATE_QUOTE_ENDPOINT,payload);
            setStatus(response.data.status)
            if(response.data.status===201){
                dispatch(clearRoom());
                dispatch(addRefcode(payload.to_ref_code))
                dispatch(resetPendingRooms());
            }
        } catch (error) {
                console.log("accom error response",error);
                if(error.response && error.response.status === 401){
                    navigate("/")
                }
                else if(error.response && error.response.status === 409){
                    let message=error.response.data.message;
                    if (message.includes("reference")&&message.includes("saved")&&setErrorMessage) {
                        setErrorMessage('The client name already exists, please modify the name*');
                    }else{
                        alert(error.response.data.message)
                    }
                }
                else if (error.response) {
                    setErrorMessage('The client name already exists, please modify the name*');
                    // alert(error.response.data.message)
                } else {
                    navigate("/internal-error",{state:{message:error.response?.data?.message||"",status:error.response?.status}})
                }
        } finally {
            setLoading(false)
        }
    };


   
    return { error,loading,status,createquote };
};


export default useCreateQuote;