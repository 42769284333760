import { useDispatch } from 'react-redux';
import {excursionDates,selExcursionDate} from "../store/excursionReducer.js"
import {getUserDate,getUserDateClients,getNumberOfUserDateClients} from "../store/globalReducer.js"
import {accomDates} from "../store/accomodationReducer.js"

const useSelectDates = (month, page) => {
  const dispatch = useDispatch();
  const selectDates = (date) => {
    let dateStrings = page !== 6 && date?.map(day => day.format("DD/MM/YYYY"));
    
    if (Array.isArray(date)) {
      let dateSet=[...new Set(dateStrings)];
      if (dateStrings.length>dateSet.length) {
        dateStrings=dateStrings.slice(0,1);
      }
    }
    
    if (month === 2) {
      switch (page) {
        case 1:
          dispatch(accomDates(dateStrings));
          break;
        case 3:
          dispatch(excursionDates(dateStrings));
          break;
        default:
          dispatch(getUserDate(dateStrings));
      }
    } else {
      dispatch(selExcursionDate(date.toString()));
    }
  }

  const selectDates2 = (date) => {
    const dateStrings = page !== 6 && date?.map(day => day.format("DD/MM/YYYY"));
    return dateStrings
  }
  const selectDates3 = (date) => {
    const dateStrings = page !== 6 && date?.map(day => day.format("DD/MM/YYYY"));
    if(getNumberOfUserDateClients() >2){

      dispatch(getUserDateClients([]));
    }else{

      dispatch(getUserDateClients(dateStrings));
    }
  }
  return { selectDates ,selectDates2,selectDates3};
}

export default useSelectDates;
