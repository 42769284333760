import React,{useState} from 'react';

function SameInputs({label,type,data,handleValues}) {

    const [selectedOption, setSelectedOption] = useState(null);
    const [defaultOption, setdefaultOption] = useState("SHARING");

    const handleOptionChange = (data) => {
        //console.log(data);
        setdefaultOption(data.name);
        handleValues("vehicle_type",data)
    };

    
    let checkBox_list = data && data.map(item =>
        <div key={item.id} className='checkBox_item_list'>
            <input
                type="radio"
                id={item.name}
                value={item.name}
                checked={item.name === defaultOption}
                onChange={() => handleOptionChange(item)}
            />
            <label htmlFor={item.name}>{item.label}</label>
        </div>
    )


    return (
        <div className='input_boxes_parent'>
           { type === "checkbox" ?
                <div className='sameInputs_parent'>
                    <label>
                        <input
                            type={type}
                        />
                        {label}
                    </label>
                </div>
                :
                <div className='checkBox_parent'>
                    {
                       checkBox_list 
                    }
                </div>
           }
        </div>
    );
}

export default SameInputs;