import React from 'react';
import PriceTag from './PriceTag.js';
import Accessibility from '../Excursion/Accessibility.js';
import TrackExcursion from '../Excursion/TrackExcursion.js';


function MapPopup({data,priceDetails}) {
    //console.log("popup data:",priceDetails);

    let{name,route,pois=[],currency}=data
    let{defaultPrice:price}=priceDetails

    return (
        <div className='mappopup_parent'>
            <div className='imageBlock'>
                <PriceTag price={price} cur={currency}/>
            </div>
           
            <div className='mappopup_cntblock'>
                <p>{name}</p>
                 <Accessibility list={data} label="" inc={false} islang={true} isacces={true}/> 
                <div className='trackexcursion_map'>
                     <TrackExcursion 
                        label={pois.length ? "point_of_interests":"Route Excursion"}  
                        point={pois.length ? 2 :route.length ? 1 :"" } 
                        data={pois.length ? pois: route.length ?route :[] } 
                        fullWidth={true}
                    /> 
                </div>
            </div>
            
               
            
            
        </div>
    );
}

export default MapPopup;