import React from 'react'
import {CancellationInfo,GlobalAmenities} from "../../MiniComponents/Atom"
import { ImageLayout } from './Atom'
import HotelRating from "../../MiniComponents/HotelRating"
import{HOTEL_RATING_ACCOM} from "../../../constants"
import transferStatic from "../../../Assets/images/accomodation/staticImages/transferStatic.png"
import {RoomAmenitiesData} from "./RoomAmenitiesData"
import { separateurMillier } from '../../../utility/globalFunc'

const TransferQuoteDetail = ({data,serviceName}) => {

  let pax = data.AdultCount +data.ChildCount +data.TeenCount +data.InfantCount


  return (
    <div className="accom-quote-detail-parent" >
        <div className='accom-detail-block1'>
            <CancellationInfo serviceName={serviceName} date={[data.TransferDate]} location={(data.LocationFromName+ " -> " +data.LocationToName )}/>
            <div className='price'>{data.Currency} {separateurMillier(data.TotalPrice,',')}</div>
        </div>

        <div className='transfer-carBlock'>
           <div><img src={transferStatic} alt="transfer car image" style={{width:"520px", height:"471px"}} /></div>
           <div>
              <p>Transfer Details</p>
              <div className='transfer-details'>
                <div>{pax} Pax</div>
                <div>Client type: Standard </div>
                <div>{data.VehicleTypeName} x{data.CarCount}</div>
                <div>Air conditioned vehicle</div>
                {data.LuggageTruck > 0 && <div>Luggage Truck x {data.LuggageTruck}</div>}
                {data.LuggageCar > 0 && <div>Luggage Car x {data.LuggageCar}</div>}
                {data.baby_seat_count && data.baby_seat_count>0 &&<div>Baby seat x {data.baby_seat_count}</div>}
              </div>
           </div>
        </div>
    </div>
  )
}

export default TransferQuoteDetail