import React,{useState,useRef,useEffect} from 'react';
import StarRatingMenu from './minicomponents/StarRatingMenu.js';
import useOutsideClick from '../../hooks/useOutsideClick.js';
import {updateRating,addCriteria} from "../../store/accomodationReducer.js"
import {useDispatch,useSelector } from 'react-redux';
import { Block } from '@mui/icons-material';

function StarRatingAccom({label1,label2,data}) {
    
    const[openPopup, setOpenPopup] = useState(false);
    const[ratingData, setratingData] = useState(data);
    const[displayStar, setDisplayStar] = useState([]);
    const btnref = useRef(null);
    const listRef = useRef(null);
    const {calendarDate:inputsDate,roomPax,criteria}= useSelector((state) => state.accomodationreducer)
    const dispatch = useDispatch();


    useEffect(() => {
        let checkedItems = ratingData.filter(item => item.checked);
        let checkedRatingsStrings = checkedItems.map(item =>item.name_tag);
        setDisplayStar(checkedRatingsStrings);
    }, [data]);


    const selectStarRating = (e,item) => {
        let {id,rating} =item
        e.preventDefault();
        let selectedRating = ratingData.map(star => {
                    if (star.id === id) {
                        return { ...star, checked: !star.checked };
                    }
                    return star;
        });

        setratingData(selectedRating)
        dispatch(updateRating(selectedRating))

    }

    const validateRating =(e)=>{
        e.preventDefault();
        //dispatch(updateRating(ratingData));
        setOpenPopup(false);

    }

    
    const openRatingPop =(e)=>{
        e.preventDefault();
        setratingData(data)
        setOpenPopup(!openPopup);
    }

    useOutsideClick(btnref, () => {
        setOpenPopup(false);
    });


    useEffect(()=>{
        if (openPopup) {
            const listScroll=listRef.current;
            setTimeout(() => {
                listScroll.scrollIntoView({
                    behavior:'smooth',
                    block:'end'
                })
            }, 100);
        }
    },[openPopup])

    return (
        <div className='starRating-parent' ref={btnref}>
            <div className='inputs-boxes star-rating' onClick={(e)=>{openRatingPop(e)}} >
                <div className='selected-star-name'>{displayStar.length ? <span>You have selected</span>:<b>{label1}</b>}</div>
                <div className={displayStar.length ?'end_hover rating-lab2':''}>{displayStar.length ? displayStar.join(' | '): label2}</div>
            </div>
            {openPopup && 
                <div className='card-starrating' ref={listRef}>
                    <StarRatingMenu data={ratingData} selectStarRating={selectStarRating} needApi={false}/>
                    <button className='rating-valbtn' onClick={(e)=>{validateRating(e)}}>Validate</button>
                </div>
            }
        </div>
        
    );
}

export default StarRatingAccom;