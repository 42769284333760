import React from "react";
import Dropdowns from '../components/Transfer/Dropdowns';
import { DROPDOWN_DATA } from '../constants';
import ExcursionInputs from "../components/Excursion/ExcursionInputs";
import QuoteExcursion from "../components/Excursion/QuoteExcursion";
import NavbarBlock from "../components/Transfer/NavbarBlock.js";

function ExcursionPage() {
   
    return (
        <div>
            <NavbarBlock/>
            <div className="transferPage_parent maubackground ">
                <div className="headText">
                    <div>Discover, feel, live <span>Mauritius.</span></div>
                </div>
                <div className='transferPage_container topAdded70'>
                    <div> 
                        <Dropdowns dropData={DROPDOWN_DATA} imgcount={3} name="Excursion"/> 
                        <div className='excursion_box'>
                            <ExcursionInputs alignbtn={true}/>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
      
    );
}

export default ExcursionPage;