import React, { useRef, useEffect, useState, Fragment } from 'react';  
import { ImageWithFallBack } from './Atom';

const LazyImage = ({ src, alt,className,onClick,hasFallback=false,fallBackImg,style={},onMouseEnter}) => {   
  const [isLoaded,setIsLoaded]=useState(false);
  const imgRef = useRef(null);
  const skeletonRef=useRef(null);

  const handleImageLoad = () => {  
    setIsLoaded(true);
  };

  useEffect(() => { 
    let observer;  
    const { current } = skeletonRef;
    setIsLoaded(false);
    if (current) {  
      observer = new IntersectionObserver(  
        (entries) => {  
          entries.forEach((entry) => { 
            if (entry.isIntersecting) {
              if(imgRef.current){
                imgRef.current.onload=handleImageLoad;
                imgRef.current.src=src;
              }
              if (src&&src.startsWith("/static")) {
                handleImageLoad();
              }  
              observer.unobserve(current);  
            }  
          });  
        },  
        {
            threshold:0.1
        }  
      );  
      observer.observe(current);  
    }  
    return () => {  
      if (observer && current) {  
        observer.unobserve(current);  
      }  
    };  
  }, [src]);  

  return (
    <>
      <div style={{display:isLoaded?"none":"block"}} className={className+" default-skeleton-load"} ref={skeletonRef}></div>
      {hasFallback&&<ImageWithFallBack style={{...style,display:isLoaded?"block":"none"}} ref={imgRef} className={className} alt={alt} loading='lazy' onClick={onClick} fallBackImg={fallBackImg} onMouseEnter={onMouseEnter}/>}
      {!hasFallback&&<img style={{...style,display:isLoaded?"block":"none"}} ref={imgRef} className={className} alt={alt} loading='lazy' onClick={onClick} onMouseEnter={onMouseEnter}/>}
    </>
  );  
};  

export default LazyImage;