import { capitalize } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { PAX_TYPE_ARRAY,capitalizeFirstLetter, getOrdinal } from '../../constants';

function BookingPaxSelector({room,isValid,isChosen,maxPax,appenddata,getRank,clientinfo,serviceIndice,notFilled,headerBookingPaxSelector}) {
    const [matchPax,setMatchPax]=useState(false);
    function getRequiredPax(){
        return clientinfo.reduce((paxType,client)=>{
            let clientType = capitalizeFirstLetter(client.type)+"Count";
            if (!paxType.hasOwnProperty(clientType)) {  
                paxType[clientType] = 1;  
            } else{
                paxType[clientType]+= 1;
            }
            return paxType;
        },{})
    }

    const doesMatchPax=()=>{
        const paxType=getRequiredPax();
        
        for (const [key,value] of Object.entries(paxType)) {
            if (room[key]!=value) {
                return false;
            }
        }
        return true;
    }

    useEffect(()=>{
        let match=doesMatchPax();
        if (match&&!isValid) {
            clientinfo.map((client)=>{
                appenddata(undefined,client,serviceIndice,maxPax);
            })
        }
        setMatchPax(match);
        
    },[])
    
    const getPaxChoiceClassName=(info,serviceIndice,notFilled)=>{
        if (isChosen(info, serviceIndice)) {
            return 'checked';
        }
        if (notFilled) {
            return 'not-filled'
        }
        return "";

    }

    return (
        <div className={`service-box ${notFilled?"not-filled":""}`}>
           {headerBookingPaxSelector}
            <div className='service-instruction'>Please confirm the pax which applies on this service ({maxPax} Pax)<span className='important-asterisk'>*</span></div>
            <div className='service-pax-info-container'>
                <div className={`service-pax-container ${matchPax?"match-pax":""}`}>
                    {
                        clientinfo.map((info, idinfo) => (
                            <div key={info.type+info.id+idinfo} className={`service-box-client ${getPaxChoiceClassName(info, serviceIndice,notFilled)}`} onClick={(e) => { matchPax?undefined:appenddata(e, info, serviceIndice, maxPax) }}>
                                <div className='service-pax-info'>
                                    <div >{capitalize(info.status)} no.{info.numero}</div>
                                    <div className='service-box-client-name'>{info.first_name}</div>
                                </div>
                                <div className={`service-pax-rank ${notFilled?"not-filled":""}`}>
                                    {getRank(info, serviceIndice)}
                                </div>
                            </div>
                        ))
                    }
                </div>
                <i className={`${isValid ? "icon-true-green" : "icon-question-mark-yellow"}`}></i>
                <div className={`toast-error-form ${notFilled ? 'show' : ''}`}>
                    <span>Please select the concerned pax for this service</span>
                </div>
            </div>
            <textarea placeholder='Do you have any remarks for this service?' className='service-box-remarks' />
        </div>
       
    )
}

export default BookingPaxSelector
