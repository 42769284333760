import React from 'react';
import locationImg from "../../../Assets/images/location-pin5.png";
import gray_airplane from "../../../Assets/images/gray-plane.png"
import NoHotel from './NoHotel.js';
import { capitalizeFirstLetters, properCase } from '../../../utility/globalFunc.js';

function HotelList({list=[],value,points,optionValue}) {
    return (
        <div>
             {/* <div className={"scroll "+(value !== "" ? "autoHeight":"")}> */}
             <div className={list.length>3?"scroll ":"choice-hotel-list "+(value !== "" ? "autoHeight":"")}>
            {
              list.length ===0 ? <NoHotel/>
             : (list?.map((data, ind) => {
                {/* if(ind<3){ */}
                return (  
                  <div  key={ind} className="OptionBox"  id={points + "option_" + ind}>
                    <div className="optionBoxDta" onClick={(e)=>{optionValue(e,data)}}>
                      <div>
                        <img src={data.loc_id === 752 ?gray_airplane:locationImg}  alt="locationimg"/>
                      </div>
                      <div className="placeName">
                        <span>{data.Hotel && capitalizeFirstLetters(data.Hotel)}</span>
                        <div className="placeDetails">{properCase(data.Town)}</div>
                      </div>
                    </div>
                  </div>
                )
                {/* } */}
              }))
            }
            </div>
        </div>
    );
}

export default HotelList;