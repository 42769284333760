import React from 'react';
import { useLocation } from 'react-router-dom';


function ErrorPage() {


    const locationData = useLocation();
    let {message,status}=locationData.state;
   
    console.log("message,status",locationData.state);



    return (
        <div className="error-page">
            <div className="error-container">
                <h1 className="error-title">Oops! Something went wrong</h1>
                <p className="error-message">Message: {message}</p> 
                <p className="error-message">Status: {status}</p>
            </div>
        </div>
    );
}

export default ErrorPage;