import { DEFAULT_SERVICES } from "../constants";
import { isServiceAccomodation, isServiceExcursion, isServiceTransfer } from "../utility/globalFunc";
import { useBookingAccomPax, useBookingExcursionPax, useBookingTransferPax } from "./useBookingPax";

const useBookingValidity=({bookingData,services})=>{
    const {getMaxPaxAccom}=useBookingAccomPax({bookingData:bookingData?bookingData:DEFAULT_SERVICES});
    const {getMaxPaxTransfer}=useBookingTransferPax({bookingData:bookingData?bookingData:DEFAULT_SERVICES});
    const {getMaxPaxExcursion}=useBookingExcursionPax({bookingData:bookingData?bookingData:DEFAULT_SERVICES});

    const checkAccomRoom=(room,bookingAccom,accomIndice)=>{
        const requiredNbPax=getMaxPaxAccom(room);
        const clientRefsLength=bookingAccom[accomIndice].clientRefs.length
        return clientRefsLength>=requiredNbPax;
    }

    const checkAccomService=(serviceAccom,bookingAccom,accomIndice,validity)=>{
        let indice=accomIndice
        serviceAccom.Rooms.forEach((room)=>{
            const isFilled=checkAccomRoom(room,bookingAccom,accomIndice);
            if (!isFilled) {
                validity.serviceNotFilled.push("room-"+accomIndice);
                validity.isAllServiceFilled=false;
            }
            indice++;
        })
        return indice;
    }

    const checkTransferService=(serviceTransfer,bookingTransfer,transferIndice,validity)=>{
        const requiredNbPax=getMaxPaxTransfer(serviceTransfer);
        const clientRefsLength=bookingTransfer[transferIndice].clientRefs.length
        const isFilled= clientRefsLength>=requiredNbPax;
        if (!isFilled) {
            validity.serviceNotFilled.push("transfer-"+transferIndice);
            validity.isAllServiceFilled=false;
        }
        return transferIndice+1;
    }

    const checkTransferExcursion=(serviceExcursion,bookingExcursion,excursionIndice,validity)=>{
        const requiredNbPax=getMaxPaxExcursion(serviceExcursion);
        const clientRefsLength=bookingExcursion[excursionIndice].clientRefs.length
        const isFilled= clientRefsLength>=requiredNbPax;
        if (!isFilled) {
            validity.serviceNotFilled.push("excursion-"+excursionIndice);
            validity.isAllServiceFilled=false;
        }
        return excursionIndice+1;
    }

    const checkIfAllPaxSelectorFilled=()=>{
        const {reservationAccom,reservationTransfer,reservationExcursion}=bookingData.services;
        let accomIndice=0,transferIndice=0,excursionIndice=0;
        
        const validity={
            isAllServiceFilled:true,
            serviceNotFilled:[]
        };

        for (let i = 0; i < services.length; i++) {
            const service = services[i];
            if (isServiceAccomodation(service)) {
                accomIndice=checkAccomService(service,reservationAccom,accomIndice,validity)
            }
            if (isServiceTransfer(service)) {
                transferIndice=checkTransferService(service,reservationTransfer,transferIndice,validity);
            }
            if (isServiceExcursion(service)) {
                excursionIndice=checkTransferExcursion(service,reservationExcursion,excursionIndice,validity);
            }   
        }
        return validity;
    }

    return {checkIfAllPaxSelectorFilled};
}

export default useBookingValidity;