import React, { Fragment } from 'react'
import { calculateNights, separateurMillier } from '../../../utility/globalFunc'
import { DocketPatternPrice, EmptyRoomTimeline, FilledRoomTimeline } from '../Atom'

function AccomodationPattern({hotelName,isNew,showRemove,removeItem,dates,rooms,totalPrice,currency}) {
    
    return (
        <div className='sel-excursion-map' style={{ marginTop: "8px", marginBottom: "13px" }}>
            <div className='containerPrice'>
                <div className='module-name accom'>Accommodation {isNew && <i className='iconNew'>New</i>}</div>
                {showRemove && <button id='remove' className='remove-button' onClick={() => removeItem()}><span>Remove</span></button>}
                {totalPrice ? <DocketPatternPrice currency={currency} price={totalPrice} display={showRemove}/> : null}
            </div>
            <div style={{ paddingLeft: "16px" }}>
                <div className='quotation_tour_name excursion-map '>{hotelName}</div>
                <div className='quotation_tour_date excursion-map dates'><span className='quotation_tour_date_first-date'>{dates[0]}</span><span className='quotation_tour_date_arrow'>&rarr;</span><span className='quotation_tour_date_first-date'>{dates[1]}</span></div>
                <div className='excursion-map nights-docket'>{calculateNights(dates[0], dates[1])} nights</div>
                {/* ROOM Pax price*/}
                {
                    rooms.map((room, index) => {
                        return (
                            <Fragment key={"roomdock" + index}>
                                {
                                    room.isEmpty ?
                                        <EmptyRoomTimeline roomNo={index + 1} />
                                        :
                                        <FilledRoomTimeline isMonoRoom={rooms.length > 1} room={room} roomNo={index + 1} />
                                }
                            </Fragment>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default AccomodationPattern