import React,{useRef} from 'react';
import useOutsideClick from '../../hooks/useOutsideClick.js';
import { useEffect } from 'react';
import { useState } from 'react';
import { CategoryItem, PaxDetailsTagsV1 } from './Atom.js';
import arrow from "../../Assets/images/arrow.svg";
import deleteTransfer from "../../Assets/images/popup/deleteTransfer.svg";

function CancelBooking({closePopup,openNew,toPath}) {
    

    const btnref = useRef(null);
    const [step,setStep] = useState(1)
    
    useEffect(() => {
    }, [])


    const notSaveData=(e)=>{
        e.preventDefault();
        closePopup();
    }

    useOutsideClick(btnref, () => {
        closePopup();
    });



    return (
        <div className='dialogue-cancel-booking' ref={btnref}>
            <div className='dialogue-cancel-booking-text'>
                <p >Cancel booking</p> 
                <p>This action is irreversible, and you won't be able to undo it. If you are certain 
                 <br/> you want to cancel your booking, please confirm below. </p>
            </div>
            <div className='dialogue-cancel-booking-details'>
                <div className='dialogue-cancel-booking-details-box1'>
                    <p className='client-name'>Malikah Agowan #20825</p>
                    <p className='booking-cancellation'>Booking cancellation</p>
                    <PaxDetailsTagsV1 paxDetails={{
                            AdultCount: 1,
                            ChildCount: 0,
                            InfantCount: 0,
                            TeenCount: 0
                            }}/>
                </div>
                <div className='dialogue-cancel-booking-details-box2'>
                        <div className='cotDocdetails'>
                                <p>Document details</p>
                                { <div><span className="label" style={{ marginTop: "0px" }}>Booking nb:</span> <span style={{ marginTop: "0px" }} className="value"></span></div>}
                                <div><span className="label">Agency:</span> <span className="value"></span></div>
                                <div><span className="label">Client-Type:</span> <span className="value">Standard</span></div>
                        </div>
                </div>
            </div>
            <div className='dialogue-cancel-booking-service'>
                <div className='item-deleted'>
                            <p><img src={deleteTransfer} className ="img-delete-service" /> <span className="name-service-delete">Transfer</span></p>
                                <div style={{paddingLeft:"10px",marginTop:'13px'}}>
                                    <p className='title-popup ex'>Sir Seewoosagur Ramgoolam<img style={{marginLeft:"6px", width:"17px", height:"17px"}} src={arrow}/></p>
                                    <p className='title-popup ex'>International Airport</p>
                                </div>
                                <div className="quotation-map-child" style={{paddingLeft:"37px",color:"#0F7173",marginTop:"4px"}}>
                                            <span>{'18/07/2023'}</span>
                                </div>
                                    <div className="quationcar_img">Superior Car</div>
                </div>
            <div className='dashed-total'></div>                
            </div>
            <div className='dialogue-cancel-booking-container-button' >
            <button className={""} >Next</button> 
            </div>
    </div>
            
    );
}

export default CancelBooking;

