import React, { useRef } from 'react';
import { DisplayPax } from '../Excursion/ExcursionArray.js';
import { NightStay } from './Atom.js';
import close from "../../Assets/images/close_cotation.png";
import closeIconHover from '../../Assets/images/close_hover.png';
import download from "../../Assets/images/download.png";
import  amenitiesLogo  from "../../Assets/images/Amenities-icon/bathroom.png";

import arrowleft from "../../Assets/images/arr-icon-left.png";
import arrowright from "../../Assets/images/arr-icon-right.png";;
// import arrowlefthover from "../../Assets/images/download.png";
// import arrowrighthover  from "../../Assets/images/Amenities-icon/bathroom.png";

import { useSelector } from 'react-redux';
import { useState } from 'react';
import { FREE_SALES_STATUS, getBackgroundColor } from '../../constants.js';
import { getCategoryIcon  } from '../../utility/globalFunc.js';
import useOutsideClick from '../../hooks/useOutsideClick.js';
import { AccomStockStatus } from '../Accomodation/minicomponents/Atom.js';


function AmenitiesPopup({list,setPopupOpen,totalpax,roomNo}) {
    const [iconSrc, setIconSrc] = useState(close);
    const [showArrow, setShowArrow] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [fade, setFade] = useState(false);
    const imgRef=useRef(null);
    let {name,roomAmenitiesData,price,roomName,images,currency,stockStatus}=list
    
    const {calendarDate} = useSelector((state) => state.accomodationreducer)
    const closePopup =(e)=>{
        e.preventDefault();
        let animDiv = document.getElementById('amenities-popup');
        if (animDiv) {
            animDiv.classList.add('fadeOut');
        //  remove the element after the animation completes
            animDiv.addEventListener('animationend', () => {
                animDiv.style.display = 'none';
            });
        }
        setTimeout(() => {
            setPopupOpen((prevstate)=>({
                ...prevstate,
                seemore:false,
                id:null
            }))
        }, 200);
    }
    const handleCloseChild = (e) => {
        e.stopPropagation(); // Prevents the click from propagating to the parent
     }

    const switchCarousel = (direction) => {
        setCurrentImageIndex((prevIndex) => {
            if (direction === 'next') {
                return (prevIndex + 1) % images.length;
            } else if (direction === 'prev') {
                return (prevIndex - 1 + images.length) % images.length;
            }
            return prevIndex;
        });
        setFade(true);
        setTimeout(() => {
            setFade(false);
        }, 300);
};

    return (
        <div className='amenities-popup-parent'  onClick={(e)=>handleCloseChild(e)} onMouseLeave={()=>setShowArrow(false)}>
            <div className='room-images-carosel' >

                    <img 
                        onMouseEnter={() =>{ setShowArrow(true)}}
                        className={`image-room-popup ${fade ? 'fade-in-300' : ''}`} 
                        src={images[currentImageIndex]}
                        alt="hotel-image" 
                        style={{}}
                        ref={imgRef}
                    />

                    <img src={iconSrc} alt="close icon" className='close-icon' onClick={(e)=>{closePopup(e)}}
                        onMouseEnter={() => setIconSrc(closeIconHover)}
                        onMouseLeave={() => setIconSrc(close)}
                    />
                {/* eto */}
                {showArrow ? 
                    <div className='arrow-container'>
                        <div className='containerLeftArrow' onClick={ ()=>switchCarousel('prev') }>
                            <img className='leftArrow' src={arrowleft} alt="hotel-image"/>
                        </div>
                        <div className='containerRightArrow' onClick={() => switchCarousel('next')}>
                            <img className='rightArrow' src={arrowright} alt="hotel-image"/>
                        </div>
                    </div>
                : null}
                <AccomStockStatus status={list.stockStatus} className='statusRoom'/>
            </div>
            <div onMouseEnter={() =>{ setShowArrow(false)}} className='amenities-popup-content'>
                
                <div className='hotel-name' 
                // line-name={true}
                >
                    <div className='borderLeftStyle'></div>{roomName}</div>
                    <div className='amenities-list'>
                    {Object.entries(roomAmenitiesData).map(([key,value]) => (
                        <div key={"category"+key}>
                            <div className='category-name'>  <img style={{ marginRight: "10px"}}  src={value.TagCategoryIcon} alt="region image" />{value.TagCategoryName}</div>
                            <ul>
                            {value.tag.map((amenity, index) => (
                                <li key={index} className={index === value.tag.length - 1 ? 'last-li' : ''}>{amenity}</li>
                            ))}
                        </ul>
                        </div>
                    ))}
                </div>
                <div className='btn-block'>
                   {stockStatus !== "Unavailable" && <div style={{display:"flex",gap:"0 29px"}}>
                        <DisplayPax  price={price} roomNo={roomNo} totalpax={totalpax} cname={5} currency={currency}/>
                        <NightStay from={calendarDate[0]} to={calendarDate[1]} otherClassName='popup-dimension'/> 
                    </div>}
                    <button>
                        <img src={download} alt='download button'/>
                        <span>Download technical roomsheet</span>
                    </button>
                </div>
            </div>
           
        </div>
    );
}

export default AmenitiesPopup;