import React, { useState,useEffect } from 'react';
import axios from "axios"
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {HOTELLIST_ENDPOINT, LOCPOINT_ENDPOINT} from "../services/apiEndpoints"


// Custom hook to fetch data from an API
const useGetHotels = () => {
    const [hotelData, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);  
    let token = localStorage.getItem("access-token");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await axios.request({
                headers: {Authorization: `Bearer ${token}`},
                method: 'GET',
                url: process.env.REACT_APP_API_URL+LOCPOINT_ENDPOINT,
            });
        

            let removed_airport = response.data.result.filter((data)=>{
                return data.loc_id !== 752
            })
            setData(removed_airport)
          
        
        } catch (error) {
            console.log(error);
            
            if(error.response && error.response.status === 401){
                navigate("/")
            }else if (error.response) {
                setError(error.response?.data.message||"");
            } else {
            alert("An unexpected error occurred");
            }
        } finally {
            setLoading(false)
        }
    };
   
  return { hotelData,loading,fetchData };
};


export default useGetHotels;