import React, { useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import arrow from "../../Assets/images/quotation_arrow.png";
import { B2BPagination, PaxDetailsTags } from "../MiniComponents/Atom";
import { MoreOptionsThreeDotted } from "./MoreOptions";
import useQuoteList from "./hooks/useQuoteList";
import { dateToConventionalFormat, scrollToTop,getBeforeUnderscore } from "../../utility/globalFunc";
import TABLE_SKELETON from '../../Assets/images/skeleton/table-booking-skeleton.png';
import B2BSkeleton from "../Accomodation/minicomponents/B2BSkeleton";
import { BOOKING_PAGE, SORT_DATES,QUOTATION_TYPE,RESERVATION_TYPE,RESERVATION_TYPE2 } from '../../constants';
import {setQuoteBookList} from "../../store/globalReducer"

function TableBooked({ data,highLightLastBooking=false }) {
  
  const [currentPage, setCurrentPage] = useState(1);


  const navigate = useNavigate();
  let token = localStorage.getItem("access-token");
  let [bookfilter,setBookFilter] = useState({reservation_type:RESERVATION_TYPE,sort_type:SORT_DATES[0].value,client_type:null,file_status:null,page_num:1})
  const {fetchBookingData,bookingList,loading,nbPages} = useQuoteList();
  const dispatch = useDispatch()
 
  const changeNoPage=(page)=>{
    setBookFilter(prev=>({
        ...prev,
        page_num:page
    }))
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    changeNoPage(page)
    scrollToTop(0);
  };

  const openViewDetails = (e, data) => {
    e.preventDefault();
    // bookfilter["to_ref"] = data.file_name
    // bookfilter["sort_type"] = null
    setBookFilter(prev=>({
      ...prev,
      to_ref:data.file_name,
      sort_type:null,
      page_num:null,
      reservation_type:RESERVATION_TYPE2
    }))
   
  };

  useEffect(()=>{
    console.log(bookfilter);
    
    fetchBookingData(bookfilter)
  },[bookfilter])

useEffect(() => {
  if(bookingList !== null&&bookfilter.reservation_type===RESERVATION_TYPE2){
    let {reservationAccom,IdBooking,Clients,ToRef,AdultCount,ChildCount,TeenCount,InfantCount} =bookingList
    let tempTransfer = [
      {
          "TransferType": "DEP",
          "LocationFromId": 2,
          "LocationToId": 752,
          "Remarks": null,
          "Flight": null,
          "TransferCategoryId": 1,
          "VehicleTypeId": 3,
          "Currency": "EUR",
          "TotalPrice": "280.00",
          "TransferDate": "2024-12-05",
          "TransferTime": "00:00:00",
          "AdultCount": AdultCount,
          "TeenCount": TeenCount,
          "ChildCount": ChildCount,
          "InfantCount": InfantCount,
          "BabySeat": 0,
          "AirConditionning": true,
          "LuggageTruck": 0,
          "LuggageCar": 0,
          "CarCount": 1,
          "TransferCategoryName": "PRESTIGE",
          "VehicleTypeName": "PRIVATE CAR",
          "LocationFromName": "AMBRE RESORT & SPA",
          "LocationToName": "AIRPORT"
      }
  ]
    let result = {"reservationAccom":reservationAccom,"reservationTransfer":tempTransfer,"refCode":ToRef,"IdBooking":IdBooking,"Clients":Clients}

  
    dispatch(setQuoteBookList(result))
    let file_nb = bookingList.ToRef
    // let {hostname,protocol,port }=window.location;
    // port ?  port = ":"+port : port = ""
    // const serverUrl = `${protocol}//${hostname}${port}/selected-view-details?file_nb=${file_nb}`;
    // window.open(serverUrl, '_blank');
    navigate(`/selected-view-details?file_nb=${file_nb}`)




  }
}, [bookingList])

  const isLastBookingHighlighted=(index)=>{
    return highLightLastBooking && currentPage === 1 && index === 0;
  }




  return (
    <>
      <table className="table-booking-parent">
        <thead className="table-booking-header">
            <tr className="table-booking-row">
                <th>File nb</th>
                <th>Client name</th>
                <th>File Status</th>
                <th className="date-cell">From</th>
                <th> <img src={arrow} alt="arrow" /></th>
                <th className="date-cell">To</th>
                <th>Nb of pax</th>
                <th>Created by</th>
                <th></th>
            </tr>
        </thead>
        {!loading &&  data && data?.length > 0 && (
            <tbody className="table-booking-body">
                {data.map((list, index) => (
                    <React.Fragment key={`${list.booking_id}_${index}`}>
                        <tr
                            key={list.booking_id}
                            className={`table-booking-row row-body ${list.status === "ON REQUEST" ? "yellow" : ""} ${ isLastBookingHighlighted(index) ?'last': ""}`}
                            onClick={(e) => openViewDetails(e, list)}
                        >
                            <td>{list.booking_id}</td>
                            <td className="ref-cell">{getBeforeUnderscore(list.file_name)}</td>
                            <td className="ref-cell">
                              <div className={`statuscell status_booked `}>Booked</div>
                            </td>
                            
                            <td className="date-cell">{dateToConventionalFormat(list.date[0])}</td>
                            <td className="arrow-cell">
                                <img src={arrow} alt="arrow" />
                            </td>
                            <td className="date-cell">{dateToConventionalFormat(list.date[1])}</td>
                            <td className="pax-details">
                                <PaxDetailsTags paxDetails={list.pax_details} customContainer={true} />
                            </td>
                            <td>
                                {list.created_by},<br />
                                <b>{list.agency_name}</b><br />
                                <label style={{ fontWeight: "300" }}>{list.created_date}</label>
                            </td>
                            <td className="booking-status">
                              <div className="quote-btn-container">
                                  <div className="quote-btn status_req2">Proforma</div>
                                  <div className="quote-btn status_req3">Voucher</div>
                              <div onClick={(e) => e.stopPropagation()}>
                                        <MoreOptionsThreeDotted quotation={list}/>
                                {isLastBookingHighlighted(index) && <div className="booking-new-indicator">--</div>}
                              </div>
                              </div>
                            </td>
                        </tr>
                        {index!==data.length-1&&<tr key={"hr-"+index}>
                          <td colSpan={100}><hr/></td>
                        </tr>}
                    </React.Fragment>
                ))}
            </tbody>
        )}
      </table>

      {loading ? (
          <B2BSkeleton imgUrl={TABLE_SKELETON} style={{ marginTop: "25px" }} />
      ) : (
          <div style={{ margin: "30px 0px", fontSize: "22px", width: "100%" }}>
              {!data?.length && "No data found....."}
          </div>
      )}

      {nbPages&&<div className="pagination">
          <B2BPagination
              currentPage={currentPage}
              onPageChange={handlePageChange}
              nbPages={nbPages}
          />
      </div>}

    </>
  );
}

export default TableBooked;
