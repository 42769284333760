import {useState} from 'react'
import QuoteCancellationPolicy from './QuoteCancellationPolicy';
import QuoteDetailsLayout from './QuoteDetailsLayout';
import {HeadingNav} from "../MiniComponents/Atom"


const QuoteCancellationLayout = ({data}) => {
    const NAVBAR_QUOTE =["Cancellation policy","Service details"]  
    const [headingNo,setHeadingNo] =useState(1);
    const STYLE={"marginBottom":"73px","float":"left"}

    return (
     <>
        <div style={STYLE}><HeadingNav data={NAVBAR_QUOTE} updatefn={setHeadingNo} selId={headingNo}/></div>
        { headingNo === 1 ?
        <QuoteCancellationPolicy data={data}/> :<QuoteDetailsLayout data={data} />}
     </>
    )
}

export default QuoteCancellationLayout