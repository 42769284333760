import {useState} from "react"
import SearchAgency from "./SearchAgency";
import { FixedSizeList as List } from "react-window";

const AgencyList = ({ items, onItemSelect, selectedItem, onClearSelection,onSubmitCall }) => {
    const [searchTerm, setSearchTerm] = useState(selectedItem?.AgencyName || '');
  


    const filteredItems = items.filter((item) =>
      item.AgencyName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    const handleItemClick = (item) => {
      let {AgencyId,AgencyName} = item
      setSearchTerm(AgencyName);
      onItemSelect(AgencyName);
      onSubmitCall({"agency_id":AgencyId})
    };
  
    const handleClear = () => {
      setSearchTerm('');
      onClearSelection();
    };
  
    return (
      <div>
        <SearchAgency value={searchTerm} onChange={setSearchTerm} onClear={handleClear} />
        {!selectedItem && (
         <div style={{ padding:"25px",borderRadius: "32px",backgroundColor: "#fff",marginTop:"15px",height:"310px" }}>
            <p style={{fontWeight:"700"}}>Select Agency</p>
            <List
              innerElementType="ul"
              itemCount={filteredItems.length}
              itemSize={45}
              height={250}
              width={343}
              className="list-container"
            >
              {({index, style }) => {
                return (
                  <li style={style} onClick={() => handleItemClick(filteredItems[index])} className="agency-lists">
                    {filteredItems[index].AgencyName} {`(${filteredItems[index].AgencyCountryCode})`}
                  </li>
                );
              }}
            </List>
         </div>
        

        )}
      </div>
    );
  };

  export default AgencyList