import React,{useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import useQuotationService from '../../hooks/useQuotationService.js';

const QuotationUpdatedPopUp = ({data}) =>{
    const [popupopen,setpopupOpen]=useState({succespop:false,popstatus:null,quotation_screen:false})
    const {fetchQuotationDataAndGoToBooking,fetchQuotationData}=useQuotationService();
    const [fadeClass,setFadeClass]=useState("");
    const navigate = useNavigate();

    console.log("console.log.data",data)

    const openGridQuote =(e)=>{
        e.preventDefault();
        navigate("/quotation")
    }

    const openClientPage=(e)=>{
        e.preventDefault();
        fetchQuotationDataAndGoToBooking(data.refCode);
    }

    const handleOpenPopup=(e,isClosed)=>{
        e?.preventDefault();
        if (!isClosed) {   
            setFadeClass("fade-out-200");
            setTimeout(() => {
                setFadeClass("");
                closepopup(isClosed);
            }, 200);
        }else{
            closepopup(isClosed);
        }
    }

    return(
        <div className={`${popupopen.succespop ?"save_input_popup":"save_input_popup"} ${fadeClass}`}>
                <div onClick={(e)=>{ handleOpenPopup(e,false)}} className='close-button'></div>
         <div className={'success_cotpopup fade-in-200'}>
        <div>  
            <div className='success_popchild'>
                <div >Your quotation has been successfully updated !<br/><span>"{data.refCode}"</span><span> quotation</span></div>
                <div className='success_popchild_btns'>
                    <button onClick={(e)=>{openGridQuote(e)}}>View on quotation grid</button>
                    <button onClick={(e)=>{openClientPage(e)}}>Book now</button>
                </div>
            </div>
        </div>      
    </div>  
    </div>
    )

}
export default QuotationUpdatedPopUp;