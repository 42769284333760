import { useMemo, useEffect } from 'react';
import { debounce } from 'lodash';

export function useDebounceLoad(callback, delay) {
  const debouncedFunction = useMemo(() => debounce(callback, delay), [callback, delay]);

  useEffect(() => {
    return () => {
      debouncedFunction.cancel();
    };
  }, [debouncedFunction]);

  return debouncedFunction;
}
