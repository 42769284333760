import React, { useEffect, useRef, useState } from 'react';
import roomSize from "../../Assets/images/global-icon/room-size.svg";
import {convertToFilename} from "../../constants.js"
import usePopupData, { GLOBAL_AMENITIES_POPUP } from '../../hooks/usePopupData.js';

function Amenities({rooms={},multiple,data=[],multipleStyles,multi, viewPopup, list,isRoom = false,onSeeMoreClick=undefined,isIconSvg=false}) {
  const [isOverflowing,setIsOverflowing]=useState(null);
  const multipleListRef=useRef(null);
  const {isOpen,openPopup,closePopup,getPopupContent}=usePopupData();
  

  const handlePopUp = (e) => {
    if (viewPopup && list) {
      viewPopup(e, list, "see-more");
    }
  };

  //let imagePath = process.env.REACT_APP_TAG_ICONS_URL;
  const {availablity,label}=rooms
                                  
  const lists = multiple && data.map((item,ind) => <div className='amenities_list_other' style={{background:`url(${item.AmenityIcon})no-repeat 11px,center`}} key={ind} >{item.AmenityName}</div>)
  
  const multiDatas = data.map((item, ind) => (
   
    
     <div className={isRoom ? 'amenities_list_room':'amenities_list_other'} key={ind}  style={{background:`url(${item.AmenityIcon})no-repeat 11px,center`}}>
      {isIconSvg&&<img key={'img'+ind}  className='logoAmenities' src={roomSize} alt={item.AmenityName||item} />}
      {item.AmenityName||item}
    </div>
    
   
  ));

  const checkAmenitiesOverflow=()=>{
    const element=multipleListRef.current
    if (element) {
      setIsOverflowing(element.scrollHeight>element.clientHeight);
    }
  }

  const onClickSeeMore=()=>{
    onSeeMoreClick&&onSeeMoreClick();
  }
  

  return (
    <div>
      {!multiple && !multi ? <div className={'roomBox ' + (availablity ? "" : "roomRequest")}>{label}</div> : null}
      {multiple ? 
      (
      <>
        <div className="amenities_list resume" ref={multipleListRef}>
        {lists}
      </div>
      <button onClick={onClickSeeMore} className='button-see'>See more amenities</button>
      </>
      ) : null}
      {!multiple && multi ?
        <div className='amenities_list ' >{multiDatas}
          {isRoom && <button className='see-more' onClick={handlePopUp}><span>See more</span> {/* <img className='see-more-img' src={arrowright}/> */}</button>}
        </div>
        : null}
      {isOpen&&getPopupContent({amenities:data,closePopup:closePopup})}
    </div>
    );
}

export default Amenities;