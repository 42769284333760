import React, { useEffect, useState } from "react";
import Logo from "../../Assets/images/mautourco-logo.png";
import united from "../../Assets/images/flag/united.png";
import waveHand from "../../Assets/images/global-icon/waving-hand.svg";
import userImg from"../../Assets/images/svg/avatar.svg"
import axios from "axios";
import { NAVBAR_ITEMS,capitalizeFirstLetter,MAUTOURCO_ID } from "../../constants.js";
import usePopupData from "../../hooks/usePopupData.js";
import {userLogin,userLogout} from "../../store/authentication.js";
import {clearTransfer} from "../../store/transferReducer.js";
import {clearexcursion} from "../../store/excursionReducer.js";
import {clearPax,clearAll} from "../../store/globalReducer.js";
import {clearAccom} from "../../store/accomodationReducer.js";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import useAuthActions from "../../hooks/useAuthActions.js";

function NavbarBlock({logout}) {
  const [loginPopups, setLoginPopups] = useState({logDropdown: false,logSuccess:false});
  const {subAgencyAdded} = useSelector((state) => state.authpath)
  const {user_name,status,agency_country_code,agency_name,agency_id,first_name,last_name} = useSelector((state) => state.authpath.userData)

  const {sortedList} = useSelector((state) => state.globalreducer)
  const {refCode} =useSelector((state) => state.transfer_Global)  
  const {accomList,roomPax,pendingRooms} = useSelector((state) => state.accomodationreducer)
  const { listAgency} = useAuthActions();
  const [reducedNav,setReducedNav] = useState(false)

  const { isOpen, openPopup, getPopupContent } = usePopupData();

  const dispatch = useDispatch()
  const navigate = useNavigate();
  let senderid = Number(localStorage.getItem("user_id"))
  let token = localStorage.getItem("access-token")
  let mtco_token = localStorage.getItem("mtco-token")

  const [activeItem, setActiveItem] = useState('');
  const issubAgencyAdded = Object.values(subAgencyAdded).length > 0
  const keysToRemove = ["access-token", "mtco-token", "refresh-token","persist:mautourco","isLoggedIn"];

  useEffect(() => {
    const path = window.location.pathname;
    const currentItem = NAVBAR_ITEMS.find(item => item.navPage === path);
    //need to be refactor
    if (path === "/transfer") setActiveItem("Home");
    if (currentItem) {
      setActiveItem(currentItem.name);
    }
    checkQuotaionView(path)
  }, []); 
  
  const logPopOpen = (e) => {
    e.preventDefault();
    setLoginPopups((prevstate) => ({
      ...prevstate,
      logDropdown: !loginPopups.logDropdown,
    }));
  };

  const messageChat =(e,status,name)=>{
    e.preventDefault();
    userInfo(status,name)
    axios.request({
              headers: {Authorization: `Bearer ${token}`},
              params: {user_id: senderid},
              method: "GET",
              url: `/user_messages`
            })
            .then(res => {
              console.log("msg bth hit:",res.data);
              chatinfo(res.data,"chatdata")
            })
            .catch((err) => console.log("get",err.response.data.message));
  }
 
  const handleLogout =(e)=>{
    e.preventDefault()
    dispatch(clearTransfer())
    dispatch(clearPax())
    dispatch(clearAccom())
    dispatch(clearAll())
    window.location.reload();
    let sendObject={user_id:senderid,user_name:loginData.username}
    let token = localStorage.getItem("access-token")
    console.log("logout token",token);
    setLoginPopups((prevstate) => ({
      ...prevstate,
     logDropdown: false,
    }));
    // axios.request({
    //   headers: {
    //     Authorization: `Bearer ${token}`
    //   },
    //   method: "POST",
    //   url: process.env.REACT_APP_API_URL+`/user/logout`,
    //   data: sendObject,
    // })
    // .then(res => {
    //    localStorage.setItem("access-token", res.headers.access_token);
    //    console.log("logout success",res);
    //    dispatch(userLogout())
    //    setLoginPopups((prevstate) => ({
    //     ...prevstate,
    //     logDropdown: false,
    //   }));
   
    // })
    // .catch((err) => {
    //   console.log("logout error message:",err)
    //   setLoginPopups((prevstate) => ({
    //     ...prevstate,
    //     logDropdown: false,
    //   }));
    
   
    // });
  }

  const pageRedirect =(e,list)=>{
    e.preventDefault();
    let {name,navPage} = list;
    const pathname = window.location.pathname;
    console.log(name,navPage,pathname);
    const isSortedListLength = Object.values(sortedList.services || sortedList).some(array => array.length > 0);
    if (status === 200) {
      const hasAccomList = accomList?.length > 0;
      const hasSortedList = isSortedListLength;
      const hasRefCode = refCode !== null;
  
      console.log("hasSortedList",hasAccomList,hasSortedList,hasRefCode);
      
        let {requiredNbRooms} = pendingRooms


      // Navigate based on combinations of lists and reference code
      if (
          (!hasAccomList && !hasSortedList && !hasRefCode) || // Case 1
          (!hasAccomList && hasSortedList && hasRefCode) || // Case 2
          (!hasAccomList && hasSortedList && !hasRefCode) || // Case 3
          (hasAccomList && !hasSortedList && !hasRefCode && !requiredNbRooms) || // Case 4
          (hasAccomList && !hasSortedList && hasRefCode) || // Case 5
          (hasAccomList && hasSortedList && hasRefCode) // Case 6
        
      ) {
          setActiveItem(name);
          navigate(navPage);
          if(name){
            dispatch(clearAll())
            dispatch(clearTransfer())
            dispatch(clearexcursion())
            //dispatch(userLogout())
            dispatch(clearAccom())
          }
          
      } 
      
      else {
          openPopup("dialogue-box", "", navPage);

      }
    }

   
  }

  const checkQuotaionView = (path) =>{
    if(path.includes("view-details")){
      setReducedNav(true)
    }
  }

  const clearall =()=>{
    dispatch(clearAll())
    dispatch(clearTransfer())
    dispatch(clearexcursion())
    dispatch(userLogout())
    dispatch(clearAccom())
    navigate("/login",{ replace: true })
    keysToRemove.forEach((key) => localStorage.removeItem(key));
    
  }


  const showAgencyList =(e)=>{
    e.preventDefault();

    if(agency_id ===MAUTOURCO_ID){
      listAgency()
      openPopup("agency-list")
    }

  }

const homeNavigate =(e)=>{
  e.preventDefault()
  navigate("/")
  setActiveItem("Home")
}

  return (
    <>
      {!reducedNav &&
          <div className="navbar-head">
          <div className="navbar-body-container">
            <div className="navbody navbody1" onClick={(e)=>{homeNavigate(e)}}>
              <img src={Logo} alt="Logo" />
            </div>
            <div className="navbody navbody2">
              
            {<ul className={agency_id === MAUTOURCO_ID && !issubAgencyAdded ? "disable":""}>
                {
                  NAVBAR_ITEMS.map(list => <li key={list.id} onClick={(e) => { pageRedirect(e, list) }} className={activeItem === list.name ? "border-bottom":""} >{list.name}</li>)
                }
            </ul>}
                
            </div>
            <div className="navbody navbody3">
              <div >
                <span className="welcome">Welcome </span>
                  { 
                    status > 200 ? errmessage
                      : status === 200 && token !== undefined ? 
                      
                      
                        <span style={{ fontWeight: "bold" }}>
                          <>{capitalizeFirstLetter(first_name)} {last_name}</>
                          <img className="wave-hand" src={waveHand} alt="Logo" />
                       
                          <div className="big-text-ellipsis">{agency_id === MAUTOURCO_ID && issubAgencyAdded ? `${subAgencyAdded.agency_name} (${subAgencyAdded.agency_country_code})`  :agency_name+` (${agency_country_code})` }</div>
                        </span>
                    
                      
                    :"Login"
                  }
              </div>
              
              <div onClick={(e) =>showAgencyList(e)}>
            
                <img src={status === 200 && (token?.length || mtco_token?.length) && logout === false ? userImg:userImg} className="profil-user" alt="user logi img"/>
              
              </div>
              
              <div className="flagLangage-container">
                <img className="flagLangage" src={united} alt="Logo" />
              </div>
              <button onClick={(e)=>{clearall()}}>Logout</button>
            </div>
          </div>
          <div className="login-popup-container">
            {
                loginPopups.logDropdown ? 
                
                <div className="logoutPop">
                  <button onClick={(e)=>{handleLogout(e)}}>Logout</button>
                </div>:null
            }
      
          </div> 
        </div>
      }
      {reducedNav && 
        <div className="navbar-head">
        <div className="navbar-body-container">
          <div className="navbody navbody1">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="navbody navbody2">
              
          </div>
          <div className="navbody navbody3 reduced">
            <div >
              <span className="welcome">Welcome back </span>
                { 
                status > 200 ? errmessage
                  : status === 200 && token !== undefined ? <span style={{ fontWeight: "bold" }}>{capitalizeFirstLetter(user_name)} <img className="wave-hand" src={waveHand} alt="Logo" /> <br />{agency_name}</span>
                :"Login"}
            </div>
            
            <div>
              <img src={status === 200 && token.length && logout === false ? userImg:userImg} className="profil-user" alt="user logi img"/>
            </div>
            
            <div className="flagLangage-container">
              <img className="flagLangage" src={united} alt="Logo" />
            </div>
            <button style={{visibility:'hidden'}} onClick={(e)=>{clearall()}}>Logout</button>
          </div>
        </div>
      </div>
      
      }
      {isOpen ? getPopupContent():<></>}
    </>

    
  );
}

export default NavbarBlock;