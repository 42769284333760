import React from 'react'
import { getOrdinal } from '../../../constants'
import { DocketPatternPrice } from '../Atom'
import { properCase } from '../../../utility/globalFunc'

function TransferPattern({isNew,transferNo,date,locationFrom,locationTo,vehicleType,removeItem,capacity,carSelected,adultCount,teenCount,childCount,infantCount,totalpax,showRemove,supplements,price,currency,carCount=1}) {
    
    return (
        <div className='transfer-pattern-container'>
            <div className="trans_date">
                <div className="module-name transfer">
                    <b>{transferNo}{getOrdinal(transferNo)} Transfer {isNew && <i className='iconNew'>New</i>}</b>
                </div>
                {showRemove && <button id='remove' className='remove-button' onClick={() => removeItem()}><span>Remove</span></button>}
                {carSelected&&<DocketPatternPrice price={price} currency={currency} display={showRemove}/>}
            </div>
            <div className='transfer-pattern-content'>
                <div className="transfer-date">
                    <span>{date}</span>
                </div>
                <div className="trans_points">
                    <div className="quotation-map-child">
                        <span>{locationFrom}</span>
                    </div>
                    <div className="quotation-map-child"></div>
                    <div className="quotation-map-child">
                        <span>{locationTo}</span>
                    </div>
                </div>
                {carSelected?
                <div className="trans_per_car">
                        <div className="adult_block_span" style={adultCount > 0 ? { marginBottom: "5px", height: "18px" } : {}}>
                            {adultCount > 0 ? (
                                <div className="quationAdult_img">
                                    {adultCount + " " + (adultCount > 1 ? "Adults" : "Adult")}
                                </div>
                            ) : ("")}
                        </div>

                        <div className="adult_block_span" style={childCount > 0 ? { marginBottom: "5px", height: "18px" } : {}}>
                            {childCount > 0 ? (
                                <div className="quationAdult_img">
                                    {childCount + " " + (childCount > 1 ? "Children" : "Child")}
                                </div>
                            ) : ("")}
                        </div>

                        <div className="adult_block_span" style={teenCount > 0 ? { marginBottom: "5px", height: "18px" } : {}}>
                            {teenCount > 0 ? (
                                <div className="quationAdult_img">
                                    {teenCount + " " + (teenCount > 1 ? "Teens" : "Teen")}
                                </div>
                            ) : ("")}
                        </div>

                        <div className="adult_block_span" style={infantCount > 0 ? { marginBottom: "5px", height: "18px" } : {}}>
                            {infantCount > 0 ? (
                                <div className="quationAdult_img">
                                    {infantCount + " " + (infantCount > 1 ? "Infants" : "Infant")}
                                </div>
                            ) : ("")}
                        </div>
                    <div className="adult_block_span" style={carSelected ? { marginBottom: "5px", height: "18px", } : {}}>
                        <div className="quationcar_img">
                            {properCase(vehicleType)} {capacity && carCount > 1 ? " x" + carCount : ""}
                        </div>
                    </div>
                    {supplements.length>0&&<div className='trans-supplements-container'>
                        <p className='supplements-title'>Supplement:</p>
                        {
                            supplements.map((supp,ind)=>(        
                                <div key={"supp"+ind} className='supplements-details'>
                                    <i className={supp.icon}></i>
                                    <span>{supp.label}:{supp.count}</span>
                                </div>
                            ))
                        }
                    </div>}
                </div>:
                <div className='trans-message-error'>
                    {/* {!carSelected ? "Please select the vehicle of the transfers" : "Please select the vehicle of the transfer"} */}
                        Please select the {transferNo}{getOrdinal(transferNo)} vehicle of the transfer
                </div>}
                <div>

                </div>
            </div>
        </div>
    )
}

export default TransferPattern