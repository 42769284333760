import React,{useState,useEffect} from 'react';
import useFilterItems from '../../../hooks/useFilterItems.js';
import {useSelector } from 'react-redux';

function RegionList({data=[],onlySelectThree = false,type,scrollRef}) {
    const [selectedRegion, setSelectedRegion] = useState([]);
    const {criteria}= useSelector((state) => state.accomodationreducer)
    const {handleCheckboxChange } = useFilterItems();

    useEffect(() => {
       let chk = criteria.map((item)=>Object.values(item)[1])
       setSelectedRegion(chk)
    }, []);

    const handleCheck = (e,item)=>{
        e.preventDefault();
        let regionName=item.name_tag;
       
        let chkAvailable = selectedRegion.includes(regionName)
        let demo = {target:{checked:!chkAvailable,value:item.id}}
        setSelectedRegion((prevState)=>{
           if (prevState.includes(regionName)) {
                return prevState.filter((name)=>name !== regionName);
           }else{
                prevState.push(regionName);
           }
           return prevState;
        })

        //..console.log(demo,regionName);
        
        handleCheckboxChange(demo,item,type,scrollRef)
    }


    const handleCheckThreeElments = (e,item)=>{
        e.preventDefault();
        let regionName=item.name_tag;
        let chkAvailable = selectedRegion.includes(regionName)
        
        if (chkAvailable || selectedRegion.length < 3) {
            let demo = {target:{checked:!chkAvailable,value:item.id}}
            setSelectedRegion((prevState)=>{
            if (prevState.includes(regionName)) {
                    return prevState.filter((name)=>name!==regionName);
            }else{
                    prevState.push(regionName);
            }
            return prevState;
            })

            handleCheckboxChange(demo,item,type,scrollRef)
        }
    }

    useEffect(()=>{
        if (criteria.length===0) {
            setSelectedRegion([]);
        }
        if (criteria.length>0) {
            let chk = criteria.map((item)=>Object.values(item)[1])
            setSelectedRegion(chk)
        }
    },[criteria])


    // console.log("selectedRegion",selectedRegion);
    // console.log("criteria",criteria);


    return (
        <div className='regionList-parent'>
            {data?.tags?.map((list) => (
            <div
                className={'region-names ' + (selectedRegion.includes(list.name_tag) ? "selected" : "")}
                key={list.id_tag}
                onClick={(e) => { onlySelectThree ? handleCheckThreeElments(e, list) : handleCheck(e, list); }}
            >
                {list.name_tag}
            </div>
            )) || <p>No data available</p>}
      </div>
      
          
    );
}

export default RegionList;