import React from 'react'
import amenitiesStatic from "../../../Assets/images/accomodation/staticImages/amenitiesStatic.png"

export const RoomAmenitiesData = ({roomData}) => {

    console.log("room data",roomData);
    
  return (
    <div className='quote-items-display'>
        <div className='quote-items-display-hotels'>
            <p className='quote-items-display-hotels-names'>{roomData.RoomName}</p>
            <div className='amenities_list'>
                <div className='amenities_list_hotel person-icon'>
                    {roomData?.AdultCount} Adult{roomData?.AdultCount !== 1 ? 's' : ''}
                </div>
                <div className='amenities_list_hotel'>Client type | {roomData?.ClientCategory} </div>
                <div className='amenities_list_hotel meal-plan-icon'>{roomData?.MealPlan}</div>
            </div>
        { <img src={roomData.RoomImage? roomData.RoomImage:amenitiesStatic } className='quote-items-display-hotels-img' alt="room image" style={{width:"100%",Height:"471px"}}/>}
        </div>
        <div className='quote-items-display-amenities'>
            <RoomAmenitiesList list = {roomData.RoomAmenities}/>
        </div>
    </div>
  )
}

export const RoomAmenitiesList = ({list={}}) => {
console.log("list",list);

    return (
         
        <div className='amenities-list'>
            {Object.keys(list).map((amenity) => (

                
                <div key={"category"+amenity} >
                    <div className='category-name' key={amenity}><img style={{ marginRight: "10px"}}  src={list[amenity]?.TagCategoryIcon} alt="region image" />{amenity}</div>     
                    <ul >
                        {list[amenity].tag.map((item, index) => (
                        <li key={index}>{item}</li>
                        ))}
                    </ul>
                    
                </div>
            ))}
        </div>
          
    )
  }

