import React,{useRef,useState,useEffect} from 'react';
import { PaxAge } from '../MiniComponents/Atom.js';
import { Client_dropdown } from '../../components/Quotations/small_dropdowns';
import {CLIENTDATA,capitalizeFirstLetter,ROOMPAXDATA,PAX_VALIDATION_PARMS} from "../../constants"
import {hasAgeZero, scrollToDependsBoundary,checkRoomPaxZero} from "../../utility/globalFunc.js"

function PaxDropdown({optionList,setOptionData,cname,confirmPax,hideAge=false}) {
    const mainContainerRef = useRef(null);
   const scrollRef = useRef(null);
   const [errorPax, setErrorPax] = useState({roomValue:true,minAdult:true}); 
   const [isDisabled, setIsDisabled] = useState(false);
   const PAXkEYS = ["adult", "teen", "child", "infant"];
   const [animateClass,setAnimateClass]=useState("");
  let {ADDROOMPAX}=PAX_VALIDATION_PARMS

  
   const animateFading=(fadingClassName="fade-in")=>{
    setAnimateClass(`show ${fadingClassName}`);
    setTimeout(()=>{
        setAnimateClass("");
    },2000);
   }
   const clearData=(e)=>{
        confirmPax(e,"clear");
        setIsDisabled(false);
        animateFading();
        scrollToDependsBoundary(mainContainerRef.current,"end")
        setErrorPax({roomValue:true,minAdult:true})
   }


   const handleIncrement = (e,list,name) => {
        e.preventDefault();
      //  console.log("optionList: ",optionList,list);

        let {room_id} =list
        setOptionData((prevData) =>
            prevData.map((room) =>
            room.room_id === room_id ? 
            {
                ...room,
                [name]: room[name] + 1,
                paxAge: name !== "adult" ? [...room.paxAge, { [name]: 0, id: room[name] + 1 }] : [...room.paxAge]

            }: room
        ))
        scrollToFocusElement(e,undefined,mainContainerRef.current);
    };

    const handleDecrement = (e,list,name) => {
        e.preventDefault();
        let {room_id} =list
        //  if (list[name] === 0 || (name === 'adult' && list[name] === 1)) {
        if (list[name] === 0) {
            return; 
        }
        else{
            setOptionData((prevData) =>
                prevData.map((room) =>
                room.room_id === room_id ? 
                    {
                        ...room,
                        [name]: room[name] - 1,
                        paxAge: room.paxAge.filter(pax => !(Object.keys(pax)[0] === name && pax.id === list[name]))
                    }:room
                ))
        }
        scrollToFocusElement(e,undefined,mainContainerRef.current);
    };

    const scrollToFocusElement=(e,scrollChildren,scrollMainParent,animate=false)=>{
        e?.preventDefault();        
        e?.stopPropagation();
        // animate&&animateFading("fading-pax-height");
        scrollChildren&&setTimeout(() => {
            scrollChildren?.scrollTo({
                top: scrollChildren.scrollHeight,
                behavior: animate?"smooth":"smooth",
            });        
        }, 10);
        
        scrollMainParent&&setTimeout(() => {
            scrollToDependsBoundary(scrollMainParent,"end");
        }, scrollChildren?150:10);
    }

    const addNewRoom =(e,name,roomId)=>{
       e.preventDefault();
       console.log(name);
       let {hasAnyZeroes, minOneAdult} =checkRoomPaxZero(optionList,ADDROOMPAX)
       if(name === "add" && !hasAnyZeroes){
            const rooms=[...optionList,{...ROOMPAXDATA,room_id: optionList[optionList.length-1].room_id + 1,adult:0}]
            
            setOptionData(rooms)
            const scrollParent = scrollRef.current;
            const scrollMainParent = mainContainerRef.current;
            if(rooms.length>=2){
                if (rooms.length===2) {
                    scrollToFocusElement(e,scrollParent,scrollMainParent,true)
                }else{
                    scrollToFocusElement(e,scrollParent,scrollMainParent)
                }
            }

       }

       else if(name === "add" && hasAnyZeroes){
        setErrorPax(prevState => ({
            ...prevState,
            roomValue: !hasAnyZeroes
        }));
       }
       
       else{
        const updatedAgeData = optionList.filter(item => item.room_id !== roomId);
        setOptionData(updatedAgeData);
        setErrorPax({roomValue:true,minAdult:true})
       }
       
       
    }

    const confirmButton = (e,name)=>{
        e.preventDefault();
        let {hasAnyZeroes, minOneAdult} =checkRoomPaxZero(optionList,ADDROOMPAX)

        if(!hideAge){  
            let disabled=hasAgeZero(optionList);
            setIsDisabled(disabled)
            if(!disabled && !hasAnyZeroes && minOneAdult){
               confirmPax(e,name) 
                // additionalActionOnDone&&additionalActionOnDone();
            }
            else{
                setErrorPax({
                    roomValue: !hasAnyZeroes,
                    minAdult: minOneAdult
                });
            }
        }
   
        
        else{
           confirmPax(e,name);
        }
        scrollToFocusElement(e,undefined,mainContainerRef.current);
    }

    const handleAgeValue=(age,ageList,roomId)=>{
        setOptionData(prevAgeData => prevAgeData.map(room => {
            if (room.room_id !== roomId) return room;
            const updatedPaxAge = room.paxAge.map(pax =>{
                    if(pax[Object.keys(ageList)[0]] !== undefined && pax.id === ageList.id) {
                        return { 
                            ...pax,
                            ...{ [Object.keys(ageList)[0]]: age } 
                        };
                    }
                    return pax;
                }
            );
            return { ...room, paxAge: updatedPaxAge };
        }));
    }

    useEffect(()=>{
        const scrollMainParent = mainContainerRef.current;
        scrollToFocusElement(undefined,undefined,scrollMainParent);
    },[])


    return (
        <div id='box' className={"optionBox-pax" + (optionList.length > 1 ? " roomAdded":"")+(` ${animateClass}`)}  ref={mainContainerRef}>
            <div className={optionList.length > 1 ? "optionBox-scroll":"" }  ref={scrollRef}>
                {
                    optionList.map((paxData,paxInd)=>{
                        return (
                            <div  key={"room_pax_"+paxData.room_id} className={optionList.length > 1 ?"room-list ":""}>
                            {optionList.length > 1 ? <div className="room-no"><div>Room {paxInd+1}</div><button onClick={(e)=>{addNewRoom(e,"remove",paxData.room_id)}}>Remove</button></div>:<></>}
                            {
                                PAXkEYS.map((data,ind)=>(
                                    <div  key={ind} className='paxbox-row'>
                                        <div className="label">{capitalizeFirstLetter(data)}</div>
                                        <div className='OptionValueBox-pax'>
                                            <div className="pax_decrement" onClick={(e) => { handleDecrement(e,paxData,data)}}></div>
                                            <div className="paxValueBox">{paxData[data]}</div>

                                            {/* <input type="text" className="paxValueBox" value={paxData[data]}/> */}
                                            <div className="pax_increment" onClick={(e) => {handleIncrement(e,paxData,data)}}></div>
                                        </div>
                                    </div> 
                                ))
                            }
                            {!hideAge && 
                                <div className='age-section-container'>
                                {paxData?.paxAge.length > 0 && <p>Please specify the ages of pax on arrival:</p>}
                                {    
                                    paxData?.paxAge.length > 0 && 
                                    <div className='age-container'>
                                    {paxData?.paxAge.map((ageList,ind)=>(<PaxAge key={ind} ageInd ={ind} data={ageList} onAgeChange={(age)=>handleAgeValue(age,ageList,paxData.room_id)} roomId={paxData.room_id} optionList={optionList} isDisabled={isDisabled}/>))}
                                    </div>
                                }
                               
                            </div>
                            }

                            <div>
                               {cname === 1 && <Client_dropdown data={CLIENTDATA} label={paxData.clientType} phase={1} setOptionData={setOptionData} optionList={optionList} roomId={paxData.room_id}/>}
                            </div>
                        </div>    
                    )})
                }
            </div>
            
            <div className={"pax-button" + (optionList.length&&optionList.length>0?" pax-age-added":"")}> 
                <div className='errMsg'>{isDisabled && "You need to specify age to continue your search"}</div>
                <div className='errMsg'> {!errorPax.roomValue && "Please fill the room *"}</div>
                <div className='errMsg'> {!errorPax.minAdult && "Minimum one adult is required *"}</div>
               
                { cname === 1 ?<><div onClick={(e)=>{addNewRoom(e,"add"); /*clearStyle()*/}} className='addRoom-accom'>Add a room +</div></>:<></>} 
                <div className="pax-done-btn">
                    <div className="paxClear" onClick={(e) => { clearData(e) }} >Clear Pax</div>
                    <button className="paxDone" onClick={(e) => { confirmButton(e, "done");}}>Done</button>
                </div>
            </div>
        </div>
    );
}

export default PaxDropdown;