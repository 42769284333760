import { getBackgroundColor } from "../../../constants"
import { dateToConventionalFormat, transformDate } from "../../../utility/globalFunc"

export const ImageLayout =({imageList=[]})=>{        
    return(
        <div className="image-grid-container">
             { imageList.length ? imageList.slice(0,3).map((imgList,id)=>(<img  src={imgList} alt="accomodation image layout" key={id}/>)):<></> }
        </div>
    )
}

export const DateAtom =({date})=>{     
    return(
        <div className="datesAtom">
            <span className='quotation_tour_date_first-date'>{date[0] ?transformDate(date[0]):null  }</span>
           { date[1] && <span className='quotation_tour_date_arrow'>&rarr;</span>}
           { date[1] && <span className='quotation_tour_date_second-date'>{transformDate(date[1])}</span>}
        </div>
    )
}

export const AccomStockStatus=({status,className="room-stock-status"})=>{
    const toCapitalize = (txt) => {
        return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
    }
    return (
        <div className={className} style={{ backgroundColor: getBackgroundColor(status) }}>
            <label className='status_txt'>{toCapitalize(status)}</label>
        </div>
    )
}