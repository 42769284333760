import React, { useState,useRef,useEffect } from "react";
import { calculateMinMaxAndSumAccom, scrollToTop} from "../../utility/globalFunc.js"
import {formatPax,ROOMPAXDATA,getTotalPax} from "../../constants.js"
import { useDispatch } from "react-redux";
import {accomPaxBookingDefault} from "../../store/accomodationReducer.js"
import useOutsideClick from '../../hooks/useOutsideClick.js';
import PaxDropdownBooking from "./PaxDropdownBooking.js";

function BookingPersonV1({optionData,setOptionData,cname,paxInfo,scroll,setLablel=null,serviceRerservation}) {
  const [popupOpen, setPopupOpen] = useState({dropDown: false,submitClk: false,addRoom:false});
  
  const btnref = useRef(null);
  const isFirstRender = useRef(true);  

  const dispatch = useDispatch();

  useEffect(() => {
    if (isFirstRender.current) {
      setOptionData();
      isFirstRender.current=false
    }
    
  }, []);

  useOutsideClick(btnref, () => {
   // let disabled=hasAgeZero(optionData);
    if (popupOpen.dropDown) {
      scrollToTop();
     
    }
    setPopupOpen({ dropDown: false, submitClk: false }); 
  });


  const handleDropdown = (e) => {
    e.preventDefault()
    setPopupOpen((prevstate) => ({
      ...prevstate,
      dropDown:!popupOpen.dropDown
    }));
    setOptionData(paxInfo)

  };

  const confirmPax = (e, name) => {
    e?.preventDefault();
   
    if (name === "clear") {
      setOptionData([ROOMPAXDATA])
    }
    else {
      setPopupOpen((prevstate) => ({ ...prevstate,dropDown: false,submitClk: true,}));
      dispatch(accomPaxBookingDefault(optionData))
    }
   
  };

  const labelPax = (paxInfo) =>{
   if(paxInfo.length >0 && getTotalPax(paxInfo[0])> 0){
    return <>{ formatPax(paxInfo[0])}</>
   }
   if(paxInfo.length > 0 && cname == 7 && setLablel != null && getTotalPax(paxInfo[0]) == 0 ){
    return <>{setLablel}</>
   }
   return '';
  }

  useEffect(()=>{
    if (!popupOpen?.dropDown) {
    }
    if(scroll){
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth',
      });
    }
  },[popupOpen])


  return (
    <div className="selectDropdown-pax fade-in-300" ref={btnref}  style={ cname ==="accomodation"?{justifyContent: "flex-start",marginLeft:"10px"}:{} } >
      <div className={"numOfPax " +(!popupOpen.dropDown ?"numOfPax_sel":"paxpop_open")} onClick={(e) => handleDropdown(e)} >
        <div className="pax-text">{popupOpen.dropDown ?  "Select nb of pax": <span> Number of pax</span>}</div>
        {
          !popupOpen.dropDown &&
          <div className="dropdown-paxmenu fade-in-300" >
              <div className="end_hover selLabel2">
               {labelPax(paxInfo)}
              </div>    
          </div>
        }
      </div>

      {popupOpen.dropDown && <PaxDropdownBooking optionList={optionData} setOptionData={setOptionData} cname={cname} confirmPax={confirmPax} serviceRerservation={serviceRerservation}/>}
    </div>
  );
}

export default BookingPersonV1;