import React,{useState,useEffect} from 'react';
import ListTransfer from './ListTransfer.js';
import BookingPerson from '../Transfer/BookingPerson.js';
import {paxData} from "../../constants.js";
import ButtonBlock from '../MiniComponents/ButtonBlock.js';
import {paxDetails} from "../../store/excursionReducer.js";
import {delPopup} from "../../store/transferReducer.js"
import { useSelector,useDispatch } from 'react-redux';
import {addLists} from "../../store/transferReducer.js"
import {checkDelete} from "../../store/errorReducer.js"
import {DeletePopup} from "../Transfer/SaveCotation.js"
import MessagesPopup from '../MiniComponents/MessagesPopup.js';
import { Skeleton } from '@mui/material';
import usePopupData from '../../hooks/usePopupData.js';



function TransferInputs({scroll, gridStyle}) {
    const [searchClick,setSearchClick] =useState(false)
    const [gridStructure, setgridStructure] = useState({ "gridTemplateColumns": "226px 265px 372px 61px 362px", "display": "grid", "position": "relative", })
    // let gridStructure = { "gridTemplateColumns":"226px 265px 328px 35px 328px","display":"grid","position":"relative",}
    const {transferUpdates,transferList,regionDetails,loading,transferPax}=useSelector((state) => state.transfer_Global);
    const {isActive:delActive,isPopup} = useSelector((state) => state.errorreducer.delete)
    const {messages} = useSelector((state) => state.errorreducer) 
    const {isOpen,openPopup,getPopupContent}=usePopupData() 
    const [popupclose,setpopupclose] =useState(false)

    const dispatch = useDispatch();

    useEffect(() => {
        if (window.location.href.includes('/select-vehicle')) {
            setgridStructure ({ "gridTemplateColumns": "226px 265px 328px 35px 328px", "display": "grid", "position": "relative", })
        }
    }, [])
    

    useEffect(() => {
        if(popupclose){
            openPopup("del-message")
            const timeoutId = setTimeout(() => {
                setpopupclose(false)
              }, 2000); 
        
              return () => {
                clearTimeout(timeoutId);
            };
        }
        
    }, [popupclose]);
    
    
    // to open the delicom and done button
    const deleteItemClick = (e,name)=>{
        e.preventDefault();    
            if(name === "open_delpopup"){
                let sendObj = {delbtn:true,delpop:false}
                dispatch(checkDelete(sendObj))
            }
            else{
                let sendObj = {delbtn:false,delpop:false}
                setSearchClick(false)
                dispatch(checkDelete(sendObj))
            }
        
    }
    
    return (
        <>
            <div className='transfer_box' style={{background:loading ?"#f4f4f4":""}}>
                <div className="headTransfer">

                    {!loading ?
                        <>
                            {!delActive ? 
                                <div className="delTransferBtn" onClick={(e) => { deleteItemClick(e, "open_delpopup") }}id="deleteButton" >
                                    Delete transfer
                                </div>
                                :
                                <div></div>
                            }
                        </>:<Skeleton/> 
                    }        
                    
                    {/* {!loading ?<BookingPerson  cname={2} scroll={scroll} paxInfo={transferPax}/>:<Skeleton/>} */}
                </div>
                <ListTransfer styles={gridStyle} delStatus={delActive} lists={transferUpdates} errchk={searchClick} updateerr={setSearchClick}  scroll={scroll} noClick={false}/>
                {/* {!loading ?<div className="inputImgBackground gif"  onClick={(e) => {addInputBoxes(e)}} id="addTransfer_img_btn"></div>:<></>} */}
                <ButtonBlock addTransfer={true} delbtn={delActive} deleteItemClick={deleteItemClick} setSearchClick={setSearchClick} errchk={searchClick} label="Use the same vehicle for all your transfers"/> 
            </div>

            { isPopup ?<div className='deletepopup_container'><DeletePopup  setSearchClick={setSearchClick} setpopupclose={setpopupclose}/></div>:<></> }
            {messages !== null ?<div className='deletepopup_container'><MessagesPopup data={messages}/></div>:<></>}
            {isOpen ? getPopupContent():<></>} 
            
        </>
    )}

export default TransferInputs;