import React, { useRef } from 'react'
import { NAVBAR_HEIGHT, pluralize } from '../../constants'
import { isArrayOfObjects, scrollToDependsBoundary } from '../../utility/globalFunc';
import { AmenitiesPattern } from './Atom';
import staticIcon from "../../Assets/images/accomodation/icons/others.png";


function HotelAmenitieDetail({detail,isActive,detailIndice,handleClick,onClose}) {
    const amenitieRef=useRef(null);
    const btnRef=useRef(null);
    const patternRef=useRef(null);

    
    const getMaxHeight = (isActive) => {
        let height = isActive ? (patternRef.current?.scrollHeight) || 0 + "px" : 0;
        return height;
    }

    const handleClose=(detailId, detailIndice)=>{
        onClose&&onClose(detailId, detailIndice)
        scrollToDependsBoundary(amenitieRef.current,"start","smooth",-(NAVBAR_HEIGHT+40))
    }


    return (
        <div key={detail.name} ref={amenitieRef} className={`amenities-element ${isActive ? 'active' : ''}`}>
            <div className='element-head' onClick={() => handleClick(detail.id)}>
                <div className={'element-head-name ' + (isActive ? "enable" : "")}>
                    <img src={detail.icon !== null ? detail.icon : staticIcon} alt={detail.name} />
                    <span>{detail.name}</span>
                </div>
                {/* {isActive?<div className='fade-out-600'></div>:<div className='fade-in-600'>{detail.elements.length}{pluralize(detail.elements.length, " Element")}</div>
                                } */}
                <div className={`fade-${isActive ? "out" : "in"}-visibility-200`}>
                    {detail.elements.length}
                </div>
            </div>
            <div className='close-button-container fade-in-300'>
                {isActive&&isArrayOfObjects(detail.elements) && <button className='close-tab-button' onClick={() => handleClose(detail.id, detailIndice)} ref={btnRef} >Close tab</button>}
            </div>
            <div ref={patternRef} style={{ height: getMaxHeight(isActive) }} className={`pattern-parent ${isActive ? "isActive" : ""}`} >
                {/* {active&&isArrayOfObjects(detail.elements)&&<button className='close-tab-button' onClick={()=>handleClosePattern(detail.id,detailIndice)}  ref={el => btnsRef.current[detailIndice]=el} >Close tab</button>} */}
                {<AmenitiesPattern data={detail.elements} pattern={detail.pattern || "lr"} />}
            </div>
        </div>
    )
}

export default HotelAmenitieDetail
