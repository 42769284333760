import React,{useState,useEffect, useCallback} from 'react';
import { GoogleMap, useJsApiLoader ,DirectionsRenderer,Marker  } from '@react-google-maps/api';
import MapPopup from './MapPopup.js';
import {MAP_OPTIONS, MAURITIUS_BOUNDS} from "../../constants.js"

function MapContainer({openMap,setOpenMap,alldata,details,mappoints,name}) {
    const[map,setMap]=useState(null);
    const [directions, setDirections] = useState(null);
    const [mapAnimate,setMapAnimate]=useState('fade-in');
    const mapstyles ={width: "1508px",height:" 855px",borderRadius:"0 0 27px 27px"}
    const labelStyle = {
      backgroundColor: '#fff',
      color: 'white',
      padding: '5px 10px',
      borderRadius: '5px',
      fontSize: '14px',
      fontWeight: 'bold',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
    };
    const Waypoints = mappoints.length > 2 && name ? mappoints.slice(1,-1).map((data)=>{return{"location":{"lat":data.position.lat,"lng":data.position.lng}}}):[{"location":mappoints}]
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
      })
  
  
    let poisLength = {"lat":mappoints.lat,"lng":mappoints.lng}

      const directionsData = [
        {
          origin: name ? {"lat":mappoints[0].position.lat,"lng":mappoints[0].position.lng} :poisLength,
          destination: name && mappoints.length === 1  ?{"lat":mappoints[0].position.lat,"lng":mappoints[0].position.lng}
                      :!name ? poisLength
                      :{"lat":mappoints[mappoints.length-1].position.lat,"lng":mappoints[mappoints.length-1].position.lng}, 
          waypoints: Waypoints
        },
      ];


      useEffect(() => {
        if(!isLoaded)  return
        const directionsService = new google.maps.DirectionsService();
        const requests = directionsData.map(({ origin, destination, waypoints }) => ({
          origin,
          destination,
          waypoints,
          travelMode: window.google.maps.TravelMode.DRIVING
        }));
    
        Promise.all(requests.map(request => new Promise(resolve => {
            directionsService.route(request, (result, status) => {
              if (status === 'OK') {
                resolve(result);
              } else {
                resolve(null);
              }
            });
          }))).then(results => {
            setDirections(results[0]);
          });
      }, [map]);

      const handleMapClose=(open)=>{
        if (open) {
            setMapAnimate("fade-in");
            setOpenMap(open);
        }else{
            setMapAnimate("fade-out")
            setTimeout(() => {
                setOpenMap(open);
            }, 300);
        }


      }

      const onMapLoad=useCallback((map)=>{
        setMap(map);
        const MRU_BOUNDS=new window.google.maps.LatLngBounds(MAURITIUS_BOUNDS[0],MAURITIUS_BOUNDS[1]);
        map.fitBounds(MRU_BOUNDS);
      },[])
    
   console.log("mappoints",mappoints);
   
      return isLoaded ? (
        <div className='mapbox_parent'>
           
                <div className={`map_container ${mapAnimate}`}>
                    <div>
                        <div className='mapHeader'>
                        <div className='headerName'>{alldata.name}<span>{alldata.type}</span></div>
                            <div className='closeBtn' onClick={(e)=>{handleMapClose(false)}}>Close</div>
                        </div>
                        <GoogleMap
                           mapContainerStyle={mapstyles}
                            center={!name ? poisLength : mappoints.length ===1 ? {"lat":mappoints[0].position.lat,"lng":mappoints[0].position.lng} :{ lat: -20.348404, lng: 57.552152 }}
                            zoom={name && mappoints.length === 1 ? 14 :!name ? 13 :10 }
                            onLoad={onMapLoad(map)}
                            options={MAP_OPTIONS} // Apply custom map options
                            
                        >
                          {directions &&
                              <DirectionsRenderer
                                  directions={directions}
                                  options={{
                                      polylineOptions: {
                                      strokeColor: '#0094FF', // Red color for the route polyline
                                      strokeOpacity: 1, // Opacity of the route polyline
                                      strokeWeight: 6, // Thickness of the route polyline
                                      },
                                      label:1,
                                      preserveViewport: true, // Stop zooming while rendering
                                       suppressMarkers: true, // its true means location logo is hide Example of other options you can include
                                  }}
                              />
                          }
                          {mappoints.map((mark) => <Marker key={mark.id} position={mark.position} label={{text:`${mark.id.toString()}`,color:'#fff',fontWeight:"700",backgroundColor: "#0F7173"}}  style={labelStyle}/>)} 
                          <div className='map_container_popup'>
                               <MapPopup data={alldata} priceDetails={details}/>      
                          </div> 
                        </GoogleMap>  
                    </div>    
                </div>:<></>
        </div>
    ):<></>
}

export default MapContainer;