import React from 'react';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import useHotelList from '../hooks/useHotelList.js';
import {updateRating,incPaginationNumber} from "../../../store/accomodationReducer.js"
import {useDispatch,useSelector } from 'react-redux';
import { getAccomInputObject, scrollToTop } from '../../../utility/globalFunc.js';
import { BUDGET_RANGE } from '../../../constants.js';

function StarRatingMenu({data,selectStarRating,needApi,scrollRef}) {
    const {criteria,roomPax,calendarDate,backendDate,paginationPage,isOffer,budgetRange} = useSelector((state) => state.accomodationreducer)
    const {fetchHotelList,loading} = useHotelList();
    const dispatch = useDispatch()

    const handleCheck = (e,item)=>{
       e.preventDefault();
        if(!needApi){
            selectStarRating(e,item)
        }
        else{
            let chkData = toggleRatingChecked(data,item.id);
            const ratings = chkData.filter(item => item.checked).map(item => item.rating);
            const clientsInfo = roomPax.map((item) => ({
                    client_type: item.clientType[0].toUpperCase(),
                    adult_count: item.adult,
                    children:item.paxAge.flatMap(item =>
                        Object.entries(item)
                        .filter(([key, value]) => key !== 'id' && !isNaN(value))
                        .map(([key, value]) => Number(value))
                    )
            }));
            const actionAND = criteria.map(item => item.action === "AND" ? item.id_tag : null).filter(id_tag => id_tag !== null);
            const actionOR = criteria.map(item => item.action === "OR" ? item.id_tag : null).filter(id_tag => id_tag !== null);

            const updatedBudget = {
                from: budgetRange[0] === BUDGET_RANGE.MIN_PRICE ? BUDGET_RANGE.MIN_PRICE : budgetRange[0],
                to: budgetRange[1] === BUDGET_RANGE.MAX_PRICE ? BUDGET_RANGE.MIN_PRICE : budgetRange[1],
            };
            // SEND OBJECT FOR HOTEL LIST  
            let sendObject = getAccomInputObject({
                dates:backendDate,
                clientsInfo,
                star_rating:ratings,
                budgetRange:updatedBudget,
                OR:actionOR,
                AND:actionAND,
                offers:isOffer
            })
            scrollToTop();
            let redirect="/accomodation-select"
            let module = 0
            // HOTEL LIST            
            fetchHotelList(sendObject,redirect,module,1,true);
            dispatch(updateRating(chkData));
        }
       
    }

    const toggleRatingChecked = (ratingsData, id) => {
        return ratingsData.map(star => {
            if (star.id === id) {
                return { ...star, checked: !star.checked };
            }
            return star;
        });
    };

    return (
       
             <>
                {
                    data.map((item)=>{
                        return(
                            <div className={'star-icon '+(item.checked ? 'addborder-star':'')} key={item.id} onClick={(e)=>{handleCheck(e,item)}}>
                               <>
                                    <div>{item.name_tag}</div>
                                    <div className='rating-menu-star'><Rating emptyIcon={<StarIcon fontSize="inherit" />} name="read-only" value={item.rating} readOnly size="small" /></div>
                                    <div className={'checkbox-star '+(item.checked ?'sel-star':'')}></div>
                                </>
                            </div>
                        )
                    })
                }
                </>
        
    );
}

export default StarRatingMenu;