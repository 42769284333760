import { useEffect, useRef, useState } from "react"
import {addingTotalPax,pluralize, TRANSFER_VEHICLE_TYPE} from "../../constants"
import { formatPrice, getCurrency, transformDate } from "../../utility/globalFunc"
import { format} from 'date-fns';
import { useSelector } from "react-redux";


export let filterarray = {
    
        "Region": [
            "North",
                "South",
                "East",
                "West"
        ],
        "Popular filter": [
            "All-inclusive",
                "Golf",
                "Plage",
                "Family hotel",
                "Accessible",
                "Kids Club",
                "Welness & SPA"
        ],

        "Hotel structure":[
            "Golf",
                "Private pool",
                "Climatisation"
        ]
    
}

export let accomHotel =  [

    {
        desc:"Explore Mauritius's lush inland beauty. Visit Grand Bassin, a sacred lake, and marvel at the landscapes of Black River Gorges National Park.\r\nHead to Chamarel for a 300-foot waterfall and the Seven-Coloured Earth geological phenomenon.\r\nConclude at Rhumerie de Chamarel for a guided distillery tour and rum tasting an immersive journey through nature, spirituality, and local craftsmanship in the heart of Mauritius.",
        img: "https://mautourcoassets.s3.amazonaws.com/excursion_images/Wild%20South/Black_River_Gorges.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3FLDZHFNR2QTDS73%2F20240328%2Feu-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240328T114833Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=91cd29881ef51b571b48ff39ef664c7c4a833fe3407d2b12d6867c407515d783",
        loc_det: "Mahébourg, Grand Port",
        name: "Shandrani Beachcomber Resort & SPA",
        offer: "(15% Early Bird Offer)*",
        price: 230,
        offer_price: 300,
        rating: 4,
        region: "North",
        Types_of_rooms: [
            "Deluxe double room",
            "Single Standard room"
        ],
        id: 1,
    },
   
]



export let selected_excursion = {
        "excursion_name":"Randonné Morne Brabant",  
        "excursion_type":"A la carte",       
        "excursion_title":"Un panorama à couper le souffle",
        "description":["nécessitant parfois l'utilisation de cordes pour surmonter les sections escarpées.","Une fois au sommet, une vue panoramique ","Le Morne Brabant est également associé à une histoire"],
        "point_of_interests": [
            {
                "description": "Connect with spirituality and natural beauty of Grand Bassin",
                "id": 1
            },
            {
                "description": "Discover the wonders of the hinterland and wildlife of Mauritius with the Black River Gorges",
                "id": 2
            },
            {
                "description": "Visit of Rhum making Distillery",
                "id": 3
            },
            {
                "description": "Visit of Chamarel Coloured Earth & Waterfall",
                "id": 4
            }
        ],
        "notes": [
            {
                "description": "Connect with spirituality and natural beauty of Grand Bassin",
                "id": 1
            },
            {
                "description": "Discover the wonders of the hinterland and wildlife of Mauritius with the Black River Gorges",
                "id": 2
            },
            {
                "description": "Visit of Rhum making Distillery",
                "id": 3
            },
            {
                "description": "Visit of Chamarel Coloured Earth & Waterfall",
                "id": 4
            }
        ],
        "particulars": [
            {
                "accessibility": "Easy",
                "duration": "8H",
                "id": 1,
                "language": [
                    "fr",
                    "eng"
                ],
                "meals": "included"
            }
        ],

        "quotedata":
        {
            name:"Randonnée Morne Brabant",
            particulars:{meals:"included",duration:"3H30",pickup:"included"},
            excursion_image:"",
            vehicle_type:[{id:1,name:"Individual vehicle"},{id:2,name:"Group vehicle"},],
            fromDate:"",
            toDate:"",
            price:360

        },
        "feedback":[
            {
                id:1,
                name:"Brandon Seville",
                pic_url:"",
                place:"Calais, France",
                comments:"Un immense MERCI à Mautourco pour cette magnifique semaine riche en découverte à travers ces superbes excursions proposées. Un grand merci en particulier à Steeve pour ta bonne humeur et ton dynamisme et Clarel pour ta gentillesse, ta bienveillance et ta disponibilité tout au long de cette semaine pour nous avoir permis de découvrir cette magnifique et riche île !"
            },
            {
                id:2,
                name:"Brandon Seville 1",
                pic_url:"",
                place:"Calais, America",
                comments:"Un immense MERCI 2 à Mautourco pour cette magnifique semaine riche en découverte à travers ces superbes excursions proposées. Un grand merci en particulier à Steeve pour ta bonne humeur et ton dynamisme et Clarel pour ta gentillesse, ta bienveillance et ta disponibilité tout au long de cette semaine pour nous avoir permis de découvrir cette magnifique et riche île !"
            },
            {
                id:3,
                name:"Brandon Seville 2",
                pic_url:"",
                place:"Calais, India",
                comments:"Un immense MERCI 3à Mautourco pour cette magnifique semaine riche en découverte à travers ces superbes excursions proposées. Un grand merci en particulier à Steeve pour ta bonne humeur et ton dynamisme et Clarel pour ta gentillesse, ta bienveillance et ta disponibilité tout au long de cette semaine pour nous avoir permis de découvrir cette magnifique et riche île !"
            }

        
        ],
        "excursion_images":["excursion_place1","excursion_place2","excursion_place3","excursion_place1","excursion_place2","excursion_place3","excursion_place1","excursion_place2","excursion_place3","excursion_place1","excursion_place2","excursion_place3"],
        "pax_details":{ "adult_pax": 1,"teen_pax": 1,"child_pax": 1},
        "cancelation_policy":[
            {id:1,name:"Free Cancellation",details:"You can cancel your reservation without any fees up to 48 hours before the start of the tour."},
            {id:2,name:"Late Cancellation",details:"In case of cancellation between 48 hours and 24 hours before the start of the tour, a cancellation fee equivalent to 50% of the total cost of the reservation will be applied."},
            {id:3,name:"Day-of-Tour Cancellation",details:"Cancellations made less than 24 hours before the start of the tour or no-shows will not be refunded."},
            {id:4,name:"Reservation Modification",details:"Changes in the date or time of the tour are subject to availability and can be made without fees up to 48 hours before the start of the tour. Late changes may incur additional fees."},
            {id:5,name:"Exceptional Conditions",details:"In case of exceptional circumstances, such as extreme weather conditions or unforeseen situations, we reserve the right to cancel the tour. In this case, customers will have the choice between a full refund or the option to reschedule their reservation."
    }


    ]
        
}

export const ShowCalendar = ({errchk, label,label2})=>{
    return(
        <div 
            className={`Calendar_comp__parent ${errchk && label2 === "dd/mm/yyyy" ? "errorContainer" : ""}`}
        >
            <div className={`calander_txt1 ${errchk && label2 ==="dd/mm/yyyy" ? "error" : ""}`}>
              {label}</div>
            <div className={`calander_txt2 ${label2 !== "dd/mm/yyyy" ? "date_added" : ""} ${errchk && label2 === "dd/mm/yyyy" ? "error" : ""}`}>
                {label2}
            </div>

        </div>
    )
}

export const CalanderHeader=({label,label2,dummytxt,dates,removeLabel2})=>{

    // console.log("dates",dates);
    return(
        <div className="Calendar_header__parent">
            <div className={"cal_headboxes "+(dates && dates.length ? "fromsel":"")}>
                <div>{label}</div>
                <div>{dates && dates.length && !removeLabel2 ? dates[0]  :dates !=="" && dates.length && removeLabel2 ? dates: dummytxt}</div>   
            </div>
           { 
           !removeLabel2 ?
           <div className={"cal_headboxes depature-calander "+(dates && dates.length === 2 ? "toosel": dates && dates.length ?"gngtoosel":"")}>
                <div>{label2}</div>
                <div>{dates && dates.length === 2 ? dates[1]: dummytxt}</div>   

            </div>
            :null}
        </div>
    )
}
export const CalanderHeaderTransfer=({label,dummytxt,dates})=>{

    return(
        <div className="Calendar_header__parent">
            <div className={"cal_headboxes "+(dates !=null ? "fromsel":"")}>
                <div>{label}</div>
                <div>{dates   ? format(dates,'dd/MM/yyyy') : dummytxt}</div>     
            </div>
        </div>
    )
}

export const CalendarFooter=({handleDates})=>{
    return(
        <div className="Calendar_footer">
           <div className="calen_clear" onClick={(e)=>{handleDates("clear")}}>Clear date</div>
           <button className="validate_btn_multi" onClick={(e)=>{handleDates("validate")}}>Validate</button>
        </div>
    )
}


export const DisplayPax = ({totalpax=[],unit,list,price,roomNo,currency,cname})=>{
    const [animateClass,setAnimateClass]=useState('');
    const [priceText,setPriceText]=useState(price);
    const currentRoomTotalPax = totalpax?.filter((item,index) => index === roomNo )
    const { tempId: currentTransferId } = useSelector((state) => state.transfer_Global);
    const { AdultCount, ChildCount, TeenCount,InfantCount } = useSelector((state) => state.transfer_Global.transferUpdates[currentTransferId]);

    useEffect(()=>{
      setAnimateClass("fade-out-100");
      const timeout = setTimeout(() => {
        setPriceText(price);
        setAnimateClass("fade-in-100");
        return () => clearTimeout(timeout);
      }, 100);
    },[price]);
    

    const getNumberOfPax=()=>{
        let nbr=0;
        let pax=" Adult";
        if (currentRoomTotalPax.length) {
            nbr+=currentRoomTotalPax[0].adult;
            nbr+=currentRoomTotalPax[0].infant;
            nbr+=currentRoomTotalPax[0].teen;
            nbr+=currentRoomTotalPax[0].child;
            if (currentRoomTotalPax[0].child>0||currentRoomTotalPax[0].teen>0||currentRoomTotalPax[0].infant>0) {
                pax=" Pax"
            }else{
                pax=pluralize(nbr," Adult"," Adults");
            }
        }
        return nbr+pax;
    }
    
    function calculPriceByPax(){
        let adultPrice = list.AdultPrice * AdultCount;
        let childPrice = list.ChildPrice * ChildCount;
        let teenPrice = list.TeenPrice * TeenCount;
        let totalPrice = adultPrice + childPrice + teenPrice;
        return totalPrice; 
    }

    const getTransferVehicleLabel=()=>{
        if (list?.MaxPax>unit) {
            const isOnlyAdult=!(list.ChildCount>0||list.InfantCount>0||list.TeenCount>0);
            return unit+" "+(isOnlyAdult?pluralize(unit,"Adult"):"Pax");
        }else{
            const unitNb=Math.ceil(unit/list.MaxPax);
            return unitNb+" "+pluralize(unitNb,"Unit","Units")
        }
    }

    return(
        <div className="paxContainer">
            {
                //price transfer
                cname === 2 ? 
                    <div className={`exprice ${animateClass} show`}><span>{getCurrency(currency)}</span> 
                        {
                            list.TransferType === "Pax"?
                            formatPrice(calculPriceByPax())
                            :
                            formatPrice(priceText)
                        }
                    </div>
                : 
                <div className={`exprice ${animateClass} show`}><span>{getCurrency(currency)}</span> {formatPrice(priceText)}</div>
            }
             {
                cname === 1 ? 
                    <div className="paxNumber">
                        {addingTotalPax(totalpax,true)}
                    </div>
                : (currentRoomTotalPax.length && currentRoomTotalPax[0]?.teen || currentRoomTotalPax[0]?.child || currentRoomTotalPax[0]?.infant) > 0 ? 
                    <div className="paxNumber">{addingTotalPax(currentRoomTotalPax,false)} pax</div>
                : cname === 5 ?
                    <div className="paxNumber">
                        {getNumberOfPax()}
                    </div>    
                :  cname === 2 ?
                    <div className="paxNumber">
                        {getTransferVehicleLabel()}
                    </div>
                :
                <div className="paxNumber">
                    {currentRoomTotalPax[0]?.adult} { pluralize(currentRoomTotalPax[0]?.adult,"Adult")}
                </div>
             }  
            
        </div>
    )
}

export const CalendarButton=({ direction, handleClick, disabled,value=new Date(), setRightRef=undefined}) => {
    
    const [month,setMonth]=useState(value.getMonth());
    const [year,setYear]=useState(value.getFullYear());
    const rightRef=useRef(null);

    const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];

    useEffect(()=>{
        if (direction==="right") {
            setRightRef(rightRef);
        }
    },[])

    useEffect(()=>{
        setMonth(value.getMonth());
        setYear(value.getFullYear());
    },[value])

    const getSecondMonthIndex=()=>{
        return month+1>=months.length?0:month+1;
    }

    const getSecondMonth=()=>{
        return months[getSecondMonthIndex()];
    }

    const getSecondYear=()=>{
        return getSecondMonthIndex()<month?year+1:year;
    }
  return (
    <>
      {direction === "left" ? (
        <>
          <button
            type="button"
            className={`rmdp-arrow-container rmdp-left ${disabled?"disabled":""}`}
            aria-roledescription="button to navigate left"
            style={{ cursor:disabled?"default":"pointer" }}
            onClick={handleClick}
          >
            <i className="rmdp-arrow"></i>
          </button>
          <div className={`rmdp-header-values ${disabled?"disabled":""}`} onClick={handleClick}>
            <span style={{ cursor: disabled?"default":"pointer" }}>{months[month]}</span>,
            <span style={{ cursor: disabled?"default":"pointer" }}>{year}</span>
          </div>
        </>
      ) : (
        <>
          <div className="rmdp-header-values" onClick={handleClick} ref={rightRef}>
            <span style={{ cursor: "pointer" }}>{getSecondMonth()}</span>,
            <span style={{ cursor: "pointer" }}>{getSecondYear()}</span>
          </div>
          <button
            type="button"
            className="rmdp-arrow-container rmdp-right"
            aria-roledescription="button to navigate right"
            onClick={handleClick}
          >
            <i className="rmdp-arrow"></i>
          </button>
        </>
      )}
    </>
  );
}
  