import React,{useState,useRef, useEffect} from 'react';
import {scrollRemove} from '../../constants.js'
import { HotelImgPreview, ImageWithFallBack } from '../MiniComponents/Atom.js';
import DefaultMainHotelImage from "../../Assets/images/default-img/hotel-main.jpg"
import LazyImage from '../MiniComponents/LazyImage.js';

const CAROSEL_CASE={
    OPEN_POPUP:"OPEN_POPUP",
    CAROSEL_IMG:"CAROSEL_IMG"
}

function ImageCarosel({data=[]}) {

    const [openPopup,setOpenpopup]=useState(false)
    const [imageIndex,setImageIndex]=useState(0)
    const popupRef = useRef(null);
    const scrollRef = useRef(null);
    const [imgAnimate,setImgAnimate]=useState("");
    const [existImage,setExistImage]=useState(true);

    let totalLength  = data.length -1;

    const scrollToNext=(i)=>{
        const container=scrollRef.current;
        const children=Array.from(container.children);
        const containerRect=container.getBoundingClientRect();

        const childRect=children[i].getBoundingClientRect();
        if (childRect.right>containerRect.right) {
            setImgAnimate("translate-x-right-animation");
            container.scrollTo({
                left:container.scrollLeft+childRect.right-containerRect.right,
                behavior:"smooth",
            })
        }else{
            setImgAnimate("");
        }
    }
    

    const scrollToPrevious=(i)=>{
        const container=scrollRef.current;
        const children=Array.from(container.children);
        const containerRect=container.getBoundingClientRect();

        const childRect=children[i].getBoundingClientRect();
        if (childRect.left<containerRect.left) {
            setImgAnimate("translate-x-left-animation");
            container.scrollTo({
                left:i===0?0:container.scrollLeft-Math.max(containerRect.left-childRect.left,0),
                behavior:"smooth",
            })
        }else{
            setImgAnimate("");
        }
    }
    
    const caroselPopup =(e,name,ind)=>{
        e.preventDefault();
        setImgAnimate("");
        
        if(name === CAROSEL_CASE.OPEN_POPUP){
            setOpenpopup(true) 
        }
        if(name === CAROSEL_CASE.CAROSEL_IMG){
            setImageIndex(ind)
        }
    }

    const handleOutsideClick = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
          setOpenpopup(false);
        }
    };

      useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
          document.removeEventListener('mousedown', handleOutsideClick);
        };
      }, []);  

      useEffect(() => {
        scrollRemove(openPopup)
      }, [openPopup]);  

    useEffect(()=>{
       // console.log("imageIndex",imageIndex);
        
    },[imageIndex])      

    const imageList = data.map((item,id)=>{
        return <LazyImage 
                key={item}
                src={item} 
                style={{filter:id===imageIndex ? "brightness(0.5)":""}}
                onClick={(e)=>{caroselPopup(e,CAROSEL_CASE.CAROSEL_IMG,id)}}
                className={`hotel_image imagelist_item ${id===imageIndex?imgAnimate:""}`}
                fallBackImg={DefaultMainHotelImage}
                hasFallback={true}
               />
    })
    

    const moveCarosel = (e,name)=>{
        e.preventDefault();
        if(name === "right" && imageIndex !== totalLength  ){
            let index=imageIndex+1;
            setImageIndex(index);
            scrollToNext(index);
        }
        else if(name === "left" && imageIndex !== 0){
            let index=Math.max(imageIndex - 1,0);
            setImageIndex(index);
            scrollToPrevious(index);
        }
    }

    const onImgError=()=>{
        setExistImage(false);
    };

    return (
        <div className='imageCarosel_parent'>
            <div className='imageCarosel_images'>
                <div className='imagesContainer'>
                    <LazyImage src={data[0]} alt="excursion-image" className={`hotel_image main carosel-image`} onClick={(e)=>{caroselPopup(e,CAROSEL_CASE.OPEN_POPUP);setImageIndex(0)}} onError={onImgError} hasFallback={true} fallBackImg={DefaultMainHotelImage}/>
                </div>
                <div className='rytImages'>
                    {data[1]&&<LazyImage src={data[1]} key={"img1"} alt="excursion-image" className='hotel_image secondary carosel-image' onClick={(e)=>{caroselPopup(e,CAROSEL_CASE.OPEN_POPUP);setImageIndex(1)}} fallBackImg={DefaultMainHotelImage} hasFallback={true}/>}
                    {data[2]&&
                        <HotelImgPreview nbrLeftImages={data.length-3} imgSrc={data[2]} imgClassName={"hotel_image tiers carosel-image"} onClick={(e)=>{caroselPopup(e,CAROSEL_CASE.OPEN_POPUP);setImageIndex(2)}}/>
                    }
                </div>
            </div>
            
            {
                openPopup ?
                <div className='carosel_popup' >
                    <div className='carosel_body' ref={popupRef}>
                        <div className='carosel_mainimage'>                
                             <LazyImage className={"img-carosel"} src={data[imageIndex]} alt='Main img' fallBackImg={DefaultMainHotelImage} hasFallback={true}/>
                            {imageIndex>0&&<div className='arrow-carosel carosel_left' onClick={(e)=>{moveCarosel(e,"left")}}></div>}
                            {imageIndex<data.length-1&&<div className='arrow-carosel carosel_right' onClick={(e)=>{moveCarosel(e,"right")}}></div>}

                        </div>
                        <div className='carosel_imagelist' ref={scrollRef} >
                            {imageList}
                        </div> 
                    </div>
                </div>
                :null
            }
            
        </div>
    );
}

export default ImageCarosel;