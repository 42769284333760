import React,{useState} from 'react';
import HotelAmenitieDetail from '../../MiniComponents/HotelAmenitieDetail.js';

function HotelAmenities({lists}) {
    const [activeIndexes, setActiveIndexes] = useState([]);
    const handleClosePattern=(index,refInd)=>{
      let id=String(index);
      setActiveIndexes((prev)=>{
        return prev.filter((item)=>item!=id);
      })
    }

    const handleClick = (index) => {
        let strData = String(index)
        setActiveIndexes((prevIndexes) => {
            if (prevIndexes.includes(strData)) {
              return prevIndexes.filter((item) => item !== strData);
            } else {
              return [...prevIndexes, strData];
            }
        });
    };

    const isActive=(item)=>{
      return activeIndexes.includes(String(item.id));
    }
    
    return (
        <div className='HotelAmenities-parent'>
             {
                lists?.filter((amenity)=>amenity.elements.length>0).map((item,ind)=>{
                    const active=isActive(item);
                    return(
                      <HotelAmenitieDetail key={item.name} detail={item} isActive={active} detailIndice={ind} handleClick={handleClick} onClose={handleClosePattern}/>
                    )
                })
             }
        </div>
    );
}

export default HotelAmenities;