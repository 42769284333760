import React,{useState} from 'react';

function CarDropdown({label,data,handleValues}) {

const [selectedOption,setSelectedOption]=useState({name:"Please select a vehicle for your excursion ",id:null,count:null})
const [popupOpen,setPopupOpen]=useState(false)

let vehicleList = data.filter(data => data.transfer_type !== "SHARING")



const selectedData =(e,value)=>{
    let {vehicle_name:name,service_details_id:id,vehicle_count:count}=value
    //console.log(value);
    e.preventDefault();
    setSelectedOption((prevstate)=>({
        ...prevstate,
        name:name,
        id:id,
        count:count
    }))
    handleValues("vehicle-name",value)
    setPopupOpen(false)

}

    return (
        <div className='carDropdown-parent'>
            <label>{label}</label>
           <div className='carDropdown-input' onClick={(e)=>{setPopupOpen(!popupOpen)}}>{selectedOption.count !== null ? selectedOption.count  + "x ":null}{selectedOption.name}</div>
           <div>
            {popupOpen ?
                <ul className='carDropdown-popup'>
                    {
                        vehicleList.map(item=> 
                            <li key={item.service_details_id} onClick={(e)=>{selectedData(e,item)}}>
                                <div>{item.vehicle_count}x {item.vehicle_name}</div>
                                <div>{item.max_pax} Pax Max</div>
                                <div><input type="checkbox" value={item.vehicle_name} defaultChecked={item.vehicle_name === selectedOption.name} /></div>
                            </li>)
                    }
                </ul>:null
            }    
           </div>
           
        </div>
    );
}

export default CarDropdown;