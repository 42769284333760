import { configureStore,combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {errorReducer} from "./errorReducer.js";
import {accomodationData} from "./accomodationReducer.js"
import {excursionData} from "./excursionReducer.js";
import {transferData} from "./transferReducer.js";
import {authentication} from "./authentication.js"
import {globalUpdates} from "./globalReducer.js"
import createMigrate from 'redux-persist/es/createMigrate';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

let NEW_VERSION = 13
const migrations = {
 
  12: (state) => {
    // migration clear out device state
    return {...state,device: undefined}
  },
  [NEW_VERSION]: (state) => {
    // migration to keep only device state
    return {  device: state.device}}
}

const persistConfig = {
  key: 'mautourco',
  storage,
  version:NEW_VERSION,
  timeout: null,
  migrate: createMigrate(migrations, { debug: false }),
  stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
  [excursionData.name]:excursionData.reducer,
  [transferData.name]:transferData.reducer,
  [authentication.name]:authentication.reducer,
  [errorReducer.name]:errorReducer.reducer,
  [globalUpdates.name]:globalUpdates.reducer,
  [accomodationData.name]:accomodationData.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'], // Also ignore REHYDRATE
      },
    }),
  devTools: process.env.NODE_ENV !== 'production', // Enable DevTools in development mode
});



export const persistor = persistStore(store);

export default store