import React,{useReducer, useRef} from 'react';
import usePopupData from '../../hooks/usePopupData';
import { useDispatch,useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import useOutsideClick from '../../hooks/useOutsideClick.js';
import useMoreOptionQuotation from '../../hooks/useMoreOptionQuotation.js';
import { useEffect } from 'react';
import { useState } from 'react';
import Dropdowns from '../Transfer/Dropdowns.js';
import { DROPDOWN_DATA } from '../../constants.js';
import BookingPerson from '../Transfer/BookingPerson.js';
import Multicalander from '../Excursion/Multicalander.js';
import DropdownsAddPax from '../Transfer/DropdownsAddPax.js';


function AddServiceBox({closePopup,openNew,toPath}) {
    

    const { isOpen,getPopupContent,openPopup } = usePopupData();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const btnref = useRef(null);
    const [step,setStep] = useState(1)
    
    useEffect(() => {
    }, [])



    const openSavePop =(e)=>{
        e.preventDefault();
         
    }

    const notSaveData=(e)=>{
        e.preventDefault();
        closePopup();
    }

    useOutsideClick(btnref, () => {
        closePopup();
    });

    const handleDropdownParent = (name, data) => {
        console.log("Dropdown selected:", { name, data });
        if(data.label == "Transfer"){
            setStep(6)
        }else if(data.label == "Accommodation"){
            setStep(4)
        }else if(data.label == "Excursion"){
            setStep(7)
        }
      };

    const askingForService = () =>{
        return (
            <div className='dialogue-asking-service' ref={btnref}>
                <div className='dialogue-asking-service-text'>
                    <p >Are you sure you want to add this service to your booking?</p> 
                    <p>This action is irreversible, and you won't be able to undo it. If you are certain you <br/> want to add this service, please confirm below. New prices and cancellation policies will apply to the added service.</p>
                </div>
                <div className='dialogue-asking-service-container-button' >
                    <button  onClick={(e)=>notSaveData(e)} >Cancel</button> 
                <button className={""} onClick={(e)=>(setStep(2))}>Next</button> 
                </div>
        </div>
        )
    }
    const askingForServiceCaution = () =>{
        return (
            <div className='dialogue-caution-service' ref={btnref}>
                <div className='dialogue-caution-service-text'>
                    <p >Caution</p> 
                    <p>The added item will need to be into your Arrival & Departure date. <br/> If you want to booking an item outside of this range please create a new booking.</p>
                </div>
                    <div className='dialogue-caution-service-date-container disable' style={{display:'grid',gridTemplateColumns:'170px 170px',gap:'13px'}}>
                        {/* <ShowCalendar errchk={false} label={'Arrival'} label2={'18/07/2023'}/>
                        <ShowCalendar errchk={false} label={'Depature'} label2={'18/07/2023'}/> */}
                    </div>
                <div className='dialogue-caution-service-container-button' >
                    <button  onClick={(e)=>notSaveData(e)} >Cancel</button> 
                <button className={""} onClick={(e)=>setStep(3)}>Next</button> 
                </div>
        </div>
        )
    }
    const ChoosingService = () =>{
        return (
            <div className='dialogue-choose-service' ref={btnref}>
                <div className='dialogue-choose-service-text'>
                    <p >Please select how you want to add items to your booking.</p> 
                </div>
                    <div className='dialogue-choose-service-container' >
                        <div className='dialogue-choose-service-container-child' onClick={()=>{setStep(5)}}>
                            <p>Add new pax to you <br/> reservation while adding <br/> a new service</p>
                        </div>
                        <div className='dialogue-choose-service-container-child service'  onClick={()=>{setStep(4)}}>
                            <p>Add new service  to you <br/> reservation for your <br/> existing pax</p>
                        </div>
                    </div>
        </div>
        )
    }

    const PaxSelectionNewService = () =>{
        return(
            <div className='dialogue-pax-selections' ref={btnref}>
                <div className='dialogue-pax-selections-text'>
                    <p >Pax selections</p> 
                    <p>Please select the pax who will be concerned by the new item</p>
                </div>
                <div className='dialogue-pax-selections-selector'>
                    <div className='dialogue-pax-selections-selector-child'>
                        <DropdownsAddPax dropData={DROPDOWN_DATA} imgcount={1} name="Accommodation" center={true} handleDropdownParent={handleDropdownParent}/>
                        <Multicalander label="Arrival" label2={ "dd/mm/yyyy" } rangeDate={[]} month={2} page={1} />
                        <Multicalander label="Departure" label2={ "dd/mm/yyyy"} rangeDate={[]} month={2} page={1} /> 
                    </div>
                <div className="service-pax-info-container" bis_skin_checked="1">
                    <div className="service-pax-container   " bis_skin_checked="1">
                        <div className="service-box-client " bis_skin_checked="1">
                            <div className="service-pax-info" bis_skin_checked="1">
                                <div bis_skin_checked="1">Adult no.1</div>
                            <div className="service-box-client-name" bis_skin_checked="1">tes</div>
                            </div><div className="service-pax-rank " bis_skin_checked="1"></div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className='dialogue-pax-selections-container-button' >
                    <button  onClick={(e)=>notSaveData(e)} >Cancel</button> 
                <button className={""} onClick={(e)=>(setStep(2))}>Next</button> 
                </div>
        </div>
        )
    }
    const PaxSelectionNewServiceTransfer = () =>{
        return(
            <div className='dialogue-pax-selections transfer' ref={btnref}>
                <div className='dialogue-pax-selections-text'>
                    <p >Pax selections</p> 
                    <p>Please select the pax who will be concerned by the new item</p>
                </div>
                <div className='dialogue-pax-selections-selector'>
                    <div className='dialogue-pax-selections-selector-child-excursion'>
                        <DropdownsAddPax dropData={DROPDOWN_DATA} imgcount={1} name="Transfer" center={true} handleDropdownParent={handleDropdownParent}/>
                        <Multicalander label="Transfer date" label2={ "dd/mm/yyyy" } rangeDate={[]} month={2} page={1} />
                    </div>
                    <BookingPerson cname={1} paxInfo={[{"adult": 1,"teen": 0,"child": 0,"infant": 0,"room_id": 1,"paxAge": [],"clientType": ["Standard","STD"]}]} scroll={false} />
                </div>
                <div className='dialogue-pax-selections-container-button' >
                    <button  onClick={(e)=>notSaveData(e)} >Cancel</button> 
                <button className={""} onClick={(e)=>(setStep(2))}>Next</button> 
                </div>
        </div>
        )
    }
    const PaxSelectionNewServiceExcursion = () =>{
        return(
            <div className='dialogue-pax-selections transfer' ref={btnref}>
                <div className='dialogue-pax-selections-text'>
                    <p >Pax selections</p> 
                    <p>Please select the pax who will be concerned by the new item</p>
                </div>
                <div className='dialogue-pax-selections-selector'>
                    <div className='dialogue-pax-selections-selector-child-excursion'>
                        <DropdownsAddPax dropData={DROPDOWN_DATA} imgcount={1} name="Excursion" center={true} handleDropdownParent={handleDropdownParent}/>
                        <Multicalander label="Excursion date" label2={ "dd/mm/yyyy" } rangeDate={[]} month={2} page={1} />
                    </div>
                    <BookingPerson cname={1} paxInfo={[{"adult": 1,"teen": 0,"child": 0,"infant": 0,"room_id": 1,"paxAge": [],"clientType": ["Standard","STD"]}]} scroll={false} />
                </div>
                <div className='dialogue-pax-selections-container-button' >
                    <button  onClick={(e)=>notSaveData(e)} >Cancel</button> 
                <button className={""} onClick={(e)=>(setStep(2))}>Next</button> 
                </div>
        </div>
        )
    }
    const PaxSelection = () =>{
        return(
            <div className='dialogue-pax-selections reservation' ref={btnref}>
                <div className='dialogue-pax-selections-text'>
                    <p >Pax selections</p> 
                    <p>Please select the pax who will be concerned by the new item</p>
                </div>
                <div className='dialogue-pax-selections-selector'>
                <Dropdowns dropData={DROPDOWN_DATA} imgcount={1} name="Accommodation" center={true}/>
                <BookingPerson cname={1} paxInfo={[{"adult": 1,"teen": 0,"child": 0,"infant": 0,"room_id": 1,"paxAge": [],"clientType": ["Standard","STD"]}]} scroll={false} />
                </div>
                <div className='dialogue-pax-selections-container-button' >
                    <button  onClick={(e)=>notSaveData(e)} >Cancel</button> 
                <button className={""} onClick={(e)=>(setStep(2))}>Next</button> 
                </div>
        </div>
        )
    }

    return (
            <>
                {step == 1 && askingForService()}
                {step == 2 && askingForServiceCaution()}
                {step == 3 && ChoosingService()}
                {step == 4 && PaxSelectionNewService()}
                {step == 5 && PaxSelection()}
                {step == 6 && PaxSelectionNewServiceTransfer()}
                {step == 7 && PaxSelectionNewServiceExcursion()}
                {isOpen ? getPopupContent():<></>}
               
            </>
            
    );
}

export default AddServiceBox;

