import React, { Fragment, useEffect, useRef, useState } from 'react'
import CancellationPolicyItem from './CancellationPolicyItem'
import useOutsideClick from '../../../hooks/useOutsideClick';

function CancellationPolicyPopup({onValidate=undefined,show=false,setShow,cancellationData}) {
    // const [checked,setChecked]=useState(false);
    const [showMessage,setShowMessage]=useState(false);
    const popupRef=useRef(null);
    const checkboxRef=useRef(null);

    const resetPopup=()=>{
        setShow(false);
        setShowMessage(false);
    }

    const handleValidate=()=>{
        if (checkboxRef.current && checkboxRef.current.checked) {
            onValidate&&onValidate();
            resetPopup()
        }else{
            setShowMessage(true);
        }
    }
    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [show])

    
    useOutsideClick(popupRef,()=>{
        resetPopup();
    })
    return (
        <>
        {show?
        <div className='deletepopup_container'>
            <div className='cancellation-popup' ref={popupRef}>
                <h6 className='cancellation-popup-title'>Cancellation policy</h6>
                <div className='cancellation-popup-container'>
                    <div className='concellation-item-container'>
                    {
                        cancellationData?.map((data,ind)=>(
                            <Fragment key={ind+" "+data.ValidFrom}>
                                <CancellationPolicyItem rooms={data.Rooms} dateFrom={data.ValidFrom} dateTo={data.ValidTo} description={data.Description}/>
                                <i className='icon-dashed-line'></i>
                            </Fragment>
                        ))
                    }
                    </div>
                </div>
                <div className='cancellation-validation'>
                    <div className='input-row-cancellation'>
                        <label className='label-acceptance' htmlFor="validation-cancellation">I hereby acknowledge and agree to the above conditions & policies</label>
                        <input type='checkbox' id='validation-cancellation' ref={checkboxRef}/>
                    </div>
                    <button className='validate-button' onClick={handleValidate}>Validate</button>
                </div>
                {showMessage&&<p className='cancellation-error-message'>
                    Please confirm and agree the Cancellation policy before pursuing adding your room in your quotation
                </p>}
            </div>
        </div>
        :
        null}
    </>
    )
}

export default CancellationPolicyPopup
