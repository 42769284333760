import React,{useState,useRef} from 'react';
import {SHOWFILTER} from '../../constants';
import useOutsideClick from '../../hooks/useOutsideClick.js';
import {updateAccomlist} from "../../store/accomodationReducer.js"
import {useDispatch } from 'react-redux';


function ShowFilters({hotelList}) {

    const [open, setOpen] = useState(false);
    const btnref = useRef(null);
    const dispatch = useDispatch()

    useOutsideClick(btnref, () => {
        setOpen(false);
    });

    const selectedFilter =(e,list)=>{
        e.preventDefault();
        let {name}=list;
       // Function to handle sorting from highest to lowest
       if(name ==="Highest to Lowest"){
            let sortedData = [...hotelList].sort((a, b) => b.price - a.price);
            let payload=  { lists: sortedData };
            dispatch(updateAccomlist(payload));
       }
       else if(name==="Lowest to Highest") {
            let sortedData = [...hotelList].sort((a, b) => a.price - b.price);
            let payload=  { lists: sortedData };
            dispatch(updateAccomlist(payload));
       }
       setOpen(false);
        
    }



    return (
        <div className='showfilter-parent' ref={btnref}>
            <div className={`${open ? 'active' : ''} filter-box`} onClick={()=>{setOpen(!open)}} >Other filters</div>
            { open && <div className='filter-dropdown' >
                {SHOWFILTER.map((list, ind) => {
                    if (ind === SHOWFILTER.length - 1) {
                        return null;
                    }
                    return (
                        <div key={ind} className={"filter-list "+(list.disable ?"filDisable":"" )}   onClick={(e) => { selectedFilter(e, list); }}>
                            {list.name}
                        </div>
                    );
                })}
                <hr className='dashed-hr'/>
                <label className="labelCheckBox filDisable">
                    <input type="checkbox" className='checkunvailable' />
                    {SHOWFILTER[SHOWFILTER.length - 1].name}
                </label>
                </div>}
        </div>
    );
}

export default ShowFilters;