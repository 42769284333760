 const SearchAgency = ({ value, onChange, onClear }) => {
    
    return(

      <div className="search-agency-parent">
      <input
        type="text"
        placeholder="Select an Agency"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="search-input"
      />
      {value && (
        <button
          onClick={onClear}
          style={{
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
            fontSize: '16px',
            color: '#999',
          }}
        >
          &#x2715;
        </button>
      )}
    </div>
    )
  
 }

  export default SearchAgency