import { ISO_8601_DATE_FORMAT } from "../constants";
import { buildEmptyRoom, buildEmptyRoomsPattern, getDateFromString, isServiceAccomodation, isServiceTransfer } from "../utility/globalFunc";

const useDocket=()=>{

    function getAccomServiceGroup(date, hotelId,reservationAccom) {
        let i = 0;
        for (const accom of reservationAccom) {
          if (JSON.stringify(accom.Dates) === JSON.stringify(date) && accom.HotelId === hotelId) {
            return [accom, i];
          }
          i++;
        }
        return [null, i];
    }

    const buildServiceAccom = (pendingRooms,reservationAccom) => {     
      // if (!reservationAccom||reservationAccom.length==0) {
      //   return [];
      // }
        let { requiredNbRooms, date, hotelName, hotelId } = pendingRooms;
        let accoms = [...reservationAccom];
        
        let [accomGroup, indGroup] = getAccomServiceGroup(date, hotelId,reservationAccom);
        let existingNbRooms=(accomGroup ? accomGroup.Rooms.length : 0);
        
        if (requiredNbRooms > 0 && requiredNbRooms>existingNbRooms) {
    
          let emptyRoomsRemaining = requiredNbRooms - existingNbRooms;
          // console.log({required:requiredNbRooms,currRooms:existingNbRooms,emptyRoomsRemaining});
          
          let emptyRooms = new Array(emptyRoomsRemaining);
          let defaultRooms = buildEmptyRoom(undefined, date, hotelName);
          emptyRooms.fill(defaultRooms);
    
          if (!accomGroup) {
            let groupedAccom = {
              "HotelName": defaultRooms.hotelName,
              "HotelId": defaultRooms.hotelId,
              "HotelAmenities": [],
              "Dates": defaultRooms.date,
              "Total": 0,
              "Currency": defaultRooms.currency,
              "Rooms": emptyRooms,
            };
            accoms = [...accoms, groupedAccom];
          } else if(requiredNbRooms>accomGroup.Rooms.length) {
            let rooms=[...accoms[indGroup].Rooms];
            let {roomsFilled}=pendingRooms;
            let nbFilled=roomsFilled.reduce((prev,curr)=>(prev+(curr?1:0)),0);
            
            let previousRooms=rooms.slice(0,rooms.length-nbFilled);
            let newFilledRooms=rooms.slice(rooms.length-nbFilled);
    
            let emptysRoom=buildEmptyRoomsPattern(roomsFilled,newFilledRooms,defaultRooms);
            
            accoms[indGroup] = {
              ...accoms[indGroup],
              Rooms: [...previousRooms,...emptysRoom],
            };
          }
        }
        return accoms;
    }

    function getServiceDate(service) {
        if (isServiceAccomodation(service)) {
            return service.Dates?service.Dates[0]:null;
        }
        if (isServiceTransfer(service)) {
            return service.TransferDate||null;
        }
        return null;
      }


    function getServiceDateParsed(service) {
        let date=getServiceDate(service);
        if (null) {
            return null;
        }
        return getDateFromString(date);
    }

    

    const getTimeline=({accomodations=[],transfers=[],excursions=[]})=>{
        let mergedServices=[...accomodations,...transfers,...excursions];
        mergedServices.sort((a,b)=>{
            let dateA=getServiceDateParsed(a);
            let dateB=getServiceDateParsed(b);
            return new Date(dateA)-new Date(dateB);
        })
        
        return mergedServices;
    }

    return {getAccomServiceGroup,buildServiceAccom,getTimeline};
}

export default useDocket;