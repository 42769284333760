import React, { useState, useEffect, useRef } from "react";
import locationImg from "../../Assets/images/location-pin5.png";
import gray_airplane from "../../Assets/images/gray-plane.png"
import HotelList from "./minicomponents/HotelList.js";
import useGetHotels from "../../hooks/useGetHotels.js";
import { SelectedLocation } from "../Transfer/SaveCotation";
import useOutsideClick from "../../hooks/useOutsideClick.js";
import { capitalizeFirstLetters } from "../../utility/globalFunc.js";
import { AIRPORT_LOCATION_NAME } from "../../constants.js";

function LocationPoint({inpText,label,points,passArray1,passArray2,handleValues,allHotel,searchHotelClassName='selLabel'}) {

  const [popupOpen, setPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [optionData, setoptionData] = useState([]); 
  const [optionRecords, setoptionRecords] = useState([]);
  const inpref = useRef(null);
  const btnref = useRef(null);
  const listRef = useRef(null);
  const {hotelData,loading:getload, fetchData} = useGetHotels();
  const [resultLabel,setResultLabel]=useState("Popular research");

  useEffect(() => { popupOpen && inpref.current.focus(); }, [popupOpen]);
  useEffect(() => { hotelData && (setoptionData(hotelData), setoptionRecords(hotelData)); }, [hotelData]);
  useOutsideClick(btnref, () => {
    setPopupOpen(false);
    setsearchMode(false);
  })

  const handleInpChange=()=>{
    if(inpref.current && inpref.current.value!==''){
      setResultLabel("Results")
    }else{
      setResultLabel("Popular search")
    }
  };

  const handleDropdown = (e, value) => {
    e.preventDefault();
    inpref.current.value = "";
    if (value === "element") {
      setPopupOpen(prevState => !prevState);
      setLoading(false);
      if (passArray1 === "" || !hotelData) {
        fetchData();
      }
    }
  };
  
  const optionValue = (e,data) => { 
    e.preventDefault();
    setPopupOpen(false);
    
    handleValues("pickup",data)
  };

  const filterData = (e) => {
    e.preventDefault();
    setLoading(true);
    
    setTimeout(() => {
      if(e.target.value.length >= 1){
        setLoading(false)
        setoptionRecords(optionData.filter((data) => data.Hotel && data.Hotel.toLowerCase().includes(e.target.value.toLowerCase())) );
      }
      else if(e.target.value.length === 0){
        setLoading(false)
        !hotelData && fetchData();
      }
    }, 1500);
  };
  const searchInputRef = useRef(null);
  const removeSelection =(e)=>{
    e.preventDefault();
    setsearchMode(true);
    // if (inpref.current) {
    //   alert('here')
    //   inpref.current.focus();
    // }

    let clearData = {loc_name:"",loc_det:"",loc_id:"all"}
    handleValues("clear",clearData)
    handleInpChange();
    // !hotelData && fetchData();
    fetchData();
  }

  function handleClick(){
    setsearchMode(prevState =>!prevState);
  }

  useEffect(()=>{
    const listScroll=listRef.current;
    if (popupOpen) {
      setTimeout(() => {
        listScroll.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 200);
    }
  },[popupOpen])



  const [searchMode, setsearchMode] = useState(false);

  
  return (
    <div className="select-container" ref={btnref}>
      <>
        <div id="containerClick" onClick={() => setsearchMode(true)} onBlur={() => setsearchMode(false)} >
              <div className={"selectbox1 " + (searchMode ? "hide" : "add")} onClick={(e) => handleDropdown(e, "element")}>
                <div id="select_deladd" className={"selectbox1cnt_sub " + (passArray1 !== "" ? "selectedLocation" : "")}>
              <div className={passArray1 !== "" ? searchHotelClassName : "no_selLabel"}>{passArray1 === "" ? label[0] : capitalizeFirstLetters(passArray1)}</div>
                  <div className="no_details">{passArray2 === "" ? label[1] : passArray2}</div>
                </div>
              </div>
          <input className={ searchMode ? "locInput":"hide"} placeholder={inpText} ref={inpref} onChange={(e) => { filterData(e); handleInpChange() }} />
        </div>
      </>
      {/*popup */}
      {popupOpen && 
        <div  className={"selectbox-dropdown choice-hotel-list-container "+(passArray1 !== "" ? "autoHeight":"")}  ref={listRef}>
          <div className="show">
            {
              passArray1 !== "" && passArray1 !== AIRPORT_LOCATION_NAME &&
              <SelectedLocation seldata={passArray1} removefunc={removeSelection} />
            }
            <div style={{marginBottom:"10px"}}className="optionBoxDta default" onClick={(e) => {optionValue(e,{loc_name:"All hotels",loc_det:"Find a hotel",loc_id: "all"})}}>
              <><img src={allHotel ? locationImg: gray_airplane}  alt="Airplane img"/></>
              <div className="placeName">
                  <span>{allHotel ? "All hotels":AIRPORT_LOCATION_NAME}</span>
                  <div className="placeDetails">{allHotel ? "View all of our hotels":"No information available"}</div>
              </div>       
            </div>
            <hr></hr>
            <div className="popsearch">{resultLabel}</div>
            {/* <div className={passArray1 === "" ?"popsearch":"hide"}>{resultLabel}</div> */}
          </div>
         {!loading ? 
            <div onClick={()=>setsearchMode(false)}>
              <HotelList list={optionRecords} value={passArray1} points={points} optionValue={optionValue} />
            </div> 
            :<div className="hoteldrop-loading"></div>}
        </div>
      }
    </div>
  );
}

export default LocationPoint;