import React from 'react'

function B2BSkeleton({imgUrl,style={},className=""}) {
  return (
    <div className={`skeleton-container ${className}`} style={style}>
      <img src={imgUrl} alt="skeleton load"/>
    </div>
  )
}

export default B2BSkeleton
