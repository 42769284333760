import React from 'react';
import {useDispatch } from 'react-redux';
import {removeErrorMessages} from "../../store/errorReducer.js"

function MessagesPopup({data}) {
    const dispatch = useDispatch()
    const closePopup =(e)=>{
        e.preventDefault();
        dispatch(removeErrorMessages())
    }
    return (
        <div className='popups_parent'>
            <div className='popupBox'>
                <div><b>Message:</b> {data}</div>
                <button onClick={(e)=>{closePopup(e)}}>close</button>
            </div>
        </div>
    );
}

export default MessagesPopup;