import React, { Fragment, useEffect, useState } from 'react';
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import {calculateNights,formatDate,dateToConventionalFormat,transformDate, properCase} from "../../utility/globalFunc.js"
import { pluralize } from '../../constants.js';



function TimelineView({list={}}) {

    const [isShow,setIsShow] = useState(false)
    const [firstShown,setFirstShow] = useState({})
    const [secondHide,setSecondHide] = useState({})
    const [serviceCounteur,setServiceCounter] =useState({"counteur":0,
                                                        "reservationAccom":0,
                                                        "ReservationExcursion":0,
                                                        "reservationTransfer":0})

    useEffect(()=>{
         let {counteur,reservationAccom,ReservationExcursion,reservationTransfer} = countService(list)
         if(counteur >=2){
            if(reservationTransfer > 0){
                let tempTransfert = {reservationTransfer:[list.reservationTransfer[0]] }
                setFirstShow(tempTransfert)
                if(reservationAccom > 0){
                    let tempAccom = {reservationAccom:[list.reservationAccom[0]] }
                    setSecondHide(tempAccom)
                }
                if(reservationTransfer>=2){
                let tempTransfert = {reservationTransfer:[list.reservationTransfer[1]] }
                setSecondHide(tempTransfert)
                }
            }
            else if(reservationAccom >= 2){
                let tempAccomfirst = {reservationAccom:[list.reservationAccom[0]] }
                let tempAccomSecond = {reservationAccom:[list.reservationAccom[1]] }
                setFirstShow(tempAccomfirst)
                setSecondHide(tempAccomSecond)
            }
         }

        
      },[])

    let accomNum=0;
    const filterTeens = (ageData,name) => {
        return ageData.flatMap(room => 
            room.paxAge.filter(pax => pax[name] !== undefined)
        );
    };
    //   list = list.sort((a,b)=>{
    //     const aLatestDate = parseDateString(a.date[a.date.length - 1]);
    //     const bLatestDate = parseDateString(b.date[b.date.length - 1]);
    //     return bLatestDate - aLatestDate;
    //   }) 

      const getAccomNum=()=>{
        accomNum++;
        return accomNum;
      }

      const countService = (list) =>{
        let counteur = 0;
        let counterAccom = 0;
        let counterExcursion = 0;
        let counterTransfert = 0;
        if(list.hasOwnProperty("reservationAccom")) counterAccom = list.reservationAccom.length ,  counteur += counterAccom 
        // console.log("counteurAccom",counteur);
        if(list.hasOwnProperty("ReservationExcursion")) { counterExcursion = list.ReservationExcursion.length , counteur += counterExcursion } 
        // console.log("counteurExcurion",counteur);
        if(list.hasOwnProperty("reservationTransfer")) counterTransfert = list.reservationTransfer.length , counteur += counterTransfert 
        // console.log("counteurTransfer",counteur,list.reservationTransfer);
        
        const constData =  {"counteur":counteur,"reservationAccom":counterAccom,"ReservationExcursion":counterExcursion,"reservationTransfer":counterTransfert}
        setServiceCounter(constData)
        
        return constData;
      }



    
    const departure = () =>{
        return(
            <TimelineItem>
                <TimelineSeparator>
                    <div className='arrival-timeline arrival-timeline-v1'></div>
                    <TimelineConnector style={{background:"rgba(15, 113, 115, 0.30)",borderBottomLeftRadius:"3px",borderBottomRightRadius:"3px"}}/>
                </TimelineSeparator>
                <TimelineContent >
                    <div style={{padding:"0px 0 0 16px",fontSize:"15px"}}>Arrival</div>
                    <div style={{padding:"4px 0 0 16px",color:"#797979",marginBottom:"75px"}}>Sir Seewoosagur Ramgoolam International Airport</div>
                </TimelineContent>
          </TimelineItem>
        )
    }
    const arrival = () =>{
        return(
            <TimelineItem>
            <TimelineSeparator>
                <div className='departure-timeline'></div>
            </TimelineSeparator>
            <TimelineContent>
                <div style={{padding:"0px 0 0 16px",marginTop:"-4px"}}>Departure</div>
                <div style={{padding:"4px 0 0 16px"}}>Sir Seewoosagur Ramgoolam International Airport</div>
            </TimelineContent>
          </TimelineItem>
        )
    }

    const accomReservationTimeLine = (list) =>{  
        return(
            <>
           
            {list?.reservationAccom?.map((reservationAccomData,indexOne)=>{
                return(
                  
                    <TimelineItem style={{minHeight:"82px"}} key={'accom'+indexOne} >
                        <TimelineSeparator>
                            <div className={list.hasOwnProperty("reservationAccom") ? "accomIcon":"transferIcon"}></div>
                            <TimelineConnector style={{background:"rgba(15, 113, 115, 0.30)",borderBottomLeftRadius:"3px",borderBottomRightRadius:"3px"}}/>
                            </TimelineSeparator>
                        <TimelineContent>
                    <div className='sel-excursion-map' style={{marginTop:"8px",marginBottom:  "52px"   }} key={'accomReservation'+indexOne}>
                            <div className='quotation_tour_name'  style={{paddingLeft:"16px",color:"#0F7173"}}>Accommodation </div>
                        <div style={{paddingLeft:"16px"}}>
                        <div  className='quotation_tour_name' style={{marginTop:"17px"}}>{reservationAccomData.HotelName}</div>
                        {
                            reservationAccomData.Rooms.map((reservationAccomDataDetails,indexReser)=>{
                            
                            return indexReser == 0 &&(
                                <Fragment key={indexReser+"reaccom"}>
                                    <div className='quotation_tour_date excursion-map dates'> <span className='quotation_tour_date_first-date'>{transformDate(reservationAccomDataDetails.Dates[0]) }</span><span className='quotation_tour_date_arrow'>&rarr;</span><span className='quotation_tour_date_second-date'>{transformDate(reservationAccomDataDetails.Dates[1])}</span> </div>
                                    <div  className='excursion-map nights-docket'>{calculateNights(reservationAccomDataDetails.Dates[0],reservationAccomDataDetails.Dates[1])} nights</div> 
                                </Fragment>
                            )
                            })
                        }
                        {list.length > 1 && <div className='excursion-map'>Room {getAccomNum()}</div>}
                            {reservationAccomData.Rooms.map((reservationAccomDataRoom,indexTwo)=>{
                                return(
                                    <Fragment key={indexTwo+"room"}>
                                        <div className='excursion-map room-number-icon' style={{marginTop:"28px"}}>{'Room '+(indexTwo+1)}</div>
                                        <div className='excursion-map'><b>Client type</b> | {'Standard'}</div>
                                        <div className='excursion-map person-icon' style={{marginTop:'18px'}}>{reservationAccomDataRoom.AdultCount} { pluralize(reservationAccomDataRoom.AdultCount," Adult"," Adults") } </div>
                                        {reservationAccomDataRoom.TeenCount > 0 && <div className='excursion-map filter-teen person-icon' style={{ marginTop: '13px' }}>{reservationAccomDataRoom.TeenCount } {pluralize(reservationAccomDataRoom.TeenCount," Teen","Teens")}</div>}
                                        {reservationAccomDataRoom.ChildCount > 0 && <div className='excursion-map filter-teen person-icon' style={{ marginTop: '13px' }}>{reservationAccomDataRoom.ChildCount } {pluralize(reservationAccomDataRoom.ChildCount," Child","Children")}</div>}
                                        {reservationAccomDataRoom.InfantCount > 0 && <div className='excursion-map filter-teen person-icon' style={{ marginTop: '13px' }}>{reservationAccomDataRoom.InfantCount } {pluralize(reservationAccomDataRoom.InfantCount," Infant","Infants")}</div>}
                                        <div className='excursion-map room-type-icon' style={{marginTop:"10px"}}>{reservationAccomDataRoom.RoomName}</div>
                                        <div className='excursion-map meal-plan-icon'><b>Meal Plan</b> | {reservationAccomDataRoom.MealPlan}</div>
                                    </Fragment>
                                    )
                            })}
                        </div>
                    </div> 
                    </TimelineContent>
                    </TimelineItem>
                )
            })}
        </> 
        )
    }
    const accomReservationTimeLineShowMore = (list,counter) =>{
        return(
            <div className='sel-excursion-map gradient'>
            {list.reservationAccom?.map((reservationAccomData,indexOne)=>{
                return(
                    <TimelineItem style={{minHeight:"82px"}} key={'accom'+indexOne} >
                        <TimelineSeparator >
                            <div className={list.hasOwnProperty("reservationAccom") ? "accomIcon":"transferIcon"}></div>
                            <TimelineConnector style={{background:"rgba(15, 113, 115, 0.30)",borderBottomLeftRadius:"3px",borderBottomRightRadius:"3px"}}/>
                            </TimelineSeparator>
                        <TimelineContent >
                    <div className='sel-excursion-map ' style={{marginTop:"8px",marginBottom:  "52px"   }} key={'accomReservation'+indexOne}>
                            <div className='quotation_tour_name'  style={{paddingLeft:"16px",color:"#0F7173"}}>Accommodation </div>
                        <div style={{paddingLeft:"16px"}}>
                        <div  className='quotation_tour_name' style={{marginTop:"17px"}}>{reservationAccomData.HotelName}</div>
                        <div  className='quotation_tour_date excursion-map dates'> 
                                <span className='quotation_tour_date_first-date'>{transformDate(reservationAccomData?.Rooms[0]?.Dates[0]) }</span>
                                <span className='quotation_tour_date_arrow'>&rarr;</span>
                                <span className='quotation_tour_date_second-date'>{transformDate(reservationAccomData?.Rooms[0]?.Dates[1])}</span>
                        </div>
                        </div>
                        <div className='sel-excursion-map-show-more'>
                            <button className='show-more' onClick={()=>{setIsShow(true)}}><span>Show more</span></button>
                            <span className='show-more-text'>({counter} items more)</span>
                        </div>
                    </div> 
                    </TimelineContent>
                </TimelineItem>
                )
            })}
        </div> 
        )
    }

    const excursionReservationTimeline = (list) =>{
        return (
            <>
                {list.ReservationExcursion.map((ReservationExcursiondata, ReservationExcursionindex)=>{
                    return (
                    <div className='sel-excursion-map' key={"excursion"+ReservationExcursionindex}>
                    <div className='module-name'>Excursion</div>
                    {/* <div className='quotation_tour_name excursion-map '>{list.excursion_name}</div>
                    <div className='quotation_tour_date excursion-map'>{list.date}</div>
                    <div className='quotation_tour_support excursion-map'><span>Suported : </span>{capitalizeFirstLetter(list.location)}</div>
                    <div style={{marginTop:"30px"}}><Accessibility list={list.list} islang={true} isacces={true}/></div>  */}
                    </div> 
                    )
                })}
            </>
        )
    }

    const transfertReservationTimeLine = (list) =>{
        return(
            <>
                {list.reservationTransfer.map((reservationTransfer, ReservationTransferindex)=>{
                        return(
                            <TimelineItem style={{minHeight:"82px",marginBottom:'10px'}} key={'transferTimeLine'+ReservationTransferindex} >
                                <TimelineSeparator>
                                <div className={"transferIcon"}></div>
                                <TimelineConnector style={{background:"rgba(15, 113, 115, 0.30)",borderBottomLeftRadius:"3px",borderBottomRightRadius:"3px"}}/>
                                </TimelineSeparator>
                                    <TimelineContent style={{marginBottom:'51px'}}>
                                        <Fragment>
                                    <div className="trans_date" style={{marginTop:"5px"}}>
                                        <div className="quotation-map-child" style={{paddingLeft:"16px",color:"#0F7173"}}>
                                            Transfer
                                        </div>
                                        <div className="quotation-map-child" style={{paddingLeft:"37px",color:"#0F7173",marginTop:"4px"}}>
                                            <span>{reservationTransfer.TransferDate}</span>
                                        </div>
                                    </div>
                                    <div className="trans_points" style={{paddingLeft:"16px",color:"#0F7173"}}>
                                    <div className="quotation-map-child">
                                        <span>{reservationTransfer.LocationFromName}</span>
                                    </div>
                                    <div className="quotation-map-child"></div>
                                    <div className="quotation-map-child">
                                        <span>{reservationTransfer.LocationToName}</span>
                                    </div>
                                    </div>
                                    </Fragment>
                                    <div className="trans_per_car" style={{paddingLeft:"16px"}}>
                                    <div className='trans_points_pax' >
                                        <div className='person-icon'>{reservationTransfer.AdultCount} { pluralize(reservationTransfer.AdultCount," Adult"," Adults") } </div> 
                                          <div className='group' style={{marginTop:"5px",marginBottom:"5px"}}>
                                            {reservationTransfer?.TeenCount > 0 && <div className='person-icon'>{reservationTransfer.TeenCount} { pluralize(reservationTransfer.TeenCount," Teen"," Teens") } </div>}
                                            {reservationTransfer?.ChildCount > 0 && <div className='person-icon'>{reservationTransfer.ChildCount} { pluralize(reservationTransfer.ChildCount," Child"," Children") } </div>}
                                            {reservationTransfer?.InfantCount > 0 && <div className='person-icon'>{reservationTransfer.InfantCount} { pluralize(reservationTransfer.InfantCount," Infant"," Infants") } </div>}
                                        </div>
                                        <div className="quationcar_img">
                                                    {properCase(reservationTransfer.VehicleTypeName) + " x"+ reservationTransfer.CarCount } 
                                        </div>
                                    </div>
                            
                                        { ( reservationTransfer?.LuggageTruck > 0 || reservationTransfer?.LuggageCar > 0  || reservationTransfer?.baby_seat_count > 0 ) && <div className="trans_points_supplement" style={ { marginTop: "19px"}  }>
                                            <b>Supplement :</b>
                                            {reservationTransfer.LuggageTruck > 0 && <div className='luggage_truck'><b>Luggage Truck {reservationTransfer.LuggageTruck > 1 ? `x${reservationTransfer.LuggageTruck}` : ''} </b> </div>}
                                           {reservationTransfer.LuggageCar > 0 && <div className='luggage_car'><b>Luggage Car {reservationTransfer.LuggageCar > 1 ? `x${reservationTransfer.LuggageCar}` : ''} </b> </div>}
                                           {reservationTransfer.baby_seat_count > 0 && <div className='baby-seat'><b>Baby seat {reservationTransfer.baby_seat_count > 1 ? `x${reservationTransfer.baby_seat_count}` : ''} </b></div>}
                                        </div> }

                                    </div>
                                
                                </TimelineContent>
                        </TimelineItem>
                        )
                    })}
            </>
        )
    }
    const transfertReservationTimeLineShowMore = (list,counter) =>{
        return(
            <>
                {list.reservationTransfer.map((reservationTransfer, ReservationTransferindex)=>{
                        return(
                            <TimelineItem style={{minHeight:"82px"}} key={'transferTimeLine'+ReservationTransferindex} >
                                <TimelineSeparator>
                                <div className={"transferIcon"}></div>
                                <TimelineConnector style={{background:"rgba(15, 113, 115, 0.30)",borderBottomLeftRadius:"3px",borderBottomRightRadius:"3px"}}/>
                                </TimelineSeparator>
                                    <TimelineContent style={{marginBottom:'51px'}}>
                                        <Fragment>
                                    <div className="trans_date" style={{marginTop:"5px"}}>
                                        <div className="quotation-map-child" style={{paddingLeft:"16px",color:"#0F7173"}}>
                                            Transfer
                                        </div>
                                        <div className="quotation-map-child" style={{paddingLeft:"37px",color:"#0F7173",marginTop:"4px"}}>
                                            <span>{reservationTransfer.TransferDate}</span>
                                        </div>
                                    </div>
                                    <div className="trans_points" style={{paddingLeft:"16px",color:"#0F7173"}}>
                                    <div className="quotation-map-child">
                                        <span>{reservationTransfer.LocationFromName}</span>
                                    </div>
                                        <div className='trans_points-show-more'>
                                            <button className='show-more' onClick={()=>{setIsShow(true)}}><span>Show more</span></button>
                                            <span className='show-more-text'>({counter} items more)</span>
                                    </div>
                                    </div>
                                    </Fragment>
                                
                                </TimelineContent>
                        </TimelineItem>
                        )
                    })}
            </>
        )
    }

    return (
        <div>
            {
                serviceCounteur.counteur  >=2 && !isShow ? (
                    <Timeline>
                        {departure()}
                        {serviceCounteur.reservationTransfer > 0 ? transfertReservationTimeLine(firstShown) : accomReservationTimeLine(firstShown)}
                        {serviceCounteur.reservationTransfer >= 2 ? transfertReservationTimeLineShowMore(secondHide,serviceCounteur.counteur) :accomReservationTimeLineShowMore(secondHide,serviceCounteur.counteur)}
                    </Timeline>
                ) : (
                <Timeline>
                    {departure()}
                    <>
                        {list.hasOwnProperty("reservationAccom") ? accomReservationTimeLine(list)  : "" }
                        {list.hasOwnProperty("reservationTransfer") ? transfertReservationTimeLine(list) : ""}
                        {list.hasOwnProperty("ReservationExcursion") ? excursionReservationTimeline(list) : ''} 
                    
                    </>
                    {arrival()}
                    {serviceCounteur.counteur >= 2 && ( 
                    <div className='show-less-container' style={{marginBottom: serviceCounteur.reservationTransfer >=2 ? '30px' :'30px' }}>
                        <button className='show-less' onClick={()=>{setIsShow(false)}}><span>Show less</span></button>
                    </div> )
                    }               
                </Timeline>
                 )
            }
        </div>
    );
}

export default TimelineView;