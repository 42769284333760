import React from 'react';
import {  AccomTableV1, AccomTableV2, TransferTable } from './Atom.js';
import { formatPrice } from '../../utility/globalFunc.js';
import { useSelector } from 'react-redux';
import { MORE_OPTIONS_MODE, ROOMPAXDATA } from '../../constants.js';


function TableLayoutNew({lists=[],marginTop="0px"}) {

    // const totalPrice = lists.reduce((total, curVal) => {
    //     return total + curVal.price;
    // }, 0);
    const { actionMode } = useSelector((state) => state.globalreducer)

    function calculateTotalSum(data) {
      let totalSum = 0;
      let currency = null;
    
      // Sum reservationAccom total
      if (data.reservationAccom && data.reservationAccom.length > 0) {
        data.reservationAccom.forEach(accom => {
          totalSum += parseFloat(accom.Total) || 0;
          if (!currency && accom.Currency) {
            currency = accom.Currency;
          }
        });
      }
    
      // Sum ReservationTransfer total if it exists
      if (data.reservationTransfer && data.reservationTransfer.length > 0) {
        data.reservationTransfer.forEach(transfer => {
          totalSum += parseFloat(transfer?.TotalPrice) || 0;
        });
      }
    
      // Sum ReservationExcursion total if it exists
      if (data.ReservationExcursion && data.ReservationExcursion.length > 0) {
        data.ReservationExcursion.forEach(excursion => {
          totalSum += parseFloat(excursion.Total) || 0;
        });
      }
    
      return {
        total: totalSum.toFixed(2),
        currency: currency
      };
    }

      function getCurrency(data) {
        for (let item of data) {
          if (item && item.reservationAccom && item.reservationAccom.length > 0) {
            return item.reservationAccom[0].Currency;
          }
        }
        return null; // Return null if no currency is found
      }

    return (
        <div className='table-layout-parent' style={{marginTop:marginTop}}>
            <div className='grid-layout head'>
                <div className='item1'>Item</div>
                <div>Details</div>
                <div>Dates</div>
                <div>Number of Pax</div>
                <div>Price</div>
            </div>
            <div className='quotation-details-container'>
                {
                  <div className="grid-layout body body-scroll" >
                      {lists.hasOwnProperty("reservationAccom")  && <AccomTableV2 list={lists} isDelete={actionMode === MORE_OPTIONS_MODE.REMOVE_ITEM}/>}
                      {lists.hasOwnProperty("reservationTransfer")  && <TransferTable isDelete={actionMode === MORE_OPTIONS_MODE.REMOVE_ITEM} list={lists}/>}
                  </div>

                }
            </div>
           <div className='dashed-total'>
                <div>Total: 
                &nbsp; {calculateTotalSum(lists).currency}
                &nbsp; {formatPrice(calculateTotalSum(lists).total,',')}&nbsp;
                     </div>
           </div>
        </div>
    );
}

export default TableLayoutNew;