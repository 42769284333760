import React,{useState,useEffect} from 'react';
import Dropdowns from '../components/Transfer/Dropdowns.js';
import TransferInputs from '../components/Transfer/TransferInputs.js';
import {DROPDOWN_DATA,MAUTOURCO_ID} from "../constants";
import usePopupData from '../hooks/usePopupData.js';
import { useSelector } from 'react-redux';
import NavbarBlock from '../components/Transfer/NavbarBlock.js';


function TransferPage(props) {
    const { isOpen, openPopup, closePopup, getPopupContent } = usePopupData();
    const {transferSearch} = useSelector((state) => state.errorreducer.activePopup)
    const {messages} = useSelector((state) => state.errorreducer)
    const {subAgencyAdded} =useSelector((state) => state.authpath); 
    const {agency_name,first_name,last_name,agency_id} =useSelector((state) => state.authpath.userData); 

    const [deleteItem, setdeleteItem] = useState({delData:false,delHover:false,delpopup:false,rowdatas:{}});
    const { loading} = useSelector((state) => state.transfer_Global);

    let gridStyle = { "gridTemplateColumns": "226px 265px 372px 61px 362px", "display": "grid", "position": "relative", }
    const issubAgency = Object.values(subAgencyAdded).length > 0;

    return (
       <>
        {!loading ?
        (
        <div>
            <NavbarBlock/>
            <div className={`transferPage_parent maubackground ${agency_id === MAUTOURCO_ID && !issubAgency ? "disable" : ""}`}>                <div className="headText">
                    <div>Discover, feel, live <span>Mauritius.</span></div>
                </div>
                <div className='transferPage_container '>
                    <div style={{width:"1398px"}}> 
                        <Dropdowns dropData={DROPDOWN_DATA} imgcount={2} name="Transfer"/> 
                        <>
                            <TransferInputs gridStyle={gridStyle} delState={deleteItem.delData} dellAll ={deleteItem} setdeleteItem={setdeleteItem} scroll={true}/>
                        </>
                    </div>
                </div> 
            </div>
            {/* {isOpen ? getPopupContent():<></>} */}
        </div>
            ) : (
                <div className='transferSkeletonLoad1'></div>
            )
        }
      </>
    );

}

export default TransferPage;