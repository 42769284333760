import React, { useState } from "react";
import ThreeDottedImg from "../../Assets/images/global-icon/three-dotted.svg";
import ThreeDottedImgGreen from "../../Assets/images/global-icon/three-dotted-green.svg";
import { QuotationActionDropdown } from "./small_dropdowns";

export function MoreOptionsThreeDotted({ quotation }) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="three-dotted-container"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={isHovered ? ThreeDottedImgGreen : ThreeDottedImg}
        alt="More options"
        className="three-dotted"
        onClick={() => setPopupOpen((prev) => !prev)}
      />
      {/* {popupOpen && (
        <QuotationActionDropdown setPopupOpen={setPopupOpen} file_name={quotation.file_name} />
      )} */}
    </div>
  );
}
