import React from 'react'
import {CancellationInfo,GlobalAmenities} from "../../MiniComponents/Atom"
import { ImageLayout } from './Atom'
import HotelRating from "../../MiniComponents/HotelRating"
import{HOTEL_RATING_ACCOM} from "../../../constants"
import amenitiesStatic from "../../../Assets/images/accomodation/staticImages/amenitiesStatic.png"
import {RoomAmenitiesData} from "./RoomAmenitiesData"
import { separateurMillier } from '../../../utility/globalFunc'

const AccomQuoteDetail = ({data}) => {

  return (
    <div className="accom-quote-detail-parent" >
        <div className='accom-detail-block1'>
            <CancellationInfo serviceName="Accomodation" date={data.Rooms[0].Dates} location={data.HotelName}/>
            <div className='price'>{data.Currency} {separateurMillier(data.Total,',')}</div>
        </div>

        <div className='quote-items-display accom-detail-block2'>
            <ImageLayout imageList={data.Images || []}/>
            <div><p style={{marginTop:"26px"}}>Accommodation Details</p><div>{data.Description}</div></div>
        </div>

        <div  className='accom-detail-block3'>
            <GlobalAmenities amenities={data.HotelAmenities}/>  
            <HotelRating data={HOTEL_RATING_ACCOM}/> 
        </div>

        <>{data.Rooms.map((room,id)=>(<RoomAmenitiesData key={id} roomData={room}/>  ))} </>
    </div>
  )
}

export default AccomQuoteDetail