import { useEffect, useState } from 'react';
import { areElementsIntersecting, isElementPartiallyInViewport } from '../utility/globalFunc';

export function useElementsIntersection(ref1, ref2, callbackIntersect,checkInitialPoint=false) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [yPosition,setYPosition]=useState(0);
  const [scrollY,setScrollY]=useState(0);

  const handleScroll = () => {
    if (ref1.current && ref2.current) {
      let ref2InViewport = isElementPartiallyInViewport(ref2.current);
      let intersect = areElementsIntersecting(ref1.current, ref2.current);
      let newScrollY=ref1.current.getBoundingClientRect().top-window.scrollY
      setScrollY(newScrollY);
      if (!isIntersecting && intersect) {
        setIsIntersecting(intersect);
        setYPosition(newScrollY);
        callbackIntersect && callbackIntersect({ ref1, ref2 })
      } 
      else if ((!ref2InViewport && (isIntersecting || !intersect ))) {
        setIsIntersecting(false);
      }
    }
  }

  useEffect(()=>{
    if (checkInitialPoint&&scrollY>yPosition&&isIntersecting) {
      setIsIntersecting(false);
    }
    
  },[scrollY,yPosition])

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(()=>{
    const observer = new IntersectionObserver(([entry1]) => {
      const isVisible1 = entry1.isIntersecting;
      if (isVisible1 && ref2?.current) {
        const observer2 = new IntersectionObserver(([entry2]) => {
          if (entry2.isIntersecting) {
           handleScroll();
          }
        }, {
          root: null,
          threshold: 0,
        });

        observer2.observe(ref2.current);
      } else {
        setIsIntersecting(false);
      }
    }, {
      root: null,
      threshold: 0,
    });

    if (ref1.current) {
      observer.observe(ref1.current);
    }
    return ()=>{
      if (ref1.current) {
        observer.unobserve(ref1.current);
      } 
    }
  },[ref1.current,ref2.current])

  return isIntersecting;
}