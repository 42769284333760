import React,{useReducer, useRef} from 'react';
import usePopupData from '../../hooks/usePopupData';
import {clearTransfer} from "../../store/transferReducer.js";
import {clearexcursion} from "../../store/excursionReducer.js";
import {clearPax,clearAll} from "../../store/globalReducer.js";
import {clearAccom,clearOnGoBack} from "../../store/accomodationReducer.js";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import useOutsideClick from '../../hooks/useOutsideClick.js';
import useMoreOptionQuotation from '../../hooks/useMoreOptionQuotation.js';
import { useEffect } from 'react';
import { useState } from 'react';


function DialogueBox({closePopup,openNew,toPath}) {
    

    const { pendingRooms } = useSelector((state) => state.accomodationreducer)
    const { isOpen,getPopupContent,openPopup } = usePopupData();
    const { clearDocket } = useMoreOptionQuotation()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const btnref = useRef(null);
    const [isRoomFill, setisRoomFill] = useState(true);
    
    useEffect(() => {
        if (window.location.href.includes('/selected-accomodation')){
            pendingRooms.roomsFilled.map(rep => {
                if (!rep) {
                    setisRoomFill(false);
                }
            })
        }
    }, [pendingRooms])



    const openSavePop =(e)=>{
        e.preventDefault();
        closePopup();
        openNew(e,"info")  
    }

    const notSaveData=(e)=>{
        e.preventDefault();
        if(toPath === "/accomodation-select"){
            // dispatch(clearOnGoBack());
        }else{
            dispatch(clearAccom())
            dispatch(clearPax())
            dispatch(clearTransfer())
            dispatch(clearAll())

        }
        
        navigate(toPath)
        closePopup()
       // navigate($)
    }




 

    useOutsideClick(btnref, () => {
        closePopup();
    });

    return (
            <>

                <div className='dialogue-link' ref={btnref}>
                    <p style={{fontWeight:"700",fontSize:"18px"}}>Caution:</p> 
                    
                    {!isRoomFill ?
                        <p>Please select all the rooms in your hotel to continue</p>
                    :
                        <p>You are about to discard your quotation. <br/> Are you sure you want to exit without saving ?</p>
                    }
                    
                    <div style={{display:"flex",justifyContent:"space-between",gap:"20px",marginTop:"15px"}}>
                        <button  onClick={(e)=>notSaveData(e)} >Continue without saving</button> 
                    <button className={!isRoomFill?"disable":""} onClick={(e)=>openSavePop(e)}>Save and continue</button> 
                    </div>
                 
                </div>
                {isOpen ? getPopupContent():<></>}
               
            </>
            
    );
}

export default DialogueBox;

