import React from 'react';
import { useSelector } from 'react-redux';


function PriceTag({currency,price,paxData,cur}) {
    const {Adult,Teen,Infant,Child} = useSelector((state) => state.globalreducer.paxData)

    
    let totalpax = Adult+Teen+Infant+Child
    return (
        <div className='priceTag_parent'>
            <div>{cur ==="MUR" ?<span>&#8360;</span>:cur ==="EUR" ? <span>&euro;</span>:<span>&#x0024;</span>} {price}</div>
            {/* <div>
                {Adult === 1 ?    +Adult  +" Adult":Adult+" Adults"}<br/>
                {Teen === 1 ?", " +Teen   +" Teen": Teen > 1 ? ", "+Teen+" Teens":"" }
                {Child === 1 ?", "+Child  +" Child": Child > 1 ? ", "+Child+" Children":"" }
                {Infant === 1 ?", "+Infant+" Infant": Infant > 1 ? ", "+Infant+" Infants":"" }
            </div> */}

            <div>
                {Adult === 1 ?  Adult+" Adult":Adult+" Adults"}<br/>
                {Teen === 1 ?   Teen+" Teen": Teen > 1 ? Teen+" Teens":"" }
                {Child === 1 ?  Child+" Child": Child > 1 ? Child+" Children":"" }
                {Infant === 1 ? Infant+" Infant": Infant > 1 ? Infant+" Infants":"" }
            </div>
        </div>
    );
}

export default PriceTag;