import React, { useEffect, useRef, useState } from 'react';
import DEFAULT_ROOM_IMG from "../../Assets/images/default-img/room.jpg";

import demoImg from "../../Assets/images/dummyImg1.png"
import close from "../../Assets/images/close_cotation.png"
import {VIEWHEADLIST,addingTotalPax,capitalizeFirstLetter} from "../../constants.js"
import {calculateNights, formatPrice, getOfferSign,dateUi_format} from "../../utility/globalFunc.js"
import Occupancy from '../Accomodation/minicomponents/Occupancy.js';
import { ImageWithFallBack, NightStay, Supplement } from '../MiniComponents/Atom.js';

import { useSelector,useDispatch } from 'react-redux';
import useOutsideClick from '../../hooks/useOutsideClick.js';


function RoomPopup({list,setPopupOpen}) {
    const popupRef=useRef(null);
    const [popupAnimate,setPopupAnimate]=useState('fade-in');
    let {popup,roomAmenitiesData,seemore,price,images,rateSheet,roomName,hotelName,maxPax:hotelMaxPax,currency}= list
    const {roomPax,createRooms,calendarDate} = useSelector((state) => state.accomodationreducer)

    let nbNights=calculateNights(calendarDate.length ? calendarDate[0]:newDate(),calendarDate.length === 2 ? calendarDate[1]:calendarDate[0] );

    const getRateSheetPaxDetails=(season)=>{
        const {Adult,Teen,Child,Infant,...rest}=season;
        return {Adult,Teen,Child,Infant};
    }
    
    const handleMapClose=(e,open)=>{
        if (open) {
            setPopupAnimate("fade-in");
            setOpenMap(open);
        }else{
            setPopupAnimate("fade-out")
            setTimeout(() => {
                closePopup(e);
            }, 300);
        }
    }
    // Désactiver le scroll du body lorsque le popup est ouvert
    useEffect(() => {
        const disableScroll = () => {
            document.body.style.overflow = 'hidden'; 
        };

        const enableScroll = () => {
            document.body.style.overflow = 'auto'; 
        };

        disableScroll(); 

        return () => {
            enableScroll(); 
        };
    }, []);

    const closePopup =(e)=>{
        e?.preventDefault&&e?.preventDefault();
        setPopupOpen((prevstate)=>({
            ...prevstate,
            popup:false
        }))
    }
    
    useOutsideClick(popupRef,()=>{
        handleMapClose(false);
    });

    const getMealPlanLabel=()=>{
        return rateSheet.PlanName;
    }

    const getOfferDiscount=(offer)=>{
        let sign =getOfferSign(offer.OfferType);
        return `(- ${offer.OfferValue} ${sign})`;
    }

    return (
        <div className={`room-popup-parent ${popupAnimate}`} ref={popupRef}>
            {/* <img src={close} alt="close icon" className='close-icon' onClick={(e)=>{closePopup(e)}}/> */}
            <div className='close-icon' onClick={(e)=>{handleMapClose(e,false)}}></div>
            <div className='room-popup-head'>
                <ImageWithFallBack src={images}  className='img-overview' fallBackImg={DEFAULT_ROOM_IMG}/>
                <div className='popup-head-cnt'>
                    <div className='popup-hotel-name'>{hotelName}</div>
                    <div className='popup-room-name'>{roomName}</div>
                    <div className='popup-head-tarrif'>
                        <div className='tariff-validity'>Tariff  Pax/Night Valid from {calendarDate[0]} to {calendarDate[1]} in {currency} for {nbNights} Nights</div>
                        <div><Occupancy hotelpax={hotelMaxPax} selectedPax={addingTotalPax(roomPax,false)} showMessage={false}/></div>
                    </div> 
                 </div>
                       
            </div>
            <div className='room-popup-containerBody'>
                {rateSheet.RoomTariff.map((tariff,ind)=>(
                <div className='room-popup-body' key={"tarif"+ind}>
                    <div className='tariffDates-parent'>
                        <div>Validity: <span>{dateUi_format([tariff.ValidFrom])}</span></div>
                        <div>to<span>{dateUi_format([tariff.ValidTo])}</span></div>
                    </div>
                    <table>
                        <thead >
                            <tr>
                            {
                                VIEWHEADLIST.map((headData,index)=><th key={index} className='head-txt'>{headData}</th>)
                            }
                            </tr>
                        </thead>   
                        <tbody>
                            {
                                tariff && Object.entries(getRateSheetPaxDetails(tariff))?.map(([key,value],ind)=>{
                                  
                                    
                                    let paxCategory=capitalizeFirstLetter(key);
                                    let basePax=value[key+'Pax'];
                                    let sharingPax=value[key+'SupplementPax'];
                                    let baseRate=value[key+'BaseRate'];
                                    let sharingRate=value[key+'SupplementRate']
                                    let basePrice=value[key+'BasePrice'] * tariff.NumberOfDays;
                                    let sharingPrice=value[key+'SupplementPrice']* tariff.NumberOfDays;
                                    // let amount=basePrice+sharingPax;
                                    let amount=tariff.TotalPrice;
                                 
                                    
                                    
                                    return basePax>0||sharingPax>0?(
                                        <tr key={key+ind}>
                                          
                                            <td  className='rate-txt pax-category'>{paxCategory}</td>
                                          
                                            <td  className='rate-txt price-detail'>{basePax}</td>
                                            <td  className='rate-txt price-detail'>{sharingPax}</td>
                                            <td  className='rate-txt price-detail'>{formatPrice(baseRate)}</td>
                                            <td  className='rate-txt price-detail'>{formatPrice(sharingRate)}</td>
                                            <td  className='rate-txt price-detail'>{formatPrice(basePrice)}</td>
                                            <td  className='rate-txt price-detail'>{formatPrice(sharingPrice)}</td>
                                            <td  className='rate-txt price-detail'>{formatPrice(amount)}</td>
                                        </tr>
                                ):null}) 
                                
                            }
                        </tbody>
                        <tfoot>
                            <tr><td className='line-separator'></td></tr>
                            <tr>
                                <td className='rate-txt pax-category'>{getMealPlanLabel()}</td>
                                <td className='rate-txt price-detail'>-</td>
                                <td className='rate-txt price-detail'>-</td>
                                <td className='rate-txt price-detail'>-</td>
                                <td className='rate-txt price-detail'>-</td>
                                <td className='rate-txt price-detail'>-</td>
                                <td className='rate-txt price-detail'>-</td>
                                <td className='rate-txt price-detail'>{formatPrice(tariff.MealPlanPrice)}</td>
                            </tr>
                            {
                                tariff.Offers&&tariff.Offers.map((offer,ind)=>(
                                    <tr key={"offer"+ind}>
                                        <td className='rate-txt pax-category discount'>{offer.OfferName} {getOfferDiscount(offer)}</td>
                                        <td className='rate-txt price-detail'>-</td>
                                        <td className='rate-txt price-detail'>-</td>
                                        <td className='rate-txt price-detail'>-</td>
                                        <td className='rate-txt price-detail'>-</td>
                                        <td className='rate-txt price-detail'>-</td>
                                        <td className='rate-txt price-detail'>-</td>
                                        <td className='rate-txt price-detail discount'>{formatPrice(-offer.OfferPrice)}</td>
                                    </tr>
                                ))
                            }
                            
                        </tfoot>
                    </table>
                </div>))}
            </div>
            <div className='room-popup-footer'>
                <NightStay from={calendarDate.length ? calendarDate[0]:newDate() } to={calendarDate.length === 2 ? calendarDate[1]:calendarDate[0] } flexDirection='column'/>
                <div className='total'><span>TOTAL:</span><span>{formatPrice(price)} {currency}</span></div>        
            </div>
        </div>
    );
}

export default RoomPopup;