import React,{useState} from 'react';
import quoteImg from "../../Assets/images/quoteexcursion.png"
import {DisplayPax} from './ExcursionArray.js';
import CancelationPolicy from './CancelationPolicy';
import Multicalander from './Multicalander.js';
//import SingleCalander from '../MiniComponents/SingleCalander.js';
import LocationPoint from '../Accomodation/LocationPoint';
import SameInputs from './SameInputs';
import CarDropdown from '../MiniComponents/CarDropdown.js';
import { useSelector,useDispatch } from 'react-redux';
import {quotationPopup,aboutExcursion,removeSelection} from "../../store/excursionReducer.js"


function QuoteExcursion({allData,details}) {
    const dispatch = useDispatch()
    let {defaultPrice,detailId,serviceId,excursionId} =details


    const {selExcursion=[],selDate=[]} = useSelector((state) => state.excursionreducer)
    const inputsDate = useSelector((state) => state.excursionreducer.calendarDate)
    const {user_id,payment_term,currency} = useSelector((state) => state.authpath.userData)
    const {Adult,Teen,Child,Infant} = useSelector((state) => state.excursionreducer.paxData[0])
    const [sendObject,setSendObject]=useState({pickup:"",tariff_details:"SHARING",id_hotel_from:"",price:defaultPrice, serviceId:serviceId,serviceDetId:detailId})
    
    //console.log(allData);
    // Destructuring object
    let {lang,accessibility,pickup, duration,name,cancellation_policy,quote_data,tour_cat_id,meal} =allData;
    let {tariff_details} = quote_data
    let chkSelected = selExcursion.length && selExcursion.some(obj => Object.values(obj).includes(excursionId))
    let totalPax = Adult+Teen+Child+Infant // plese add total pax for excursuon

    let checkbox = [
        {id:1,label:"Shared Vehicle",name:"SHARING",price:defaultPrice},
        {id:2,label:"Private Vehicle",name:"PRIVATE",price:""}
    ]

   

    const handleValues = (label,value)=>{
        if(label === "pickup"){
            setSendObject((prevstate)=>({
                ...prevstate,
                pickup:value.name,
                id_hotel_from:value.location_id
            }))
        }
        if(label === "vehicle_type"){
            setSendObject((prevstate)=>({
                ...prevstate,
                price:defaultPrice,
                tariff_details:value.name,
                serviceId:serviceId,
                serviceDetId:detailId
            }))
        }
        if(label === "vehicle-name"){
            setSendObject((prevstate)=>({
                ...prevstate,
                price:value.price,
                serviceId:value.service_id,
                serviceDetId:value.service_details_id
            }))
        }
    }


    const quoteData =(e)=>{
        e.preventDefault();
        if(!Object.values(sendObject).includes("") && selDate !== null){ // to add  
            dispatch(quotationPopup(true))
        //console.log("send object excursion",sendObject);
        let {pickup,tariff_details,id_hotel_from,serviceId,serviceDetId,price} =sendObject
       
        let payload = {
            "excursion_id":excursionId,
            "payment_type":payment_term,
            "paid_by": "client", 
            "date":selDate,
            "rep_id": user_id,
            "service_id":serviceId, // service id
            "service_details_id":serviceDetId, //service details id
            "id_hotel_from":id_hotel_from , 
            "id_hotel_to": 752,
            "room_no": 7,
            "discount_type": "cash", 
            "discount_value": 50, 
            "currency": currency,
            "remarks": "Prestige Client", 
            "language": "1",
            "unit": 1, 
            "data":{"duration":duration, "lang":lang, "meal":meal, "accessibility":accessibility, "pickup":pickup},
            "location":pickup,
            "vehicleType":tariff_details,
            "excursion_name":name,
            "price":price,
            "adult_pax": Adult,
            "teen_pax": Teen,
            "infant_pax": Child,
            "child_pax": Infant
        
        }
      
        //if(!chkSelected && !Object.values(sendObject).includes("")){ // to add 
        
            dispatch(aboutExcursion(payload))
            //console.log("true");
        }
        else{
            //dispatch(removeSelection(excursionId))
            alert("please fill the fields")
            //console.log("false");
            //console.log("send object excursion",sendObject);
        }
    }
    
    return (
        <div className='QuoteExcursion_parent'>
            <p className='paratxt'>Get a quote for your excursion</p>
            <div className='sub_quoteparent'>
                <div className='QuoteExcursion_header'>
                    <div className='QuoteExcursion_left'><img src={quote_data.img} alt="excursion img" width={453} height={614}/></div>
                    <div className='QuoteExcursion_right'>
                        <div className='quote_name'>{name}</div>
                        <div className='quote-accesibility'>
                            {/* <div className='quote_particular quote_part1'><span>Meals</span>{meal.charAt(0).toUpperCase() + meal.slice(1)}</div> */}
                            <div className='quote_particular quote_part2'><span>Duration</span>{duration}</div>
                            <div className='quote_particular quote_part3'><span>Pickup</span>{pickup}</div> 
                            <div className='quote_particular quote_part3'><span>Number of Pax</span>{totalPax}</div> 
                        </div>
                        
                        <div className='location_comp'>
                            <LocationPoint
                                label="Please select a Pick-up Point"
                                inpText="Search for your pick-up point"
                                inpTextDetail="Pickup details"
                                points="pickup"
                                passArray1={sendObject.pickup}
                                passArray2=""
                                passId=""
                                staticType={false}
                                cname="excursion"
                                handleValues={handleValues}
                            />
                        </div>
                        <div style={{width:"100%",float:"left"}}>
                            <div>Please select a excursion type</div>
                            <div style={{position:"relative",margin:"6px 0 35px 0px"}}>
                                <SameInputs type="radio" data={checkbox} handleValues={handleValues}/>  
                            </div>
                            <>
                                {   
                                    sendObject.tariff_details === "PRIVATE" ?
                                    <CarDropdown label="*Note : The number of vehicles will depend on the selection below" data={tariff_details} handleValues={handleValues}/>
                                    :null
                                }
                            </>
                            <div>Pick a date for your excursion*</div>
                        </div>
                        {/* <div style={{width:"100%",float:"left"}}><DisplayDates handleValues={handleValues}/></div> */}
                        <div style={{width:"324px",float:"left",position:"relative",marginTop:"10px"}} className='single-cal'> 
                            <Multicalander label="Pick a date for your excursion" rangeDate={inputsDate} label2={selDate?.length === 0 ? "dd/mm/yyyy":selDate}  month={1} page={6} />
                            {/* <SingleCalander/> */}
                        </div> 
                        <div className='display_pricepax'> 
                            <DisplayPax Adult={Adult} Child={Child} Teen={Teen} price={sendObject.price} cur={currency}/>
                        </div>
                        <div className='btnblock'>
                            <button className='ex_search' onClick={(e)=>{quoteData(e)}}>Add to quote</button>
                        </div>
                    </div>
                </div>
                <CancelationPolicy cancelData={cancellation_policy}/> 
            </div>    
            
        </div>
    );
}

export default QuoteExcursion;