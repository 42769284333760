import { Slider } from '@mui/material'
import React, { useEffect, useState,useRef,useCallback } from 'react'
import { BUDGET_RANGE } from '../../../constants';
import { getCurrency, separateurMillier } from '../../../utility/globalFunc';
import VOLUME_IMG from "../../../Assets/images/accomodation/hotel-stock-volume.png";
import useOutsideClick from '../../../hooks/useOutsideClick';
import { useDebounceLoad } from '../../../hooks/useDebounceLoad';

function PriceSlider({ currency = "EUR", onValueCommitted, onValueChange, defaultValues = [BUDGET_RANGE.MIN_PRICE, BUDGET_RANGE.MAX_PRICE], minDistance = undefined, disableSwap = false }) {
    const [value, setValue] = useState([])
    const [leftDisableWidth, setLeftDisableWidth] = useState(0);
    const [rightDisableWidth, setRightDisableWidth] = useState(0);
    const [isType, setIsType]=useState(false)

    const closeInputRef = useRef(null)

    const debouncedSearch = useDebounceLoad(useCallback((searchTerm) => {
        console.log('Debounced search term:', searchTerm);
        onValueCommitted && onValueCommitted(searchTerm)
    },[]),500);

    const handleCommittedChange = (e, _) => {
        // e?.preventDefault();
        // if(minDistance){
                // if(respectMinDist(value)){
                    onValueCommitted && onValueCommitted(value)
                // }
        // }
    }

    const respectMinDist=(values)=>{
        if (minDistance) {return values[1]-values[0]>=minDistance;}
        return true;
    }

    const handleChangeMinDistance = (event, newValue, activeThumb) => {
        
        if (!Array.isArray(newValue)) {
            return;
        }
        if (activeThumb === 0) {
            setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
        } else {
            setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
        }
    };

    const handleSimpleChange = (e, value) => {
        e?.preventDefault();
        setValue(value);
    }

    const formatValueLabel = (x) => {
       // return getCurrency(currency) + " +" + separateurMillier(x,",")
        return separateurMillier(x,",")
    }

    useEffect(() => {
        setValue(defaultValues);
    }, [])

    const handleChange = (e, value, activeThumb) => {
        if (minDistance) {
            handleChangeMinDistance(e, value, activeThumb);
            if (respectMinDist(value)) {

                setLeftDisableWidth(getWidthDisableLeft(value[0]));
                setRightDisableWidth(getWidthDisableRight(value[1]));
            }
        } else {
            handleSimpleChange(e, value);
        }
    }

    const getWidthDisableLeft = (value) => {
        const range = BUDGET_RANGE.MAX_PRICE - BUDGET_RANGE.MIN_PRICE;
        return (value - BUDGET_RANGE.MIN_PRICE) * 100 / range;
    }

    const getWidthDisableRight = (value) => {
        const range = BUDGET_RANGE.MAX_PRICE - BUDGET_RANGE.MIN_PRICE;
        return (BUDGET_RANGE.MAX_PRICE - value ) * 100 / range;
    }

    const openBudgetInput =(e,name)=>{
        e.preventDefault()
        const updatedValue = [...value];

        if(name === "OPEN-INPUT"){
            setIsType(!isType)
        }

        else{
            updatedValue[name] = Number(e.target.value)            
            setValue(updatedValue)
            debouncedSearch(updatedValue)            
        }
       
        
    }

    useOutsideClick(closeInputRef, () => {
        setIsType(false);
    });



    return (
        <div className='types-of-filter' ref={closeInputRef}>
            <div className='filter_type_name budget-edit'>Your budget <span onClick={(e) =>{openBudgetInput(e,"OPEN-INPUT")}}></span></div>
            <div className='slider-container'>
                <div className='volume-container'>
                    <div style={{ width: `${leftDisableWidth}%` }} className='disabled-volume'></div>
                    <div style={{ width: `${rightDisableWidth}%` }} className='disabled-volume right'></div>
                    <img src={VOLUME_IMG} className='volume-image' />
                </div>
                <Slider
                    value={value}
                    onChangeCommitted={(e, value) => { handleCommittedChange(e, value) }}
                    onChange={handleChange}
                    min={BUDGET_RANGE.MIN_PRICE}
                    max={BUDGET_RANGE.MAX_PRICE}
                    step={minDistance / 2}
                    valueLabelDisplay='on'
                    //valueLabelFormat={formatValueLabel}
                    valueLabelFormat={(val) =>
                        val === BUDGET_RANGE.MAX_PRICE ? `${val}+` : formatValueLabel(val)
                    }
                    disableSwap={disableSwap}
                />
            </div>

            {isType && <div className='range-input-box'>
                <input type="text" placeholder='Range From' onChange={(e) =>{openBudgetInput(e,0)}} value={value[0] || ""}/>
                <input type="text" placeholder='Range To'   onChange={(e) =>{openBudgetInput(e,1)}} value={value[1] || ""}/>
            </div>}
        </div>
    )
}

export default PriceSlider