import { useState } from 'react';
import { axiosRequestParams,axiosRequestBody} from '../services/apiClient.js';
import {BOOKING_ENDPOINT,CLIENT_DETAILS_ENDPOINT} from "../services/apiEndpoints.js"
import { TIMEOUT_DURATION } from '../constants.js';
import {userLogout} from "../store/authentication.js"
import {clearTransfer} from "../store/transferReducer.js"
import {clearexcursion} from "../store/excursionReducer.js"
import {clearPax,clearAll} from "../store/globalReducer.js"
import {clearAccom} from "../store/accomodationReducer.js"

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useDocket from './useDocket.js';
import useMoreOptionQuotation from './useMoreOptionQuotation.js';


// Custom hook to fetch data from an API
const useFinalBooking = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);  
    const [status, setStatus] = useState(null);  
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {clearDocket}=useMoreOptionQuotation()
 
    
    const finalBooking = async (payload) => {
        console.log("req payload:",payload);
       
        setLoading(true);
        try {
            const axiosConfig = { timeout: TIMEOUT_DURATION }; 
            const finalBooking = await axiosRequestBody("POST",BOOKING_ENDPOINT,payload,axiosConfig);
           
           console.log("finalBooking:",finalBooking.data.status);
            setStatus(finalBooking.data.status)
            clearDocket();
            //dispatch(clearAll())
            //dispatch(clearTransfer())
            //dispatch(clearexcursion())
            //dispatch(userLogout())
            // navigate("/accomodation")


        } 
        catch (error) {
            console.log("error",error);
            if(error.response && error.response.status === 401){
                navigate("/")
            }
            // else if (error.response && ![409, 401].includes(error.response.data.status)) {
            //     alert(error.response.data.message)
            // } 
            
            else {
                //alert(error.response.data.message)
                navigate("/internal-error",{state:{message:error.response.data.message,status:error.response.status}})
            }
                
        } 
        finally {
            setLoading(false);
        }
    };
   
    return { error,loading,status,finalBooking };
};


export default useFinalBooking;