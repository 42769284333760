import React from 'react';
import CircleIcon from "@mui/icons-material/Circle";
import Rating from '@mui/material/Rating';
import {generateIds} from "../../constants.js"


function HotelRating({data}) {
    let {overall_rating,reviews,title,subratings,name}=data
    return (
        <div className='hotel_rating_parent'>
            <div className='rating_header'>
                <div className='overall_value'>{overall_rating.toString().replace(',', '.')}</div>
                <div className='overall-container'>
                    <div className='overall_name'>{name}</div>
                    <div className='overall_rating'>
                        <Rating  className="circleRating" precision={0.5}  icon={<CircleIcon fontSize="inherit" />} emptyIcon={<CircleIcon fontSize="inherit" />} value={overall_rating} readOnly sx={{ fontSize:"12px" }} />
                        <span className='overall_review'>{reviews} reviews</span>
                    </div>
                   
                </div>  
            </div>
            <div className='overall_details_container'>
                <div className='overall_title'>{title}</div>
                <div className='rating_loop_parent'>
                {
                    subratings.map((data)=>{
                        return(
                            <div className='rating_display' key={data.id}>
                                <Rating  className="circleRating" precision={0.5}  icon={<CircleIcon fontSize="inherit" />} emptyIcon={<CircleIcon fontSize="inherit" />} value={data.rating} readOnly sx={{ fontSize:'12px' }} />
                                <div>{data.name}</div>
                            </div>
                        )
                    })
                }
                </div>
            </div>
        </div>
    );
}

export default HotelRating;