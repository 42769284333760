import React,{forwardRef, useContext, useEffect, useRef, useState} from 'react';
import { useLocation } from 'react-router-dom';
import TimelineTransfer from '../MiniComponents/Timeline.js';
import usePopupData, { CANCEL_UPDATE_POPUP } from '../../hooks/usePopupData.js'; 
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import {getTotalPrice, setReservationService} from "../../store/globalReducer.js"
import MoreOptions from '../MiniComponents/MoreOptions.js';
import useMoreOptionQuotation from '../../hooks/useMoreOptionQuotation.js';
import { MORE_OPTIONS_MODE, SERVICE_TYPE } from '../../constants.js';
import useAddItems from '../Quotations/hooks/useAddItems.js';
import Dropdowns from './Dropdowns.js';
import {DROPDOWN_DATA} from "../../constants"
import { buildReservationAccom, getCurrency, separateurMillier } from '../../utility/globalFunc.js';
import { LayoutContext } from '../layout/MainLayout.js';
import { createSelector } from '@reduxjs/toolkit';

const Quotation=forwardRef(({isIntersectingFooter=false,additionalBottomDistance=0,showServiceSelector},ref)=>{
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const location = useLocation();
  const {totalPrice,actionMode,sortedList,shwowDropDown} =useSelector((state) => state.globalreducer)  
  const {agency_id,first_name,last_name} =useSelector((state) => state.authpath.userData)  
  const {transferList=[],refCode,transferTotal,updatePax:paxres,totalpax} =useSelector((state) => state.transfer_Global)  
  const {selExcursion=[],excursionTotal} =useSelector((state) => state.excursionreducer)
  const { selRooms, roomPax, isNewHotel, numberOfRoom,pendingRooms } = useSelector((state) => state.accomodationreducer)
  const { isOpen, openPopup, getPopupContent,closePopup } = usePopupData();
  const { clearDocket } = useMoreOptionQuotation()
  const [showRemove,setShowRemove]=useState(false);
  const [canNavigate,setCanNavigate]=useState(false);
  const {addItems,status}=useAddItems();
  const [disableUpdate,setDisableUpdate]=useState(true);
  const [updatedData,setIsUpdatedData]=useState(null);
  const [currency, setcurrency] = useState();
  const [error,setError] = useState(null)

  let transfer_sum = 0;
  let excursion_sum = 0;
  let accomodation_sum = 0;
  
  let gngtoselect = 1;

  let quotationList = transferList.concat(selExcursion,selRooms)

  quotationList = quotationList.map((obj,key)=>{
    let { TransferDate:date , ...rest } = obj;
    return { date, docketid: key+1, ...rest }; 
  })

  const compareDates = (a, b) => {
    if( a.hasOwnProperty("idAccom")){
      // const dateA = new Date(a.date[0].split('/').reverse().join('/'));
      // const dateB = new Date(b.date[1].split('/').reverse().join('/'));
      // return dateA - dateB;
    }
    else{
      const dateA = new Date(a.date.split('/').reverse().join('/'));
      const dateB = new Date(b.date.split('/').reverse().join('/'));
      return dateA - dateB;
    }
  };
  const sortedArray = quotationList.sort(compareDates); 

  useEffect(() => {
    //console.log('sortedList', sortedList);


    setcurrency(sortedArray.length ? sortedArray[0].currency||sortedArray[0].Currency : 0);
    accomodation_sum = parseFloat(accomodation_sum.toFixed(3));
    let totalprice = excursion_sum + transfer_sum +accomodation_sum
    setDisableUpdate(!existNewItems());
     dispatch(getTotalPrice(totalprice))

     if(status == 201 && updatedData!= null){
      openPopup('updated-quotation',updatedData)
    }
  }, [sortedArray,status,updatedData]);

  useEffect(()=>{

    if(isOpen && location.pathname == "/accomodation-select" ){
      closePopup();
    }
  },[location.pathname])
 
// Adding total transfer price
  transferList.forEach((element) => {
    transfer_sum += element.TotalPrice;
    if(element.Car_selected){
      gngtoselect = gngtoselect + 1;
    }
  });

  selExcursion.length && selExcursion.forEach(element => excursion_sum += element.price)
  selRooms.length && selRooms.forEach(element => accomodation_sum += element.price)
  
  const viewCotation=(e)=>{
    e.preventDefault();
    let response = showError();
    if(!response){
      setTimeout(()=>{setError(null)},2000)
      return;
    } 
    let {hostname,protocol,port }=window.location;
    port ?  port = ":"+port : port = ""
    let serverUrl = protocol+`//`+hostname+port+`/view-details`;
    window.open(serverUrl, '_blank');
  }

  const openInputbox =(e)=>{
    e.preventDefault();   

    let response = showError();
    if(!response){
      setTimeout(()=>{setError(null)},2000)
      return;
    } 
    openPopup("info")
  }

  const openCancelUpdatePopup=(e)=>{
    e?.preventDefault();
    openPopup(CANCEL_UPDATE_POPUP);
  }


  // open clients page
  const bookNow =(e)=>{
    e.preventDefault();
    let response = showError();
    if(!response){
      setTimeout(()=>{setError(null)},2000)
      return;
    } 

    let {services}=sortedList
    let {reservationTransfer,reservationAccom,reservationExcursion}=services
    navigate("/userdetails",{state:{service:{reservationAccom,reservationTransfer,reservationExcursion},refCode:refCode}});
    
  }

  const updateQuotation= async (e)=>{
    e?.preventDefault();
    console.log("update coation:",selRooms,refCode);
    
    await addItems(transferList,selExcursion,selRooms,refCode,first_name,last_name,roomPax);
    const data = {"selRooms":selRooms,"pax":roomPax,"refCode":refCode}
    setIsUpdatedData(data)

  }

  const DOCKET_OPTIONS = [
    {
      label:"Remove items",
      onClick:()=>{setShowRemove(prev=>!prev)}
    },
    {
      label:"Remove all items",
      onClick:()=>{clearDocket({
        routes:location.pathname
      })}
    },
    {
      label:"Send via mail",
      onClick:()=>{}
    },
    {
      label:"Export in PDF",
      onClick:()=>{}
    },
  ];

  useEffect(()=>{
    if (!sortedArray.length) {
      setShowRemove(false);
    }    
  },[sortedArray,shwowDropDown])



  const existNewItems=()=>{
    return sortedArray.some(item=>item.isNew==true);
  }

  const containerRefToScroll = useRef(null);

  const isDocketVisible=()=>{
    
    return sortedArray.length>=1||pendingRooms.requiredNbRooms>0
  }

  const checkValidServices = (serviceName) =>{    
    let returnvalue = false;
    if(serviceName === "accom"){
        if(pendingRooms.roomsFilled &&  pendingRooms.roomsFilled.length > 0){
          let counteurRoomValid = 0;
          for(let i = 0 ; i < pendingRooms.roomsFilled.length;i++){
              if(pendingRooms.roomsFilled[i] == false){
                setCanNavigate(false)
                return returnvalue;
              }else{
                counteurRoomValid++;
              }
    
          }
          if (counteurRoomValid == pendingRooms.roomsFilled.length){
            setCanNavigate(true)
            returnvalue = true;
          }
        }
    }
    if(serviceName === "transfer"){
      if(transferList && transferList.length > 0){
        let counteurCarValid = 0;
          for(let i = 0 ; i < transferList.length;i++){
            if(transferList[i].Car_selected == false){
              setCanNavigate(false)
              return returnvalue;
            }else{
              counteurCarValid++;
            }
        }
        if(counteurCarValid == transferList.length){
          setCanNavigate(true)
          returnvalue = true;
        }
      }
    }
    return returnvalue;
  }

  const checkServiceName = () =>{
    let serviceName = "";
    if(location.pathname == "/select-vehicle") serviceName = "transfer";
    if(location.pathname == "/selected-accomodation") serviceName = "accom";
    return serviceName;
  }

  const showError = () =>{
    let serviceName = checkServiceName()
    let isValidServices =  checkValidServices(serviceName);
    
    if(!isValidServices){
        setError(serviceName)
      return false; 
    }
    return true;
  }

  useEffect(()=>{
    dispatch(setReservationService({"serviceType":SERVICE_TYPE.TRANSFER,"data":transferList}));
  },[transferList])

  
  useEffect(() => {
    let reservationAccom = buildReservationAccom(selRooms, isNewHotel)
    dispatch(setReservationService({ "serviceType": SERVICE_TYPE.ACCOMODATION, "data": reservationAccom }))
  }, [selRooms,isNewHotel,pendingRooms])

  return (
    <>
      <div className={`fixedQuotation ${isIntersectingFooter?"follow-scroll":""}`} style={isIntersectingFooter?{bottom:`${additionalBottomDistance}px`}:{}} ref={ref}>
        <div className={`service-selector-container ${showServiceSelector?"":"hide-service"}`}>
          <Dropdowns dropData={DROPDOWN_DATA} imgcount={1} name="Accommodation"/>
        </div>
        <div className={`quotationBlock ${isDocketVisible()?"":"hide"}`}>
          <div className="headerQuotation">
            <div className="headerQuotation-txt">Your quotation</div>
            {actionMode==null&&<MoreOptions options={DOCKET_OPTIONS}/>}
          </div>
          <div ref= {containerRefToScroll} className='quotation_body'>
            {/* [Docket] */}
            <TimelineTransfer containerRef={containerRefToScroll} transferData={sortedArray} paxdata={paxres} nxtselect={gngtoselect} totalpax={totalpax} showRemove={showRemove}/>          
          </div>   
            {/* //Total */}
          {/* //Total */}
          <div className="quotation-total">
            Total :
            <b style={{ float: "right", position: "relative" }}>
              <span>{getCurrency(currency)}  {separateurMillier(totalPrice,",")}</span>
            </b>
          </div>
          {actionMode==null&&<div className="view_quotation">
            <div onClick={(e)=>{viewCotation(e)}}>
              <span>View quotation</span>
            </div>
          </div>}
          <div className="bookButtons">
            {showRemove ? (
              <>
                <button style={{ marginLeft: "174px", background: "rgba(20, 155, 158, 1)", color: 'white', border:'rgba(22, 163, 145, 1)'}} onClick={(e)=>setShowRemove(false)} className='save-quote-btn'>Done</button>
              </>
            ) : (
              actionMode == null ? (
                <>
                  <button onClick={(e) => openInputbox(e)} className=''>Save quotation</button>
                  <button onClick={(e) => bookNow(e)} className='pay-book-btn'>Book now</button>
                </>
              ) : (
                <>
                  <button onClick={openCancelUpdatePopup} className='cancel-update-btn'>Cancel</button>
                  <div className='update-warning-red'>
                    To update your quotation, you need to first add a new item.
                  </div>
                  <button onClick={(e) => updateQuotation(e)} className="update-btn" disabled={disableUpdate}>
                    Update quotation
                  </button>
                </>
              )
            )}
          </div>
        </div>
        {actionMode===MORE_OPTIONS_MODE.ADD_ITEM && <div className='toast-warning'>
          Caution : Please save your quotation to apply latest changes
        </div>}
        {error && error == "transfer" && <div className='vehicle-fill-pop'><p>Please select all vehicles of your transfer list ! 🙅🏽‍♂️</p></div>}
        {error && error == "accom" && <div className='hotel-fill-pop'><p>Please select all the rooms in your hotel ! 🙅🏽‍♂️</p></div>}
      </div>
      {isOpen ? getPopupContent():<></>}
    </>
    
  );
})



export default Quotation;