import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { scrollToTop } from '../../utility/globalFunc'
import { useSelector } from 'react-redux';
import StarRatingMenu from '../Accomodation/minicomponents/StarRatingMenu';
import useFilterItems from '../../hooks/useFilterItems';

const FilterResume=forwardRef(({filters,selectStarRating,scrollRef, intersectFooter=false},ref) => {
  const {starRating} = useSelector((state) => state.accomodationreducer)
  const {getFilterResume,renderFilterResume} = useFilterItems();
  const [isScrollable,setIsScrollable]=useState(false);
  const filterScrollRef=useRef();

  const onClickButtonScroll=(e)=>{
    e?.preventDefault();
    if (scrollRef.current) {
      scrollToTop();
    }
  }
  const filtersResume=getFilterResume(filters);

  useEffect(()=>{
    const element=filterScrollRef.current;
    const checkScrollability=()=>{
      if (element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth) {
        setIsScrollable(true);
      } else {
        setIsScrollable(false);
      }
  }

  const observer = new ResizeObserver(checkScrollability);
    if (element) {
      observer.observe(element);
      checkScrollability();
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  },[]);
  
  return (
    <div className={`filter-summarize fade-in-200 ${intersectFooter&&"follow-scroll"}`} ref={ref}>
      <div className={`filters-scroll ${isScrollable&&"bottom-shadowed"}`} ref={filterScrollRef}>
        <div className={`filters-container`}>
        {
          filtersResume&&filtersResume.map((filter)=>{
            return renderFilterResume(filter,scrollRef,starRating,selectStarRating);
          })
        }
        </div>
      </div>
      <button className='button-scroll' onClick={onClickButtonScroll}>Scroll to the top</button>
    </div>
  )
})

export default FilterResume
