import React from 'react';
import { maxRoomTxt } from '../../../constants';

function Occupancy({hotelpax,selectedPax,showMessage=true}) {
    return (
        <div className='occupancy-container'>
            <div className={ "occupency-parent "+ (hotelpax >= selectedPax ?"occupency-enable":"occupency-stop")}>
                Max occupancy : {hotelpax} pax
            </div>
            {
                hotelpax>=selectedPax&&showMessage&&
                <span className='occupancy-message-success'>{maxRoomTxt[0]}</span>
            }
            {
                hotelpax<selectedPax&&showMessage&&
                <span className='occupancy-message-danger'>{maxRoomTxt[1]}</span>
            }
        </div>
    );
}

export default Occupancy;