import React, { useState, useEffect } from 'react';
import useAuthActions from '../../hooks/useAuthActions.js';
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { clearTransfer } from '../../store/transferReducer.js';
import { clearexcursion } from '../../store/excursionReducer.js';
import { clearPax, clearAll } from '../../store/globalReducer.js';
import { userLogout } from '../../store/authentication.js';
import { clearAccom } from '../../store/accomodationReducer.js';
import { useNavigate } from 'react-router-dom';
import LoginImg1 from '../../Assets/images/login/loginimg1.jpeg';
import LoginImg2 from '../../Assets/images/login/loginimg2.jpeg';
import LoginImg3 from '../../Assets/images/login/loginimg3.jpg';
import LoginImg4 from '../../Assets/images/login/loginimg4.jpg';
import LoginImg5 from '../../Assets/images/login/loginimg5.jpg';
import LoginImg6 from '../../Assets/images/login/loginimg6.jpg';

function Login({}) {
  const [imgIndex, setImgIndex] = useState(0);
  const [loadedImages, setLoadedImages] = useState([]);
  const [loginData, setLoginData] = useState({ username: '', password: '' });
  const { login, errorMsg, isLoggedIn, loading } = useAuthActions();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const images = [LoginImg1, LoginImg2, LoginImg3, LoginImg4, LoginImg5, LoginImg6];

  // Preload images on component mount
  useEffect(() => {
    const preloadImages = () => {
      const imagePromises = images.map((src) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = src;
          img.onload = () => resolve(src);
        });
      });

      Promise.all(imagePromises).then((loaded) => setLoadedImages(loaded));
    };
    preloadImages();
  }, [images]);

  // Update image index every 10 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setImgIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000);
    return () => clearInterval(intervalId);
  }, [images.length]);

  const onUpdateField = (e) => {
    const nextFormState = {
      ...loginData,
      [e.target.name]: e.target.value,
    };
    setLoginData(nextFormState);
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    let sendObject = { username: loginData.username, password: loginData.password };
    // calling login api
    login(sendObject, setLoginData);
    dispatch(clearAll());
    dispatch(clearTransfer());
    dispatch(clearexcursion());
    dispatch(userLogout());
    dispatch(clearAccom());
  };

  // useEffect(()=>{
  //   let isLoggedIn = localStorage.getItem("isLoggedIn")
  //   !isLoggedIn ? navigate("/login") :navigate("/")
  // },[])


  return (
    <div className="login-block">
      {images.map((img, index) => (
        <div
          key={index}
          className={`background-layer ${imgIndex === index ? 'visible' : ''}`}
          style={{ backgroundImage: `url(${img})` }}
        ></div>
      ))}

      <div className="login-left">
        <form onSubmit={onSubmitForm} autoComplete="off" className="formbox">
          <div className="login-logo"></div>
          <p className="log-hdtxt">Please sign in to proceed</p>
          <input
            className={`loginput ${isLoggedIn === false ? 'error-input' : ''}`}
            type="text"
            name="username"
            placeholder="Username"
            value={loginData.username}
            onChange={onUpdateField}
            required
          />
          <input
            className={`loginput ${isLoggedIn === false ? 'error-input' : ''}`}
            type="password"
            name="password"
            placeholder="Password"
            value={loginData.password}
            onChange={onUpdateField}
            required
          />
          <div>
            <button className="searchBtn loginbtn">
              {!loading ? (
                'Sign in'
              ) : (
                <CircularProgress
                  color="secondary"
                  style={{ width: '20px', height: '20px', color: '#fff' }}
                />
              )}
            </button>
          </div>
        </form>
        {isLoggedIn !== null || errorMsg.length ? (
          <div className="logErrorMsg">
            <div className="log-errimg"></div>
            <div className="log-err-box">
              <label>Login unsuccessful!</label>
              <div>{errorMsg}</div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="carosel-dots">
        {images.map((_, index) => (
          <div
            key={index}
            className={imgIndex === index ? 'sel-carosel' : 'not-sel-carosel'}
            onClick={() => setImgIndex(index)}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default Login;
