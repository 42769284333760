import React from "react";

function PaxDetails({firstName,lastName,phoneNumber,label,numero}) {
  return (
    <div className="pax-recap-details-container">
        <p className="pax-recap-label">{label} No.{numero}</p>
        <div className="pax-recap-row">
            <input className="input-name half" readOnly value={firstName}/>
            <input className="input-name half" readOnly value={lastName}/>
            {/* <input className="input-name" readOnly value={phoneNumber}/> */}
        </div>
    </div>
  );
}

export default PaxDetails;
