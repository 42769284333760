import React,{useState,useEffect} from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import usePopupData from '../hooks/usePopupData.js'; 
import TimelineView from '../components/MiniComponents/TimelineView.js';
import logo from "../Assets/images/mautourco-logo.png"
import { QuotationActionDropdown } from '../components/Quotations/small_dropdowns.js';
import TableLayoutNew from '../components/MiniComponents/TableLayoutNew.js';
import QuoteCancellationLayout from '../components/Quotations/QuoteCancellationLayout.js';
import {extractWord} from "../utility/globalFunc.js"



function ViewDetailsPage() {
    const {agency_name,first_name,last_name} =useSelector((state) => state.authpath.userData); 
    const {refCode} =useSelector((state) => state.transfer_Global) 
    const {Adult,Child,Teen,Infant} =useSelector((state) => state.globalreducer.paxData)
    const {sortedList,totalPrice,quoteBookList} =useSelector((state) => state.globalreducer)
    // const {totalPrice,sortedList} =useSelector((state) => state.globalreducer)
    const [setpopupbox] = useState(false)
    const { isOpen, openPopup, closePopup, getPopupContent } = usePopupData();
    const navigate = useNavigate();
    const [popupOpen, setPopupOpen] = useState(false);

    //// service operation api call
    const confirmSave=(e)=>{
        openPopup("info")
       setpopupbox(true)
    }


    const confirmSaveV1=(e)=>{
        let {services}=sortedList
        let {reservationTransfer,reservationAccom,reservationExcursion}=services
        navigate("/userdetails",{state:{service:{reservationAccom,reservationTransfer,reservationExcursion},refCode:refCode}});
    }

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
        
    }, []);

    return (
        <>
        <div className='view-quotationPage'>
            <div className="save_quotaion_popup">
                <>
                    <div className="save_quotaion_head">
                        <div className="save_quotaion_headlft">
                            <p className='save_quotaion_headlft_num'>{refCode === null ? "Quotation n° 20825" :extractWord(refCode) + " Quotation" }</p>
                            <p className='save_quotaion_headlft_orange'>{refCode === null ? "Quotation n° 20825" :extractWord(refCode) + " Quotation" } <span className='not-saved'>(Not saved)</span> </p>
                            <p className='save_quotaion_headlft_name'>{first_name}  {last_name}, {agency_name}</p>
                            <div className='timeline-container'><TimelineView list={sortedList.services}/></div>
                        </div>
                        <div className="save_quotaion_headryt">
                            <img src={logo}/>
                            <div className='cotDocdetails'>
                                <p>Document details</p>
                                {refCode !== null ? (
                                    <div>
                                        <span className="label">Client’s name:</span> <span className="value"><b>{extractWord(refCode)}</b></span>
                                    </div>
                                ) : null}
                                <div><span className="label">Agency:</span> <span className="value">{agency_name}</span></div>
                                <div><span className="label">Client-Type:</span> <span className="value">Standard</span></div>
                                <div><span className="label">Number of pax:</span> <span className="value">{Adult + Child + Teen + Infant}</span></div>
                            </div>
                            <div className='dropdownContainer'>
                                <div className='buttonDrop' onClick={() => setPopupOpen(prev => !prev)}><label>More options</label></div>
                                {
                                    popupOpen && <QuotationActionDropdown isView  data={quoteBookList} openPopup={openPopup}/>
                                }
                            </div>

                        </div>
                    </div>

                    {/* time line part */}
                    <TableLayoutNew lists={sortedList.services} total={totalPrice}/>
                    <QuoteCancellationLayout data={sortedList.services}/>
                    <div className='save_quotaion_button'>
                     {/* <button onClick={(e)=>{confirmSave(e)}} disabled={true}>Save Quotation</button>       */}
                        {!refCode&&<span className='warning'>Caution : Please save your quotation to apply latest changes</span>}
                        <button  disabled={refCode !== null ?true:false} onClick={(e)=>{confirmSave(e)}} className={'finsavecotation '+(refCode !== null ?"disabledCss":"")}>Save Quotation</button>  
                        {/* <button onClick={(e)=>{Booking(e)}}  disabled={refCode !== null ?false:true} className={'finbooknow '+(refCode !== null  ?"":"disabledCss2")}>Book Now</button>    */}
                        <button onClick={(e)=>{confirmSaveV1(e)}} className={'finbooknow '}>Book Now</button>   
                    </div>     
                </>
                {isOpen ? getPopupContent():<></>}
            </div> 
           
                     
        </div>
        </>
    );
}

export default ViewDetailsPage;