import React, { useEffect, useState } from 'react'
import hoteldescription from "../../../Assets/images/accomodation/hotel_icon_description.png"
import HotelAmenities from './HotelAmenities'
import B2BSkeleton from './B2BSkeleton';
import AMENITIES_SKELETON from "../../../Assets/images/skeleton/amenities-skeleton.png";
import useHotelDetails from '../hooks/useHotelDetails';

function HotelAmenitiesDetails({hotelId,data,hotelDescriptionImg,hotelDesc,setAmenitiesData}) {
  const {fetchHotelAmenities}=useHotelDetails();
  useEffect(()=>{
    fetchHotelAmenities(hotelId,setAmenitiesData)
  },[hotelId])
  
  return (
    <>
    {data?<div className='HotelAmenities-gp'>
         <div className={'element-head-name '}> <img src={hoteldescription}/>Hotel description</div>
             <div  className="accordion-section">
                 {hotelDescriptionImg && <div><img className='main-image' src={hotelDescriptionImg} alt="amenities img" /></div>}
                 {/* {(hotelDescriptionTitle && hotelDescriptionTxt ) && <div className='accordion-section-hoteldescription'><b>{hotelDescriptionTitle}</b>{hotelDescriptionTxt}</div> } */}
                 <div>
                 {
                  hotelDesc.map((list,ind)=>(
                      <div className='accordion-section-hoteldescription' key={ind}>
                        <b>{list.HotelDescTitle == "No title available" ? "" :  list.HotelDescTitle}</b>{list.HotelDesc == "No second description available" ? "" :  list.HotelDesc }
                      </div>
                  ))
                 } 
                 </div>
                 
             </div>
        <HotelAmenities lists={data}/>
    </div>
    :<B2BSkeleton imgUrl={AMENITIES_SKELETON}/>
    }
    </>
  )
}

export default HotelAmenitiesDetails
