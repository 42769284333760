// Accomodation api
export const HOTELLIST_ENDPOINT = 'accomFinder/b2b/hotels/list';
export const HOTELREGION_ENDPOINT = 'accomFinder/b2b/hotels/region';
export const HOTELDETAILS_ENDPOINT = 'accomFinder/b2b/hotels/details';
export const HOTELAMENITIES_ENDPOINT = 'accomFinder/b2b/hotels/amenities';
export const HOTEL_ROOMS_ENDPOINT = 'accomFinder/b2b/hotels/rates';
export const FILTERLIST ='accomFinder/b2b/hotels/tags';
export const LOCPOINT_ENDPOINT = 'accom/b2b/hotels/list';

// Excursion api
// export const EXCURSIONLIST_ENDPOINT = '/api/excursions';
// export const EXCURSIONDETAILS_ENDPOINT = '/api/excursions';


// // Transfer api
export const VEHICLELIST_ENDPOINT = 'transfer/b2b/tariff'; 
// export const GETHOTELS_ENDPOINT = '/api/transfers';


//  Login api
   export const LOGIN_ENDPOINT='users/b2b/login';
   export const AGENCY_LIST="masterFile/b2b/agency_list"   
   export const SWITCH_AGENCY="users/b2b/switch_agency"

// Quotation api
export const CREATE_QUOTE_ENDPOINT = 'quotation/b2b/quotation';
export const QUOTELIST_ENDPOINT = 'quotation/b2b/quotation';

export const ADD_ITEM_QUOTATION_ENDPOINT = 'quotation/b2b/quotation';
export const BOOK_QUOTE_ENDPOINT = '/api/quotations';
export const BOOKING_ENDPOINT = 'reservation/b2b/Reservation';

 // Client Details api
export const CLIENT_DETAILS_ENDPOINT = '/folder/client/create';
//export const LOGIN_ENDPOINT='users/partner/login';

export const REFRESH_TOKEN_ENDPOINT='user/b2b/refresh_token';

