import React,{useEffect, useState} from 'react';
import { DROPDOWN_DATA,MAUTOURCO_ID } from '../constants';
import Dropdowns from '../components/Transfer/Dropdowns';
import AccomInputs from '../components/Accomodation/AccomInputs.js';
import NavbarBlock from '../components/Transfer/NavbarBlock.js';
import useHotelList from '../components/Accomodation/hooks/useHotelList.js'
import {useSelector } from 'react-redux';
import usePopupData, { BOOKING_REQUESTED, TOKEN_EXPIRED_POPUP } from '../hooks/usePopupData.js';
import {useNavigate } from 'react-router-dom';
import { openPopup } from '../store/errorReducer.js';

function AccomodationPage(props) {
   const {accomList,accomfilter={},paxData,accomLoading,criteria:filterCriteria,starRating} = useSelector((state) => state.accomodationreducer)
   const {agency_id} =useSelector((state) => state.authpath.userData); 
   const {subAgencyAdded} =useSelector((state) => state.authpath); 
   const [isSubAgencyAdded,setSubAgencyAdded]=useState(false)
   let token = localStorage.getItem("access-token")
   let mtco_token = localStorage.getItem("mtco-token")
   const navigate = useNavigate() 

   const { isOpen, openPopup, closePopup, getPopupContent } = usePopupData();

   useEffect(() => {
    // openPopup(BOOKING_REQUESTED);

    const issubAgency = Object.values(subAgencyAdded).length > 0;
    setSubAgencyAdded(issubAgency)
   }, [subAgencyAdded])


   

   const list_structure1 = {
        style_input1:{display:"grid",gridTemplateColumns:"170px 170px",gap:"13px"},
        style_input2:{display:"grid",gridTemplateColumns:"458px 199px 248px",gap:"23px", marginLeft:"16px"},
        pageNo:1
    }

    return (
        <>
        {isOpen && getPopupContent()}
       { !accomLoading ? 
        
        <div>
            <div className={`transferPage_parent maubackground ${agency_id === MAUTOURCO_ID && !isSubAgencyAdded ? "disable" : ""}`}>
                <div className="headText ">
                    <div>Discover, feel, live <span>Mauritius.</span></div>
                </div>
                <div className='accomContainer'>
                    <div> 
                        <Dropdowns dropData={DROPDOWN_DATA} imgcount={1} name="Accommodation"/> 
                        <div className='accomodation_box' style={{marginTop:"80px"}}>
                        <AccomInputs alignbtn={true} styles={list_structure1} scroll={true} remRating={false}/> 
                        </div>
                    </div>
                </div> 
            </div>
        </div>
       
        :<div className='accomSkeletonLoad1'></div>}
         </>
    );
}

export default AccomodationPage;