import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {listTransfer} from "../../store/transferReducer.js"
import {SERVICE_TYPE} from "../../constants.js"
import { setReservationService } from "../../store/globalReducer.js"
import {selectedCarDetailsTemp} from "../../utility/globalFunc.js"


function CarCapacity({setCapPopup,data={}}) {


    let {selectDetails,MaxPax,totalPax} =data
    let {Category}=selectDetails;
    // const{ adult:Adult,child:Child,teen:Teen,infant:Infant} =useSelector((state) => state.transfer_Global.transferPax[0])    
    
    
    const { tempId: currentTransferId } = useSelector((state) => state.transfer_Global);
    const { AdultCount: Adult, TeenCount:Teen, ChildCount: Child, InfantCount: Infant } = useSelector((state) => state.transfer_Global.transferUpdates[currentTransferId]);
    const dispatch =useDispatch()
   
    const updateCars=(e,name)=>{
        e.preventDefault();
        if(name === "close"){
            setCapPopup((prevstate)=>({
                ...prevstate,
                pop:false,
                data:null
            }))
        }
        else{
            let payload = selectedCarDetailsTemp(data)
            console.log("car capacity payload",payload);
            dispatch(listTransfer(payload))
            dispatch(setReservationService({ "serviceType": SERVICE_TYPE.TRANSFER, "data": payload.transfer_data }))
            setCapPopup((prevstate)=>({
                ...prevstate,
                pop:false,
                data:null,
                isAdapt:true
            }))
        }
       
        
    }

    return (
        <div className='car_capacity_parent'>
            <div className='imgBlock'></div>
            <div>Be careful the number of pax selected ( <span>
                     {Adult === 1 ?    +Adult  +" Adult":Adult+" Adults"}
                     {Teen === 1 ?", " +Teen   +" Teen": Teen > 1 ? ", "+Teen+" Teens":"" }
                     {Child === 1 ?", "+Child  +" Child": Child > 1 ? ", "+Child+" Children":"" }
                     {Infant === 1 ?", "+Infant+" Infant": Infant > 1 ? ", "+Infant+" Infants":"" }
                    </span> ) does not match with the capacity of the <b style={{color:"#FF5050"}}>{Category} : Max Pax {MaxPax}</b></div>
            <div style={{marginTop:"10px"}}>Would you like to adapt the number of {Category} ?</div>
            <div className='capCarName'>{Category} * {Math.ceil(totalPax/MaxPax)}</div>
            <div className='capacity_btn'>
                {/* <button onClick={(e) => {dispatch(openPopup(false))}}>cancel</button> */}
                <button onClick={(e)=>updateCars(e,"close")}>Cancel</button>
                <button onClick={(e)=>updateCars(e,"true")}>Adapt number of cars</button>
            </div>

        </div>
    );
}

export default CarCapacity;