import React from 'react';

function NoHotel() {
    return (
        <div className='no_hoteldropdown'>
            <p>Oops... Seems to have no results for your research</p>
        </div>
    );
}

export default NoHotel;