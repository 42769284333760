import React,{useEffect,useReducer,useRef,useState} from 'react';
import { OFFER_FILTER_NAME, STARRATING } from '../../constants.js';
import {addCriteria,resetIsOffer,updateRating} from "../../store/accomodationReducer.js"
import useFilterItems from '../../hooks/useFilterItems.js';
import useHotelList from '../Accomodation/hooks/useHotelList.js';
import {useDispatch,useSelector } from 'react-redux';
import { FilterTag } from '../MiniComponents/Atom.js';
import { getAccomInputObject, isScrollable } from '../../utility/globalFunc.js';

function FiltersData({label,tagsFilter,ratingFilter,setCriteria,scrollRef,isOffer}) {
    const[allData,setAllData] = useState([])
    const {handleCheckboxChange,callMyApi } = useFilterItems();
    const {fetchHotelList,loading} = useHotelList();
    const {backendDate,roomPax,criteria,paginationPage}= useSelector((state) => state.accomodationreducer)
    const dispatch = useDispatch()
    const listRef=useRef(null);
    const [isScrollActive,setIsScrollActive]=useState(false);
    const [scrollLeftDisable,setScrollLeftDisable]=useState(false);
    const [scrollRightDisable,setScrollRightDisable]=useState(false);

    useEffect(() => {
        const ratings = ratingFilter
        .filter(item => item.checked)  // Filter items where checked is true
        .map(item => ({
            ...item,                   // Spread existing properties
            action: "AND"         // Add the new key-value pair
        }));;
        let mergedArray = [...ratings, ...tagsFilter];
        isOffer&&mergedArray.push(OFFER_FILTER_NAME);        
        setAllData(mergedArray);
    }, [ratingFilter,tagsFilter,paginationPage,isOffer]);
          


    const appliedFilters =  allData && allData.map((list,ind)=> <FilterTag key={ind} onClick={(e)=>{removeitems(e,list)}} text={typeof list==="string"?list:list.name_tag}/>)

    const removeitems = (e,item) => {
      console.log("item",item);
      
      if (typeof item==="string") {
        if (item===OFFER_FILTER_NAME) {
          dispatch(resetIsOffer());
          callMyApi(criteria,[],false);
        }
      }else{
        let upItem = {id_tag:item.rating ?item.id:item.id_tag, rating:item.rating, checked:false, name_tag:item.name_tag,action:item.action}
        e.preventDefault();
        let demo = {target:{checked:false}}
        handleCheckboxChange(demo,upItem,upItem.action,scrollRef)
      }
    };

    const removeAll = (e) => {
        e.preventDefault();
        const clientsInfo = roomPax.map((item) => ({
            client_type: item.clientType[0].toUpperCase(),
            adult_count: item.adult,
            children:item.paxAge.flatMap(item =>
                Object.entries(item)
                .filter(([key, value]) => key !== 'id' && !isNaN(value))
                .map(([key, value]) => Number(value))
            )
        }));
        let redirect="/accomodation-select"
        let module = 0
        // HOTEL LIST
        let sendObject =getAccomInputObject({
          dates:backendDate,
          clientsInfo,
        })
        fetchHotelList(sendObject,redirect,module,paginationPage,true);
        dispatch(addCriteria([]));
        dispatch(resetIsOffer());
        dispatch(updateRating(STARRATING));
       
    }

    const checkIfListScrollable=()=>{
        if (listRef?.current) {
            setIsScrollActive(isScrollable(listRef.current));
        }
    }

    useEffect(()=>{
        const resizeObserver=new MutationObserver(()=>{
            checkIfListScrollable();
        });

        resizeObserver.observe(listRef.current,{childList:true,subtree:true});
        return ()=>resizeObserver.disconnect();
    },[])

    const scrollLeft = () => {
        const container = listRef.current;
        const containerWidth = container.clientWidth;
        const children = Array.from(container.children);
        const containerRect = container.getBoundingClientRect();
    
        for (let i = children.length - 1; i >= 0; i--) {
          const childRect = children[i].getBoundingClientRect();
          if (childRect.left < containerRect.left) {
            let offsetX=containerRect.left-childRect.left;
            container.scrollTo({
                left: container.scrollLeft - containerWidth +childRect.width - offsetX,
                behavior: 'smooth'
            });
            break;
          }
        }
    };
    
    const scrollRight = () => {
        const container = listRef.current;
        const children = Array.from(container.children);
        const containerRect = container.getBoundingClientRect();

        for (let i = 0; i < children.length; i++) {
            const childRect = children[i].getBoundingClientRect();
            if (childRect.right > containerRect.right) {
                container.scrollTo({
                    left: childRect.left-containerRect.left+container.scrollLeft,
                    behavior: 'smooth'
                });
                break;
            }
        }
    };

    const checkScroll=()=>{
        if (listRef.current) {
            //console.log(listRef.current.scrollLeft === 0,listRef.current.scrollLeft === (listRef.current.scrollWidth-listRef.current.width));
            setScrollLeftDisable(listRef.current.scrollLeft === 0);
            setScrollRightDisable(listRef.current.scrollLeft === (listRef.current.scrollWidth-listRef.current.width));
        }
    }

    return (
      <div className="FiltersData_parent">
        <div>{label}</div>
        <div className='scroll-container'>
          <div className={`list_head ${isScrollActive?"scrollable":""}`} ref={listRef}>
            {appliedFilters}
            <div
              className="remove-link"
              onClick={(e) => {
                removeAll(e);
              }}
            >
              Remove all filters
            </div>
          </div>
          {isScrollActive && (
            <div className='scroll-button-container' onScroll={checkScroll}>
              <button className='scroll-button left' onClick={scrollLeft} disabled={scrollLeftDisable}></button>
              <button className='scroll-button' onClick={scrollRight} disabled={scrollRightDisable}></button>
            </div>
          )}
        </div>
      </div>
    );
}

export default FiltersData;