import React,{useEffect, useRef} from 'react';
import CalDate from './CalDate.js';
import Skeleton from '@mui/material/Skeleton';
import "react-datepicker/dist/react-datepicker.css";
import LocPoints from './LocPoints.js';
import { useSelector,useDispatch } from 'react-redux';
import {deleteRow,addLists,lengthOne, removeTransfer, resetTransfer} from "../../store/transferReducer.js"
import {checkDelete} from "../../store/errorReducer.js"
import usePopupData from '../../hooks/usePopupData.js';
import LocPointsTransfer from './LocPointsTransfer.js';
import {TRANSFER_INPUT_OBJECT} from "../../constants.js"
import MulticalanderTransfer from '../Excursion/MulticalanderTransfer.js';
import BookingPerson from './BookingPerson.js';

function ListTransfer({styles,lists=[],errchk,updateerr,scroll,noClick, addArrow=false}) {
    const {transferUpdates,regionDetails,loading,transferList}=useSelector((state) => state.transfer_Global)
    const {isActive:delStatus,isPopup} = useSelector((state) => state.errorreducer.delete)
    const {isOpen,openPopup,getPopupContent}=usePopupData()
    const dispatch = useDispatch()

    // //useEffect for styling
    useEffect(() => {
        const rmdpContainers = document.querySelectorAll('.listsParent .rmdp-container');

        rmdpContainers.forEach(container => {
            container.style.marginRight = '40px';
            container.style.marginLeft = '5px';
        });
    }, [transferUpdates]); 



    function deletefunc(e, name, data) {
        e.preventDefault()
        if (transferUpdates.length === 1) {
            let sendObj = { delbtn: false, delpop: false }
            dispatch(checkDelete(sendObj))
            dispatch(lengthOne())
            dispatch(resetTransfer());

        } else {
            dispatch(deleteRow(data.IdTransfer))
            let sendObj = { delbtn: true, delpop: false }
            dispatch(checkDelete(sendObj))
        }
    }
    const ShowDelPopup =(e,name,data)=>{
        e.preventDefault();
        // empty 
        if((delStatus && name === "delpopup") && (data.LocationFromName === "")){

          if (transferUpdates.length === 1) {
            let sendObj = {delbtn:false,delpop:false}
            dispatch(checkDelete(sendObj)) 
            //setErrorpopups(true)
            updateerr(false)
            dispatch(lengthOne())
            dispatch(resetTransfer());
            openPopup("del-message");
        
          } else {
            if (transferList.some(transfer=>transfer.IdTransfer===data.IdTransfer)) {
                dispatch(removeTransfer({idTransfer:data.IdTransfer}));
            }else{
                dispatch(deleteRow(data.IdTransfer))
            }
            openPopup("del-message");
          }
    
        }

        // for popup
        if((delStatus && name === "delpopup") && (data.LocationFromName !== "")){ 
            let sendObj = {delbtn:true,delpop:true,items:data}
            dispatch(checkDelete(sendObj));
            deletefunc(e, "delete", data);
            openPopup("del-message");
        }
        
    }

    const addInputBoxes =(e)=>{
        e.preventDefault();
        let objectLength = transferUpdates.length +1;
        let addInputs = { ...TRANSFER_INPUT_OBJECT };
        addInputs.IdTransfer = objectLength;
        dispatch(addLists({addInputs,index:transferUpdates.length}))
    }

    const addTransfer = () =>{
        const paxInfo = {"IdTransfer": 1,"TransferDate": "","LocationFromName": "","LocationFromDetails": "","LocationToName": "","LocationToDetails": "","TotalPrice": 0,"Car_selected": false,"TransferTime": "00:00:00","Remarks": "Transfer remarks from B2B UI","Flight": "Air Mauritius","Currency": null,"Pic_url": null,"TransferType": null,"AdultCount": 1,"TeenCount": 0,"ChildCount": 0,"InfantCount": 0,"IsSearched": false}
        return (
            <div className='listsParent' style={styles} onClick={(e) => {addInputBoxes(e)}}>
            <div className='disable' style={{marginRight:"10px"}}>
            <BookingPerson cname={2} scroll={scroll} paxInfo={paxInfo} rowId={0} isTransfer={true} />
                </div>
                <div className='disable' style={{display:'inherit'}}>
                <MulticalanderTransfer errmsg={false} label="Transfer Date" label2={'dd/mm/yyyy'} month={2} page={1} rangeDate={[]} rowid={0} calDate={""}/>
                </div>
                <div className='disable'>
                <LocPointsTransfer label="Pick-up-point" inpText="Search for a pick-up-point" inpTextDetail="Please select the point A of this transfer" points="pickup" passArray1={""} passArray2={""} passId={0} errmsg={false} rowid={0} scroll={scroll}/>
                </div>
                <div className='disable'>
                    <div className={!addArrow ? "inputLine" :"inputLine arrowLine"}></div>
                </div>
                <div className='disable'>
                    <LocPointsTransfer label="Destination" inpText="Search for a destination Point" inpTextDetail="Please select the point B of this transfer" points="destination" passArray1={""} passArray2={""} passId={0} errmsg={false} rowid={0} scroll={scroll}/>
                </div>                    
            </div>
        )
    }

    return (
        <>
        <div className={'inputBlocks '+ (!noClick ? "":"noclick")} >
            {
                    lists?.length >= 1 ? (lists.map((data, rowId) => {
                        let transferRow=data||TRANSFER_INPUT_OBJECT;
                        return(
                            <div key={"transInp"+rowId} onClick={delStatus ? (e)=>{ShowDelPopup(e,"delpopup",transferRow)} :null}  style={styles} className={"listsParent " + (delStatus ?"delhover":"") }   >
                                <div style={{marginRight:"10px"}}>
                                    {/* <SimplePaxPreselector  cname={1} paxInfo={roomPax} /> */}
                                    <BookingPerson cname={2} scroll={scroll} paxInfo={transferRow} rowId={rowId} isTransfer={true} />
                                </div>
                                {!loading ? 
                                    <MulticalanderTransfer errmsg={errchk} label="Transfer Date" label2={'dd/mm/yyyy'} month={2} page={1} rangeDate={[]} rowid={rowId} calDate={transferRow.TransferDate}/>
                                :
                                    <Skeleton height={62} style={{transform:"scale(1,1)",width:"251px"}}/>
                                } 
                                <LocPointsTransfer
                                    label="Pick-up-point"
                                    inpText="Search for a pick-up-point"
                                    inpTextDetail="Please select the point A of this transfer"
                                    points="pickup"
                                    passArray1={transferRow.LocationFromName}
                                    passArray2={transferRow.LocationFromDetails}
                                    passId={transferRow.IdTransfer}
                                    errmsg={errchk}
                                    rowid={rowId}
                                    scroll={scroll}
                                />
                                <div className={!addArrow ? "inputLine" :"inputLine arrowLine"}></div>
                                <LocPointsTransfer
                                    label="Destination"
                                    inpText="Search for a destination Point"
                                    inpTextDetail="Please select the point B of this transfer"
                                    points="destination"
                                    passArray1={transferRow.LocationToName}
                                    passArray2={transferRow.LocationToDetails}
                                    passId={transferRow.IdTransfer}
                                    errmsg={errchk}
                                    rowid={rowId}
                                    scroll={scroll}
                                />
                                <div  className={delStatus ? "deliconbox": "hide"} ></div> 

                            </div>
                        )
                    })):<></>
            }
            {!loading && !noClick && addTransfer()}
        </div>
        {isOpen ? getPopupContent():<></>} 
        </>
    );
}


export default ListTransfer;