import React, { useRef, useState } from "react";
import EXPIRATION_IMG from "../../../Assets/images/global-icon/expiration_token.svg";
import useOutsideClick from "../../../hooks/useOutsideClick";
import useAuthActions from "../../../hooks/useAuthActions";
import { REFRESH_TOKEN_EVENT } from "../../../constants";

function TokenExpiredPopup({closePopup}) {
    const popupRef=useRef();
    const [popupAnimate,setPopupAnimate]=useState('fade-in');
    const {refreshToken}=useAuthActions();
    
    const onClick = () => { 
        refreshToken(async (access_token)=>{
            animateClose(false,undefined)
            const event=new CustomEvent(REFRESH_TOKEN_EVENT,{
                detail:{token:access_token}
            });
            window.dispatchEvent(event);
        })
    };

    const toLogin=()=>{window.location.href = '/'};
    
    const animateClose=(open,actionOnClosed)=>{
        if (!open) {
            setPopupAnimate("fade-out")
            setTimeout(() => {
                closePopup();
                setPopupAnimate("")
                actionOnClosed&&actionOnClosed();
            }, 280);
        }
    }

    useOutsideClick(popupRef,()=>{
        animateClose(false,toLogin)
    })
    return (
        <div className={`token-expired-container ${popupAnimate}`} ref={popupRef}>
            <h6 className="token-expired-title">Session timeout</h6>
            <div className="token-message-container">
                <img src={EXPIRATION_IMG} className="token-image" alt="token-expired" />
                <p className="token-message">
                    <span>You're being timed out due to inactivity.</span>
                    <span>Please choose to stay signed in or to logoff.</span>
                    <span>Otherwise, you will logged off automatically</span>
                </p>
            </div>
            <button onClick={onClick} className="token-button">
                Stay logged
            </button>
        </div>
    );
}

export default TokenExpiredPopup;
