import React,{useState,useEffect, useCallback,useRef} from 'react';
import { GoogleMap, useJsApiLoader ,OverlayView,Marker, OverlayViewF  } from '@react-google-maps/api';
import {MAP_OPTIONS, MAURITIUS_BOUNDS} from "../../constants.js"
import Showhotels from "../Accomodation/Showhotels.js"
import { separateurMillier } from '../../utility/globalFunc.js';

function MapContainer({setOpenMap,points=null,paxData,hotelId,btn}) {
    const mapRef = useRef(null);
    const[map,setMap]=useState(null);
    const [selectedId, setSelectedId] = useState(hotelId || null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [list,setList]=useState([])
    const [zoom,setZoom]=useState(11);
    const [isMapReady, setIsMapReady] = useState(false);
    const [mapAnimate,setMapAnimate]=useState('fade-in');
    const [center,setCenter]=useState({"lat":-20.348404,"lng":57.552152});
    const [currentMapTypeId, setCurrentMapTypeId] = useState(MAP_OPTIONS);
    
    const mapstyles ={width: "1508px",height:" 855px",display: "flex",
    justifyContent: "center",
    alignItems: "end"}
    //const popupRef = useRef(null);
    const { isLoaded,loadError  } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    })

    const handleOpenMap = useCallback((openMap) => {
        if (openMap) {
            setMapAnimate("fade-in");
            setOpenMap(openMap);
        } else {
            setMapAnimate("fade-out");
            setTimeout(() => {
                setOpenMap(openMap);
            }, 300);
        }
    }, [setOpenMap]);
  
  const changeMapType =(e,type)=>{
    e.preventDefault();
    setCurrentMapTypeId((prevState)=>({
        ...prevState,
        mapTypeId:type
    }))
  }

    useEffect(() => {
        const timer = setTimeout(() => {
          setIsDisabled(true);
        }, 5000);
    
        // Cleanup the timer on component unmount
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (isLoaded && points) {
            setList(points);
            if (!btn && points.length > 0) {
                const newCenter = getFloatPosition(points[0].Position || points[0]);
                setCenter(newCenter);
                setZoom(14);
                if (map) {
                    map.panTo(newCenter);
                    map.setZoom(14);
                }
            }
        }
    }, [isLoaded, points, btn, map]);

    const handleSelect =(e,selId)=>{
        e.preventDefault();
        setSelectedId(selId)
        let hotel=points.find((data)=>data.HotelId === selId);
        setCenter(getFloatPosition(hotel.Position||data));
        setZoom(14+Math.random()*0.01);
    }

    const getFloatPosition = useCallback((position) => {
        return {
            lat: +position.Latitude,
            lng: +position.Longitude
        };
    }, []);

    const handleCloseChild = useCallback((e) => {
        e.stopPropagation();
    }, []);

    useEffect(()=>{
        if(!btn&&points){
            setCenter(getFloatPosition(points));
            setZoom(14);
        }
    },[])

    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
        setMap(map);
        const MRU_BOUNDS=new window.google.maps.LatLngBounds(MAURITIUS_BOUNDS[0],MAURITIUS_BOUNDS[1]);
        map.fitBounds(MRU_BOUNDS);
        setTimeout(() => {
            setIsMapReady(true);
        }, 1000);
    }, []);

    if (loadError) {
        return <div>Error loading maps</div>;
    }
    
    

      return isLoaded &&(
        <div className='mapbox_parent' onClick={()=>{handleOpenMap(false)}}>
           
                <div className={`map_container ${mapAnimate} show`} >
                    <div onClick={(e)=>handleCloseChild(e)}>
                        <div className='mapHeader'>
                        <div className='headerName'>Map Searching</div> 
                            <div className='closeBtn' onClick={(e)=>{handleOpenMap(false)}}>Close</div>
                        </div>
                        {!isLoaded ? (
                        <div>Loading map...</div>
                    ) : (
                        <GoogleMap
                            mapContainerStyle={mapstyles}
                            center={center}
                            zoom={zoom}
                            onLoad={onMapLoad}
                            options={currentMapTypeId}  // Correct way to conditionally modify mapTypeId
                           
                        >
                            {isMapReady && points?.map((data,ind) => (
                                <OverlayViewF
                                    key={"hotelMap"+data.HotelId||ind} 
                                    position={getFloatPosition(data.Position || data)}
                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                >
                                    {data.HotelTotalRate || data.base_price?
                                    <div 
                                        className={'customIcon ' + (selectedId === data.id ? "selectIcon" : "")} 
                                        onClick={(e) => handleSelect(e, data?.HotelId || data.HotelId)}
                                    >
                                        {data.HotelCurrency} {separateurMillier(data.HotelTotalRate || data.base_price,",")} 
                                    </div>
                                    :
                                    <div
                                    className={'customIcon hotel-name-label ' + (selectedId === data.id ? "selectIcon" : "")} 
                                    onClick={(e) => handleSelect(e, data?.HotelId || data.HotelId)}
                                    >
                                        {data.HotelName}
                                    </div>
                                    }
                                </OverlayViewF>
                            ))}

                            {isMapReady && selectedId !== null && (
                                <div className='maphotel-Popup'>
                                    <Showhotels 
                                        hotel={points.filter((data) => data.HotelId === selectedId || data.HotelID === selectedId)} 
                                        foldername="accomodation" 
                                        url="/accom/room/details" 
                                        redirect="/selected-accomodation" 
                                        paxInfo={paxData}
                                        btnBlock={btn}
                                        list={false}
                                        amenitiesMarginTop={btn ? 17 : 29.4}
                                        amenitiesMarginBottom={btn ? 25 : 30}
                                    />
                                </div>
                            )}  

                            {btn && !isDisabled && (
                                <div className='navbtn-head'>
                                    <div className='navBtn'>Navigate, zoom, select ! 🗺️</div>
                                </div>
                            )}

                            <div className='map-types'>
                                <button onClick={(e) => changeMapType(e,'roadmap')}>Roadmap</button>
                                <button onClick={(e) => changeMapType(e,'satellite')}>Satellite</button>
                            </div>

                        </GoogleMap>
                    )}
                    {!isMapReady && isLoaded && (
                        <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                            Preparing map...
                        </div>
                    )}
                    </div>    
                </div>
        </div>
    )
}

export default React.memo(MapContainer);