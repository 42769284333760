import { useState } from 'react';
import { axiosRequestBody1,axiospagination} from '../../../services/apiClient.js';
import { HOTELLIST_ENDPOINT,FILTERLIST } from '../../../services/apiEndpoints.js';
import { useDispatch,useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {listAccomodation,filterAccomodation,updateAccomlist,updateRating,clearRoom,setAccomLoading,addCriteria,setPageLoading,ishotelListInfo,incPaginationNumber,setOffset} from "../../../store/accomodationReducer.js"
import {docketInfo} from "../../../store/globalReducer.js"
import { getAccomInputObject, scrollToTop } from '../../../utility/globalFunc.js';
import { TIMEOUT_DURATION,STARRATING,BUDGET_RANGE } from '../../../constants.js';

// Custom hook to fetch data from an API
const useHotelList = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);  
    const {noHotelData,isOffer,budgetRange,backendDate,starRating,roomPax,criteria,displayUnavailableHotels,offset} = useSelector((state) => state.accomodationreducer)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location=useLocation();
    
    //let BUDGET_DEFAULT = {from:0,to:0}

    const buildFetchListPayload=()=>{
        const ratings = starRating?.filter(item => item.checked).map(item => item.rating);
        const clientsInfo = roomPax.map((item) => ({
            client_type: item.clientType[0].toUpperCase(),
            adult_count: item.adult,
            children:item.paxAge.flatMap(item =>
                Object.entries(item)
                .filter(([key, value]) => key !== 'id' && !isNaN(value))
                .map(([key, value]) => Number(value)))
            }));
        const actionAND = criteria.map(item => item.action === "AND" ? item.id_tag : null).filter(id_tag => id_tag !== null);
        const actionOR = criteria.map(item => item.action === "OR" ? item.id_tag : null).filter(id_tag => id_tag !== null);
              
        const updatedBudget = {
            from: budgetRange[0] === BUDGET_RANGE.MIN_PRICE ? BUDGET_RANGE.MIN_PRICE : budgetRange[0],
            to: budgetRange[1] === BUDGET_RANGE.MAX_PRICE ? BUDGET_RANGE.MIN_PRICE : budgetRange[1],
        };

        return getAccomInputObject({
            dates:backendDate,
            clientsInfo,
            star_rating:ratings,
            budgetRange:updatedBudget,
            offset,
            OR:actionOR,
            AND:actionAND,
            offers:isOffer,
            
        })
    }
    
    const fetchHotelList = async (payload,path,module,paginationPage,isfilter,showMainLoad=true,showUnavailableHotels=undefined) => {
        // scrollToTop(0);
        
    
        
        dispatch(setAccomLoading(true))
        // dispatch(setPageLoading(path!==location.pathname));
        if(showMainLoad){
            let paramLoading = { status: (path!==location.pathname), isFirstFlow: true };
            dispatch(setPageLoading(paramLoading));
        }
        
        if (paginationPage===1) {
            dispatch(updateAccomlist([]));
            payload.offset = 0
            //payload.filters.budget = BUDGET_DEFAULT
        }
        try {
            navigate(path);
            const axiosConfig = { timeout: TIMEOUT_DURATION }; 
            const hotel = await axiospagination("POST",HOTELLIST_ENDPOINT,payload,axiosConfig,isfilter ? 1 : paginationPage);            
            const result = hotel.data.result;
            const unavailableHotel = hotel.data.unavailable_hotel;
            const offset = hotel.data.offset;
            let showUnavailable=showUnavailableHotels!=undefined?showUnavailableHotels:displayUnavailableHotels;

            
            const availableHotels = [...result];
            const unavailable = [...unavailableHotel,...result]
            const hotels = [
                ...(showUnavailable ? unavailable : availableHotels)
            ];

            const totalResults=result.length + unavailableHotel.length; 

            if (isfilter && module === 0 && Array.isArray(hotels)) { // for new values
                dispatch(updateAccomlist({ lists: hotels }));
                dispatch(incPaginationNumber(1));
                dispatch(ishotelListInfo(hotels.length===0));
                dispatch(setOffset(offset))
            } 
            else if (!isfilter && module === 1 && totalResults !== 0 ){ // for append new values to existing values (pagination)
                dispatch(listAccomodation(hotels));
                dispatch(incPaginationNumber(paginationPage));
                dispatch(ishotelListInfo(false));
                dispatch(setOffset(offset))
            }


            else {
                dispatch(ishotelListInfo(true));
            }

            // return true;
        } catch (error) {
            console.log(error);
            dispatch(updateAccomlist([]))
            dispatch(ishotelListInfo(true));
            if(error.response && error.response.status === 401){ navigate("/")}
            else if (error.request) {alert(error.message)} 
            else { navigate("/internal-error",{state:{message:error.response.data?.message||"",status:error.response.status}})}
        } finally {
            let paramLoading = { status: false, isFirstFlow: true };
            dispatch(setAccomLoading(false))
            dispatch(setPageLoading(paramLoading))
        }
    };

    const fetchTagList = async () => {
        scrollToTop(0);
        //dispatch(setAccomLoading(true))
        //dispatch(setPageLoading(path!==location.pathname));
       
        
        try {
            const axiosConfig = { timeout: TIMEOUT_DURATION }; 
            //const hotel = await axiospagination("POST",HOTELLIST_ENDPOINT,payload,axiosConfig,pageNo);
            const filter = await axiosRequestBody1("GET", FILTERLIST, axiosConfig);
            let empty =[]
            //dispatch(listAccomodation(hotel.data.result))
            let filters=filter.data.result;
            let indRegion=filters.findIndex(filter=>filter.name_tag_category?.toLowerCase()==="region");
            if (indRegion>-1) {
                let regionFilter=filters.splice(indRegion,1)[0];
                filters.unshift(regionFilter);
            }
            dispatch(filterAccomodation(filters));
            
            //setPageNo(prevPageNo => prevPageNo + 1);

            // dispatch(clearRoom())
            if (module > 0 ){
                dispatch(addCriteria(empty)) 
            }
            //navigate(path);
              
        } catch (error) {
                console.log(error);
                dispatch(filterAccomodation([]));
                if(error.response && error.response.status === 401){
                    navigate("/")
                }else if (error.request) {
                    alert(error.message);
                } else {
                    navigate("/internal-error",{state:{message:error.response.data.message,status:error.response.status}})
                }
        } finally {
            dispatch(setAccomLoading(false))
            dispatch(setPageLoading(false))
        }
    };

    const fetchHotelTagList=async(payload,path,module,paginationPage,isfilter)=>{
        if (path!==location.pathname) {
            await Promise.all([
                fetchHotelList(payload,path,module,paginationPage,isfilter),
                fetchTagList()
            ]);
        }else{
            await fetchHotelList(payload,path,module,paginationPage,isfilter);
        }
    }
   
    return { error,loading,fetchHotelList,fetchHotelTagList,buildFetchListPayload };
};


export default useHotelList;