import React,{useEffect,useState} from 'react';
import TransferInputs from './TransferInputs.js';
// import {ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Footer from '../Transfer/Footer.js';
import Quotation from '../Transfer/Quotation.js';
import SelectCars from './SelectCars.js';
import Dropdowns from '../../components/Transfer/Dropdowns.js';
import {DROPDOWN_DATA} from "../../constants.js";
import { SaveCotationInputs } from '../Transfer/SaveCotation.js';
import {Back} from '../MiniComponents/Atom.js';
import NavbarBlock from '../Transfer/NavbarBlock.js';
import Skeleton from '@mui/material/Skeleton';
import { openPopup } from "../../store/errorReducer.js";
import { useDispatch,useSelector } from "react-redux";
import B2BSkeleton from '../Accomodation/minicomponents/B2BSkeleton.js';
import ROOMS_SKELETON from "../../Assets/images/skeleton/search-vehicule-transfert.png";
import { scrollToSection, scrollToSectionv1 } from '../../utility/globalFunc.js';

function MainTransfer() {
    const [popupOpen,setpopupOpen] = useState(false)
    const {loading}=useSelector((state) => state.transfer_Global)
    const [localloading,setlocalLoading] = useState(true)
    // const {Adult,Teen,Child,Infant} = useSelector((state) => state.transfer_Global.paxData)
    const gridStructure = { "gridTemplateColumns": "226px 265px 372px 61px 362px", "display": "grid", "position": "relative", };
    const { visitedTransferList: isVisited } = useSelector((state) => state.transfer_Global);
    useEffect(() => {
        scrollToSectionv1('pickCarsText', 100);
        setTimeout(() => {
            setlocalLoading(false);
        }, 800); 
    }, [localloading]);

    let inputStyle = {"padding": "32px 14px 36px 35px"}
    
    const gridStyle = { "gridTemplateColumns": "226px 265px 328px 35px 328px", "display": "grid", "position": "relative", }
    return (
        <div className='mainTransfer-parent'>
           { !loading  ? null :<Skeleton height={78} style={{transform:"scale(1,1)"}}/>}
            <div className='transferLeft'>
                <div>
                {
                    !loading   ? 
                    <>
                    <Dropdowns dropData={DROPDOWN_DATA} imgcount={1} name="Transfer"/>
                    <TransferInputs gridStyle={gridStyle} newStyle={inputStyle} scroll={false}/>
                    <div style={{ marginTop: isVisited ? "100px" : "200px", float: "left" }}>
                        <SelectCars />
                    </div>
                    {/* <div className='fixedQuotation'><Quotation cname="Transfer" Infant={Infant} Adult={Adult} Teen={Teen} Child={Child}/></div> */}
                    </>
                    : <B2BSkeleton imgUrl={ROOMS_SKELETON}/>
                }
                </div>
            </div>
            { popupOpen ? <div className='openPopup_save'><SaveCotationInputs closepop={setpopupOpen}/></div>:null}
            {!loading ? <ToastContainer /> : null }
             
            

        </div>
    );

}

export default MainTransfer;