import React,{useEffect, useRef, useState} from 'react';

function Feedback({data=[],label}) {
    const [selectedIndex,setSelectedIndex]=useState(0)
    const feedBackRef=useRef(null);
    const [isContentScrollable,setIsContentScrollable]=useState(false);
    let totalLength  = data.length -1

    const caroselImg = (e,name)=>{
        e.preventDefault();
        if(name === "next" && selectedIndex !== totalLength  ){
            setSelectedIndex(selectedIndex + 1)
        }
        else if(name === "prev" && selectedIndex !== 0){
            setSelectedIndex(selectedIndex - 1)
        }
    }

    const imageIndex = data &&data.map(item =>
        <div className={'feedBack_circle '+(item.id-1 === selectedIndex ? "circ_selected":"")} key={item.id}>
        </div>
        
    )

    const checkOverflow=()=>{
        if (feedBackRef.current) {
            const feedBack=feedBackRef.current;
            if (feedBack.scrollHeight>feedBack.clientHeight) {
                setIsContentScrollable(true);
            }
        }
    }

    useEffect(()=>{
        checkOverflow()
        const resizeObserver=new ResizeObserver(()=>checkOverflow());
        resizeObserver.observe(feedBackRef.current);
        return resizeObserver.disconnect();
    },[])

    const imagePath = require(`./../../Assets/images/accomodation/reviews/review${selectedIndex +1}.png`);

    return (
        <div className='feedback_parent'>
            {/* <div className='feedback_parent_header'>
                <div className='labeltxt'>Reviews</div>
            </div> */}
            <div className='feedback_container'> 
                <div className={data.length ?'feedback_header':""}>
                    <img src={imagePath} alt="review images"/>
                    <div className='person-details'>
                        <div>{data.length ? data[selectedIndex].name :""}</div>
                        <div>{data.length ? data[selectedIndex].place :""}</div>
                    </div>
                    <div className='feedback-arrows'>
                        <div onClick={(e)=>{caroselImg(e,"prev")}}></div>
                        <div onClick={(e)=>{caroselImg(e,"next")}}></div>
                    </div>
                </div>
                <div className='feedback_text' ref={feedBackRef}>
                    <div className={`content${isContentScrollable?"-scrollable":""}`}>
                        {data.length ?data[selectedIndex].comments+"" : ""}
                    </div>
                </div>
                <div className="image_index" >{imageIndex}</div>
            </div>


        </div>
    );
}

export default Feedback;