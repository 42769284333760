import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ADD_ITEM_QUOTATION_ENDPOINT,QUOTELIST_ENDPOINT } from '../../../services/apiEndpoints';
import { createSelector } from '@reduxjs/toolkit';
import { buildPayloadQuotation } from '../../../utility/globalFunc';
import { addRefcode } from '../../../store/transferReducer';
import { axiosRequestBody,axiosRequestParams } from '../../../services/apiClient';
import { clearRoom } from '../../../store/accomodationReducer';
import { MORE_OPTIONS_MODE,QUOTATION_TYPE2 } from '../../../constants';
import {setQuoteBookList} from "../../../store/globalReducer"

const useAddItems = () => {

    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {totalPrice,actionMode,sortedList,shwowDropDown,quoteBookList} =useSelector((state) => state.globalreducer)  


    const getNewItems=(items)=>{
        const filtered=items.filter((item)=>item.isNew)        
        return filtered.map(({isNew,...rest})=>rest);
    }


    const addItems=async(transferlist,selExcursion,selRooms,cotation_name,first_name,last_name,roomPax)=>{
        setLoading(true);
        console.log("actmode1",actionMode);
        try {
            let payload=buildPayloadQuotation(getNewItems(transferlist),getNewItems(selExcursion),getNewItems(selRooms),cotation_name,first_name,last_name,roomPax,actionMode);
            console.log("payload",payload);
            
            const response = await axiosRequestBody("PUT",ADD_ITEM_QUOTATION_ENDPOINT,payload);
            setStatus(response.data.status)
            dispatch(addRefcode(response.data.to_ref_code))
            if(response.data.status===201){
                dispatch(clearRoom());
                dispatch(addRefcode(null));
            }


        } catch (error) {
                console.log("accom error response",error);
                if(error.response && error.response.status === 409){
                    alert(error.response.data.message)
                }
                else if (error.response) {
                    alert(error.response.data.message)
                } else {
                    navigate("/internal-error",{state:{message:error.response?.data?.message||"",status:error.response?.status}})
                }
        } finally {
            setLoading(false)
        }
    }

    const deleteQuotation =async(payload,mode,callBack)=>{
console.log("payload",payload);

        
        try {
            const response = await axiosRequestParams("DELETE",ADD_ITEM_QUOTATION_ENDPOINT,payload);
           console.log("resonse delete",response);
           if( response.status === 200){
              if(mode === MORE_OPTIONS_MODE.DELETE_QUOTATION){
                navigate("/quotation")
              } 
              else{
                console.log("rem payload",payload);
                let updatedData =  await axiosRequestParams("GET",QUOTELIST_ENDPOINT,{ to_ref_code: payload.to_ref,quotation_type:QUOTATION_TYPE2 })
               
                dispatch(setQuoteBookList(updatedData.data.result))
              }
           }
           else{

           }
        

        } catch (error) {
                console.log("accom error response",error);
                if(error.response && error.response.status === 409){
                    alert(error.response.data.message)
                }
                else if (error.response) {
                    alert(error.response.data.message)
                } else {
                    navigate("/internal-error",{state:{message:error.response?.data?.message||"",status:error.response?.status}})
                }
        } finally {
            setLoading(false)
        }
    }

    return {status,loading,error,addItems,deleteQuotation};
}

export default useAddItems
