import React, { forwardRef } from 'react';
import Accessibility from '../Excursion/Accessibility';
import TrackExcursion from '../Excursion/TrackExcursion';
import EmptyData from "../Accomodation/EmptyData.js"
import Amenities from "../MiniComponents/Amenities.js"
import { Description, ImageWithFallBack } from '../MiniComponents/Atom.js';
import {DisplayPax} from "../Excursion/ExcursionArray";
import Rating from '@mui/material/Rating';
import { useSelector} from 'react-redux';
import useHotelInfo from '../../hooks/useHotelInfo.js';
import useHotelDetails from './hooks/useHotelDetails.js';
import StarIcon from '@mui/icons-material/Star';
import DefaultImgHotel from "../../Assets/images/default-img/hotel.jpg"
import { properCase } from '../../utility/globalFunc.js';
import LazyImage from '../MiniComponents/LazyImage.js';
import { BUDGET_RANGE } from '../../constants.js';

const Showhotels=forwardRef(({hotel=[],foldername,paxInfo=[],btnBlock,listType,amenitiesMarginTop=17,amenitiesMarginBottom=25},ref) =>{
    
    const {roomPax,calendarDate,backendDate,budgetRange}= useSelector((state) => state.accomodationreducer)
    const {fetchHotelDetails,fetchHotelData} = useHotelDetails();

    const getDescription=(data)=>{
        return data.HotelDescription?data.HotelDescription:data.HotelDescriptions[0].HotelDesc;
    }
  
    const SeeMoreData =(e,curData)=>{
        e.preventDefault();
        const clientsInfo = roomPax.map((item) => ({
                client_type: item.clientType[0].toUpperCase(),
                adult_count: item.adult,
                children:item.paxAge.flatMap(item =>
                    Object.entries(item)
                    .filter(([key, value]) => key !== 'id' && !isNaN(value))
                    .map(([key, value]) => Number(value))
                )
        }));
        const updatedBudget = {
            from: budgetRange[0] === BUDGET_RANGE.MIN_PRICE ? BUDGET_RANGE.MIN_PRICE : budgetRange[0],
            to: budgetRange[1] === BUDGET_RANGE.MAX_PRICE ? BUDGET_RANGE.MIN_PRICE : budgetRange[1],
        };

        // SEND OBJECT FOR HOTEL LIST  
        let sendObject ={
            "date_from":backendDate[0]? backendDate[0]:"",
            "date_to":backendDate[1]? backendDate[1]:"",
            "clients":clientsInfo,
            "hotel_id":curData.HotelId,
            "budget":updatedBudget
        }

        
        fetchHotelData(sendObject,"accomodation-select",curData.HotelName)
    }

    return (
        <div className='after-also-block'>
           {hotel !== null && hotel.length ?
            hotel.map((data,ind)=>{
                return(
                    // <div className={'hotels_parent '+(!listType ? "maphotel":"")} key={data.tour_id || data.id+data.currency}>
                    <div className={'hotels_parent '+(!listType ? "maphotel":"")} key={ind} ref={ind===hotel.length-1?ref:null}>
                        <div className='hotel_img'>
                            <LazyImage src={data.HotelImage} alt="excursion images" fallBackImg={DefaultImgHotel} hasFallback={true} className={"img-hotel-card"}/>
                            {/* {foldername !== "accomodation" ? <div style={{marginTop:"44px"}}>
                                <Accessibility 
                                    list={data}
                                    label=""
                                    isacces={true}
                                    islang={true}
                                />
                            </div>:<></>} */}
                        </div>
                        <div className='hotel_details'> 
                            <div className={'hotel_name '+(listType ? "addMargin":"" )} onClick={(e)=>{SeeMoreData(e,data)}}>{data.HotelName ? data.HotelName :"No data"}</div> 
                            { 
                            foldername==="accomodation" ? 
                                <>
                                    <div className='rating_location'>
                                        <span className='rating_loc_txt'>{properCase(data.HotelLocation)}</span>  
                                        <Rating emptyIcon={<StarIcon fontSize="inherit"/>} name="read-only" value={data.HotelRating} readOnly size="small" />
                                    </div>
                                    { listType &&data.IsHotelOffer&& 
                                    <>
                                        <div className='offers-accom'>Offer available</div>
                                        <i className='icon-discount-normal discount-logo'></i>
                                    </>
                                    }
                                    <div style={{margin:`${amenitiesMarginTop}px 0 ${amenitiesMarginBottom}px 0`}}>
                                        <Amenities multiple={false} data={data.tags||data.HotelAmenities.slice(0, 3)} multi={true}/>
                                    </div>
                                   
                                </>
                                :<div className='hotel_type'>{data.type} excursion</div>
                            }    
                            <div className={'hotel_details_body '+(!listType ? "remmargin":"")}>
                                <div className='chk_avaliability' style={{ marginBottom:btnBlock?"38px":"0" }}>
                                    {getDescription(data) ?<Description label="Description"  details={getDescription(data)} listType={listType}/>:"No data" }
                                </div>  
                                { foldername !== "accomodation" ?  
                                    <>
                                        <TrackExcursion label={data ?.routes.length ?"Track the excursion":""} point={1} data={data.routes ? data.routes:[] } fullWidth={false} labelSize={2}/> 
                                        <TrackExcursion label={data ?.pois.length ? "Point of Interest":""}  point={2} data={data.pois ? data.pois:[]  } fullWidth={false} labelSize={2}/>  
                                    </>
                                    :listType && <div className={"room_env "+(data.HotelRoomStatus === "FREE SALES" ?"enable":data.HotelRoomStatus === "STOP SALES"? "no-rooms":"request")}>{data.HotelRoomStatus === "FREE SALES" ?"Rooms available":data.HotelRoomStatus === "STOP SALES"? "No-rooms available":"Only “On request” rooms available"}</div>
                                }
                                 
                            </div>  
                            
                           { btnBlock ? <div className='hotel_btnblk'>
                                <DisplayPax totalpax={paxInfo} price={data.HotelTotalRate} currency={data.HotelCurrency} cname={1} />
                                <button className='seemore_btn' onClick={(e)=>{SeeMoreData(e,data)}}>See more</button>
                            </div>:<></> }  
                        </div>
                        { foldername==="accomodation"&&data.offers ? <div className='old_price'></div>:null }
                        {/* { data.offers ? <div className='old_price'></div>:null } */}
                    </div>    
                )
            })
            :<div><EmptyData/></div>
           }
        </div>
    );
})

export default Showhotels;