import React, { Fragment, useEffect, useRef, useState } from 'react';
import CancellationPolicyItem from '../Accomodation/minicomponents/CancellationPolicyItem';
import { scrollToDependsBoundary } from '../../utility/globalFunc';

function CancelationPolicy({cancelData=[],module=2}) {
    const [showMore,setShowMore]=useState(false);
    const [showButton, setShowButton]=useState(false);
    const divRef=useRef(null);
    const containerRef=useRef(null);

    const onClick=()=>{
      setShowMore(prev=>!prev)
      if (showMore) {
        setTimeout(() => {
          scrollToDependsBoundary(containerRef.current,"start","smooth",-82);
        }, 100);
      }
    }
   
    useEffect(() => {
        const checkIfScrollable = () => {
          if (divRef.current) {
            const isDivScrollable =divRef.current.scrollHeight > divRef.current.clientHeight;
            setShowButton(isDivScrollable);
          }
        };
    
        const observer = new ResizeObserver(checkIfScrollable);
    
        if (divRef.current) {
          observer.observe(divRef.current);
        }
    
        return () => {
          if (divRef.current) {
            observer.unobserve(divRef.current);
          }
        };
      }, []);

    return (
        <div className='cancelation_policy_parent' ref={containerRef}>
            <div className='cancelation_partxt'>Cancellation policy</div>
            <div className={`cancelation_maincnt ${showMore?"show-more":""}`} ref={divRef}>
                {cancelData.map((cancelItem,ind)=>(
                    <Fragment key={ind+"/"+cancelItem.valid_to}>
                        <CancellationPolicyItem description={cancelItem.description} dateFrom={cancelItem.valid_from} dateTo={cancelItem.valid_to} rooms={cancelItem.rooms}/>
                        <i className='icon-dashed-line'></i>
                    </Fragment>
                ))}
            </div>
            {(showButton||showMore)&&<button onClick={onClick} className={`button-policy ${showMore?"orange":"green"}`}>{showMore?"Show less policies":"Show more policies"}</button>}
            
        </div>
    );
}

export default CancelationPolicy;