import { createSlice } from '@reduxjs/toolkit'
import {MAUTOURCO_ID} from "../constants"

export const INITIAL_STATE = {
 userData:{},
 isLogin:false,
 subAgencyAdded:{},
 userRequestData:{},
 agencyList:[]
}


export const authentication = createSlice({
  name: 'authpath',
  initialState:INITIAL_STATE,
  reducers: {
        userLogin(state,action){
          state.userData = action.payload
          state.isLogin = true
        },
        userRequest(state,action){
          state.userRequestData= action.payload
          
        },
        userLogout(state,action){
          return INITIAL_STATE;
        }, 
        addingAgency(state,action){
          state.subAgencyAdded =action.payload
        },
         listingAgency(state,action){
          state.agencyList =action.payload
         },
        
   
    
  },
})

export const { userLogin,userLogout,addingAgency,listingAgency } = authentication.actions


export default authentication